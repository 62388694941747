import React, { useEffect, useState } from "react";
import axios from "../../axios-interceptor";
import { random_number, base_url, api_error_message } from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

const PmNotification = () => {
  const history = useHistory();
  var authID = localStorage.getItem("user_id");
  const [pmNotificationData, setPmNotificationData] = useState([]);
  const [mark_All_Read, setMark_All_Read] = useState([]);
  const [noPmNotification, setNoPmNotification] = useState("");
  let [notify_count, setNotifyCount] = useState(0);

  useEffect(() => {
    getPmNotifications();
  }, []);
  const getPmNotifications = () => {
    axios
      .post(base_url + "get_pm_notification", {})
      .then((res) => {
        if (res.data.data?.notifications) {
          let data = [...res.data.data.notifications].filter((item) => {
            if (item.body.includes("GMT")) {
              let message = item.body;
              item.body = message
                .replace(" ,\n ", ",\n")
                .replace("GMT+0000 (Coordinated Universal Time)", "IST");
              return item;
            } else {
              return item;
            }
          });
          if (res.data.data.notifications.length < 1) {
            setNoPmNotification("No new notification");
          } else {
            setNotifyCount(res.data.data.unread_notifications_count);
            setPmNotificationData(res.data.data.notifications);
          }
        } else {
          setNoPmNotification("No new notification");
          setPmNotificationData(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
        console.log(err);
      });
  };
  const markAllasRead = () => {
    setNotifyCount(0);
    axios
      .post(base_url + "update_pm_push_notification", {})
      .then((res) => {
        localStorage.setItem("pmacknowledged", res.data.data.acknowledged);
        setMark_All_Read(res.data.data);
        getPmNotifications()
      })
      .catch((err) => {
        if (err.response?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        } else if(err.message == 'Request aborted'){
          window.location.reload()
        }else {
          toast.warn(api_error_message, { toastId: random_number });
        }
        console.log(err);
      });
  };

  return (
    <>
      <div>
        <div className="btn-group Left-aligned">
          <div
            className="notifications_bell"
            id="navbarDropdownMenuLink"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <a
              href="#"
              className="badge rounded-pill badge-notification notific-badge bg-danger"
            >
              {notify_count > 0 ? notify_count : ""}
            </a>
            <img src="/images/notification_bell_icon.svg"></img>
          </div>
          <div
            className="dropdown-menu dropdown-menu-end notification_menu"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <div>
              <div className="d-flex flex-row">
                <a className="dropdown-item col custm_txt_clr no_cursor no_hover_color p-0">
                  Notifications{" "}
                </a>
                {notify_count > 0 && (
                  <a
                    className="fw-light fs-6 col d-flex align-items-end justify-content-end mark_all_read"
                    onClick={markAllasRead}
                  >
                    {noPmNotification ? (
                      ""
                    ) : (
                      <p className="custm_txt_clr"> Mark all as read</p>
                    )}
                  </a>
                )}
              </div>
              <hr className="dropdown-divider" />
              {pmNotificationData.length < 1
                ? noPmNotification
                : pmNotificationData.map((ndata) => {
                    return (
                      <div key={ndata._id}>
                        <div>
                          <div className="dropdown-item d-flex justify-content-start">
                            <div>
                              <i
                                className={`${
                                  ndata.is_viewed == 0
                                    ? "fa-solid fa-envelope custm_txt_clr"
                                    : "fa-solid fa-envelope-open custm_txt_clr"
                                } pe-2`}
                              ></i>
                            </div>
                            <div>
                              <h5 className="notific_text">{ndata.title}</h5>
                              <p className="notific_text">
                                {ndata.body != "You earned 0 points"
                                  ? ndata.body
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>

                        <hr className="dropdown-divider" />
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PmNotification;
