
import axios from "../../axios-interceptor";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { base_url, random_number,api_error_message } from "../../utilities";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table } from "react-bootstrap";
export const HandoutSLAs = (props)=>{
  const history = useHistory();
  const location=useLocation();
  let handoutItem=location.state?.handout_item;
  const SLAData=location.state?.Sla_Data
  let [SLAHeadout,setSLAHeadout]=useState(null)
  let [roleCount,setRoleCount]=useState(3)
  let [confirmationCode,setConfirmationCode]=useState('')
  const [inputs, setInputs] = useState([]);
  let [isDuplicateRoleNames, setIsDuplicateRoleNames] = useState(false);
  const save_button = document.querySelector('.save_button');
  let [disable_flag,setDisableFlag] = useState(false)
  let [sla_id,setSlaId] = useState(null)
  let [roles_count,setRolesCount] = useState(0)
  let [existedPoppedItems,SetExistedPoppedItems]=useState([])
  let [loading_flag,SetLoading_flag]=useState(false)
  let [Rolesdisable_flag,setRolesdisable_flag]=useState(false)
    const handleInputChange = (index, event) => {
      const values = [...inputs];
      values[index][event.target.name] = event.target.value;
      setInputs(values);
    };
   
    const handleAddInput = (count) => {
        if(handoutItem?.sla_id.length !=0 && handoutItem.sla_id.length !=0){
            let values = [...inputs]
            if(count>roles_count){
                    let roles = handoutItem.sla_id[handoutItem.sla_id.length-1].roles
                    for (let index = roles_count; index < count; index++) {
                        if(roles[index])
                        {
                            values.push({RoleName_Text:roles[index].role_name,RoleId:roles[index].role_id, RoleName:roles[index].role_name,'RoleNametouched':true, File:roles[index].role_handout_url,Filetouched:true,FileName:roles[index].role_handout_url,disable:roles[index].role_handout_url?true:false,doc_disable:roles[index].role_handout_url?false:true});
                        }
                        else{
                            values.push({RoleName_Text:`Role ${[index+1]}`,RoleId:"", RoleName: "",'RoleNametouched':false, File: "",Filetouched:false,FileName:"",disable:false, doc_disable:false});
                        }
                         setRolesCount(prev=>prev+1)
                       }
                        values.map(insertedItem=>{
                            SetExistedPoppedItems(prevItems =>
                                prevItems.filter(item => !insertedItem.RoleId.includes(item.RoleId))
                              );
                        })     
                    setInputs(values);
            }
            else if(count<roles_count){
                let poppedItemsArr=[]
                let final_count = roles_count-count
                for(let i = 0; i< final_count ;i++){
                let poppedItem=values.pop()
                if(poppedItem.RoleId)
                {
                    poppedItemsArr.unshift(poppedItem)
                }
                setRolesCount(prev=>prev-1)
                }
                SetExistedPoppedItems(prevPoppedItems => [...prevPoppedItems, ...poppedItemsArr]);
                setInputs(values);
            }
        }else{
            let values = []
            for (let index =0; index < count; index++) {
                values.push({RoleName_Text:`Role ${[index + 1]}`,RoleId:"", RoleName: "",'RoleNametouched':false, File: "",Filetouched:false,FileName:"" ,disable:false,doc_disable:false});
            }
            setInputs(values);
        }
       
          };
          const handleInputBlur = (index, event) => {
            const { name, value } = event.target;
            setInputs((inputs) =>
                inputs.map((input, i) =>
                    i === index ? { ...input, [name]: value, [name+'touched']: true } : input
                )
            );
        };
  
    const handleRemoveInput = (index) => {
     setInputs([]);
    };

    useEffect(() => {
        getSLaHandoutsData()
        

    }, [])

    const getSLaHandoutsData=()=>{
            if(handoutItem?.sla_id && handoutItem?.sla_id?.length !=0 ){
                setSlaId(handoutItem?.sla_id[handoutItem?.sla_id?.length-1]._id)
                let count =handoutItem?.sla_id[handoutItem?.sla_id?.length-1].roles_count
                let roles = handoutItem?.sla_id[handoutItem?.sla_id?.length-1].roles
                // handleAddInput(count)
                setRolesCount(count)
                setRoleCount(count)
                setConfirmationCode(handoutItem?.sla_id[handoutItem?.sla_id?.length-1].confirmation_code)
                setSLAHeadout(handoutItem)
                let values = []
                for (let index = 0; index < count; index++) {
                    if(roles[index])
                    {
                    values.push({RoleName_Text:roles[index].role_name,RoleId:roles[index].role_id, RoleName:roles[index].role_name,'RoleNametouched':true, File:roles[index].role_handout_url,Filetouched:true,FileName:roles[index].role_handout_url,disable:roles[index].role_handout_url?true:false, doc_disable:roles[index].role_handout_url?false:true});
                    }
                }
                setInputs(values);
        
            }else{
                handleAddInput(3)
                setConfirmationCode(generateRandomCode())
                setSLAHeadout(handoutItem)
            }
    }

    const fetchUpdatedHandoutData=()=>{
            //SetItems as initial values
            setSLAHeadout(null)
            setRoleCount(3)
            setConfirmationCode('')
            setInputs([]);
            setIsDuplicateRoleNames(false);
            setSlaId(null)
            setRolesCount(0)
            SetExistedPoppedItems([])
            setRolesdisable_flag(false)
            setDisableFlag(true)
            SetLoading_flag(true)
            axios.post(base_url+'sla/get_sla_modules_list',{"course_id":SLAData?.course_id ,
            "program_id":SLAData?.program_id })
            .then(res=>{
               let foundArr= res?.data?.data?.modules.filter(item=>item.module_id===handoutItem.module_id)
               if(foundArr.length>0) 
               {
                handoutItem=foundArr[0]
                getSLaHandoutsData()
               }
               SetLoading_flag(false)
            })
            .catch(err=>{
                console.log(err)
                SetLoading_flag(false);
                if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
                    localStorage.clear();
                    history.push('/adminlogin')
                    window.location.reload();
                }else if(err.message == 'Request aborted'){
                    window.location.reload()
                  }else{
                   toast.warn(api_error_message,{toastId:random_number});
                }
                
            })
    }
   
    const generateRandomCode=()=> {
        const codeLength = 6;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let code = '';
      
        for (let i = 0; i < codeLength; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          code += characters[randomIndex];
        }
      
        return code;
      }

    const  handleChange=(e)=>{
        const target = e.target;
        const name = target.name;
        const value = target.value;
        if(name=='roles')
        {
          const pattern = /^[0-9]+$/;
          if (value === "" || pattern.test(value)) {
            setRoleCount( value );
            if(value==="")
            {
                if(handoutItem.sla_id.length ==0){
                handleRemoveInput()
                }
            }
            else{
                // handleAddButtonInput(parseInt(value))
                handleAddInput(parseInt(value))

            }
          }
          value===""?save_button.disabled = true:save_button.disabled = false
        }
        if(name=='ConfirmationCode')
        {
            setConfirmationCode(value)
            value===""?save_button.disabled = true:save_button.disabled = false
        }
    }
    const handleSubmit = (event) => {
            event.preventDefault();
           let fileNotuploadedArr= inputs.filter(item=>item.File=="")
            save_button.disabled = true
            setDisableFlag(true)
            const fd = new FormData();
            let {course_id,module_id,lesson_id,program_id }=SLAHeadout
            fd.append('roles_count', roleCount);
            fd.append('confirmation_code', confirmationCode);
            fd.append('course_id',course_id);
            fd.append('module_id',module_id)
            fd.append('lesson_id', lesson_id);
            fd.append('program_id', program_id);
            let allRoleNames = []
            let allRoleIds=[]
                inputs.map(item=>{
                if(!item.RoleId)
                {
                    allRoleNames.push(item.RoleName)
                    // allRoleIds.push(item.RoleId)
                //    if(item.File !='' && typeof item.File === 'object'){
                //        allRoleNames.push(item.RoleName)
                //     }
                }
                else{
                       allRoleNames.push(item.RoleName)
                       allRoleIds.push(item.RoleId)
                }
                existedPoppedItems.map(item=>{
                    allRoleIds.push(item.RoleId)
                })
            })
            const hasDuplicates = new Set(allRoleNames).size !== allRoleNames.length;
            if(hasDuplicates)
            {
                setIsDuplicateRoleNames(true)
                save_button.disabled = false
                setDisableFlag(false)
                return false;
            }
            else{
                setIsDuplicateRoleNames(false)
                fd.append('roles',allRoleNames);
                if(allRoleIds.length>0) fd.append('role_ids',allRoleIds);
            } 
            inputs.map((item,index)=>{ //for new items
                // if(!item.RoleId)
                // {
                    if(item.File=='')
                    {
                        setInputs(inputs => {
                            const updatedInputs = [...inputs];
                            updatedInputs[index] = { ...updatedInputs[index], 'Filetouched': true };
                            return updatedInputs;
                          });
                        return false
                    }
                    else{
                        if( typeof item.File === 'object'){
                            fd.append(item.RoleName, item.File);
                        }
    
                    }
                // }
            })
            if(handoutItem.sla_id.length !=0){
                fd.append('sla_id',sla_id)
            }
            // if(fileNotuploadedArr.length==0)
            // {
                SetLoading_flag(true)
                setRolesdisable_flag(true)
                axios.post(base_url+'sla/save_sla_configuration',fd)
                .then(res=>{
                   toast.success(res.data?.message,{toastId:random_number});
                   save_button.disabled = false
                   setDisableFlag(true)
                   SetLoading_flag(false)
                   setRolesdisable_flag(false)
                   fetchUpdatedHandoutData()
                //    handleBackToEditSLA()
                })
                .catch(err=>{
                    console.log(err);
                    setDisableFlag(false)
                    SetLoading_flag(false)
                    setRolesdisable_flag(false)
                    save_button.disabled = false;
                    if(err.response?.statusText=='Unauthorized'){
                        localStorage.clear();
                        history.push('/')
                        window.location.reload();
                    }else if(err.message == 'Request aborted'){
                        window.location.reload()
                      }else{
                        toast.warn(api_error_message,{toastId:random_number});
                    }
                })
            // }
            // else{
            //     toast.error("Please upload the documents!..",{toastId:random_number});
            //     save_button.disabled = false
            //     setDisableFlag(false)
            // }
     };
     const handleBackToEditSLA=()=>{
        history.push('/admindashboard/editsla',{"Sla_Data":SLAData})
     }
     const handleFileInputChange = (event,index) => {
        const updatedInputs = [...inputs];
        const file = event.target.files[0];
        updatedInputs[index] = { ...updatedInputs[index], File: file, FileName: file.name,doc_disable:true };
        setInputs(updatedInputs);
      }
     const selectFile=(index)=>{
        const updatedInputs = [...inputs];
        updatedInputs[index] = { ...updatedInputs[index], 'Filetouched': true };
        setInputs(updatedInputs);
        const fileUpload = document.getElementById(`file-${index}`);
        fileUpload.click();
     }

    return(
        <>
        {loading_flag&& 
        <>
          <div>
             <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
          </div>
        </>}
          <div className="bg-body container ">
            <div className="mt-3">
               <span className="SLA_edit_sub_heading" data-testid="sub_headings">{SLAHeadout?.course_name} <span><i class="fa-solid fa-chevron-right sla_right_arrow"></i></span> {SLAHeadout?.lesson_name} <span><i class="fa-solid fa-chevron-right sla_right_arrow"></i></span> <span className="SLA_Handout_text">Handouts</span></span>
            </div>
             <div className="mt-5 ml-3">
                <div className="row">
                    <div className="col-lg-6">
                    <div className="d-flex">
                        <span className="SLA_handout_subheads">Roles</span>
                        <span><input onChange={handleChange} disabled={Rolesdisable_flag} minLength="1" maxLength={'2'} value={roleCount} type="text"  name="roles" className="form-control boder-radius-0 SLA_handoutOne" data-testid="roles_count"/></span>
                    </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="d-flex">
                        <span className="SLA_handout_subheads">Confirmation Code</span>
                        <span><input type="text" onChange={handleChange} disabled={disable_flag} value={confirmationCode} name="ConfirmationCode" className="form-control boder-radius-0 SLA_handoutOne text-center" data-testid="confirmation_code"/></span>
                    </div>
                    </div>
                </div>

                <div className="mt-3">
                    <span className="Role_count">{roleCount} Roles are created based on the number above</span>
                </div>

             {isDuplicateRoleNames&&<div className="d-block  mt-2 ml-5 pl-4">
                        <span className="Role_count requiredInput">* Role Names should be uinique </span>                   
                </div>}

                <div className="mt-3">
                <form onSubmit={handleSubmit} data-testid="form_container">
                    <div className="container"  data-testid="inputs_container">
                        {inputs.map((item, index) => (
                            
                         <>
                        <div className="row mb-3" key={index}>
                            <div className="col-lg-1 rolename_top">
                                {item.RoleName_Text}
                            </div>
                            <div className="col-lg-3">
                                <div className="text-center">
                                   <span>Role Name</span>
                                </div>
                            <input
                                disabled={disable_flag}
                                type="text"
                                className="form-control"
                                name="RoleName"
                                value={item.RoleName}
                                required
                                data-testid={`input_role_handle_${index}`}
                                onBlur={(event) => handleInputBlur(index, event)}
                                aria-describedby={`roleNameError-${index}`}
                                onChange={(event) => handleInputChange(index, event)}
                            />
                              {item.RoleNametouched&&item.RoleName === '' && (
                                <div className="text-danger" id={`roleNameError-${index}`}>
                                    Role name is required.
                                </div>
                                )}
                            </div>
                            <div className="col-lg-4">
                            <div className="text-center visible-hidden">
                                   <span>File</span>
                                </div>
                                <div className="d-flex">
                                {item.disable && <>
                                <a href={item.FileName} download className="mr-3">
                                    <img className="memo_img cursor-pointer" src="/images/handouts.svg" alt=""  />
                                    
                                    </a>
                            
                                </>}<>
                            <input
        
                                type="text"
                                className="form-control Upload_file_name"
                                name="FileName"
                                value={item.doc_disable ? item.FileName:''}
                                disabled={true}
                                readOnly
                                aria-describedby={`fileNameError-${index}`}
                            />
                                 {/* {item.Filetouched&&item.FileName === '' && (
                                <div className="text-danger" id={`fileNameError-${index}`}>
                                    Document is required.
                                </div>
                                )} */}
                             </>
                            <input type="file" name={`file-${index}`}  id={`file-${index}`} accept=".pdf" className="d-none" data-testid={`file-${index}`}  onChange={(e)=>handleFileInputChange(e,index)}/>
                            </div>
                            </div>
                          
                            <div className="col-lg-4">
                                <button type="button" onClick={(e)=>selectFile(index)} data-testid={`upload_file-${index}`} className="btn Upload_File">Upload File</button>
                            </div>
                           
                        </div>
                         </>   
                        ))}
                    </div>
                    <div className="d-block text-center">
                        <span >
                            <button className="btn Upload_File pl-4 pr-4" onClick={handleBackToEditSLA} data-testid="back_button">Back</button>
                        </span>
                        
                        <span className="ml-4">
                            <button type="submit" className="btn Upload_File pl-4 pr-4 save_button" data-testid="back">{sla_id ? 'Update':'Save'}</button>
                        </span>
                        
                    </div>
                   
                    </form>
                   
                </div>
             </div>
        </div>
        </>
    )
}
