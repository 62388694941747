import axios from "../../axios-interceptor";
import { useState,useEffect} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { base_url,date_formats, random_number,api_error_message} from "../../utilities";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table } from "react-bootstrap";
import moment from "moment";
import DataTable from 'react-data-table-component';
import Select from "react-select";

export const ActiveProgrammesAndSLAs = (props)=>{
  const history = useHistory();
  const location = useLocation();
  let [activeProgammeList,setActiveProgammeList]=useState([]);
  let [slaIndex,setSlaIndex]=useState([]);
  let [loading_flag,Setloading_flag]=useState(true)
  let [message,setMessage] = useState(false)
  const [programmes, SetAllProgrammes] = useState([]);
  let [course,setCourses] = useState([])
  let [modules_list,setModulesList] =useState([])
  let [ programme_id,setProgrammeId] =useState('')
  let [ course_id,setCourseId] =useState('')
  let [data,setData] = useState([])
  let [flag, setFlag] = useState(true)
  let programs_list = []
  let active_program_list = []
  let data_flag = false
  const columns = [
    {
      name: 'Module',
      selector: row => (
        <div className={row?.is_group_assigned ? 'fw-bold' : ''}>
          {row.module}
        </div>
      ),
      sortable: true,
    },
    {
        name: '',
        selector: row => row.is_group_assigned,
        sortable: true,
        style: {
            display: 'none',
          },
    },
    {
        name: '',
        selector: row => row.module_id,
        sortable: true,
        style: {
            display: 'none',
          },
    },
    {
        name: 'Roles',
        selector: row => row.roles,
        sortable: true,
    },
    {
        name: 'Code',
        selector: row => row.code,
        sortable: true,
    },
    {
      name: 'Date',
      selector: row => (
        <input
          data-testid="Date_Input"
          type="Date"
          name="Date"
          value={row.date}
          className="form-control sla-date"
          onChange={e => {
            handleCellValueChange(row.id, 'date', e.target.value);
            handleChange(e, row.module_id);
          }}
        />
      ),
      sortable: true,
    },
    {
      name: 'Time',
      selector: row => (
        <input
          data-testid="Time_Input"
          type="Time"
          name="Time"
          value={row.time}
          className="form-control sla-date"
          onChange={e => {
            handleCellValueChange(row.id, 'time', e.target.value);
            handleChange(e, row.module_id);
          }}
        />
      ),
      sortable: true,
    },
    
    {
        name: 'Assign Roles',
        selector: row => row.assign_roles,
        sortable: true,
    },
    {
        name: 'Send Emails',
        selector: row => row.send_emails,
        sortable: true,
      
    },

];

  
const customFilterOption = (option, searchText) => {
    const optionLabel = option.label?.toLowerCase();
    const searchLower = searchText?.toLowerCase();
    return optionLabel?.includes(searchLower);
  };

  const optionsProgramm = programmes.map((app) => ({
    value: app._id,
    label: app.program_display_name ? app.program_display_name : " " ,
  }));
  const optionsCourse = course.map((app) => ({
    value: app.course_id,
    label: app.course_title,
    is_bold : app.is_bold
  }));
  const formatOptionLabel = ({ value, label,is_bold }, { isSelected }) => (
    <span style={{ fontWeight: is_bold ? 'bold' : 'normal' }}>
      {label}
    </span>
  );

// const data = [
//     {
//         id: 1,
//         title: 'Beetlejuice',
//         year: '1988',
//     },
//     {
//         id: 2,
//         title: 'Ghostbusters',
//         year: '1984',
//     },
// ]

    useEffect(() => {
      
        localStorage.removeItem('sla_id')
        axios.post(base_url + 'admin/get_program_applications_for_review', {
        }).then(response => {
            response.data.result.map(item=>{
                programs_list.push(item)
            })
            // SetAllProgrammes(response.data.result)
            axios.post(base_url+"program_manager/get_sla_data")
            .then(res=>{
                if(res.data?.data.length == 0){
                    setMessage(true)
                }else{
                    const resultSet = new Set();
                    res.data.data.map(i=>{
                       response.data.result.map(item=>{
                            if(item._id == i.program_id ){
                                item?.courses.map(course=>{
                                    if(course.course_id == i.course_id){
                                        resultSet.add(item)
                                    }
                                })
                            }
                        })
                    })
                    const resultArray = Array.from(resultSet);
                    SetAllProgrammes(resultArray)
                    res.data?.data.map(item=>{
                        if(item.start_date){
                            item['Date'] = moment(item.start_date).format("YYYY-MM-DD")
                            item['Time'] = moment(item.start_date).format("HH:mm")
                        }
                        active_program_list.push(item)
                    }) 
                    setActiveProgammeList(res.data?.data);
                    // programs_list.push(res.data?.data)
                }
                Setloading_flag(false)
                if(localStorage.getItem('sla_program_id') && localStorage.getItem('sla_course_id')){
                     data_flag = true
                    handleProgrammeChange(localStorage.getItem('sla_program_id'))
                }
            })
            .catch(err=>{
                console.log(err);
                if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText == 'Unauthorized'){
                    localStorage.clear();
                    history.push('/adminlogin')
                    window.location.reload();
                }else if(err.message == 'Request aborted'){
                  window.location.reload()
                }else{
                  toast.warn(api_error_message,{toastId:random_number});
                }
            })
        }).catch(err => {
          console.log(err);
          if (err.response?.statusText == 'Unauthorized' || err.response?.data?.statusText == 'Unauthorized') {
            localStorage.clear();
            history.push('/')
            window.location.reload();
          }else if(err.message == 'Request aborted'){
            window.location.reload()
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        })
       
    }, [])
    const handleCellValueChange = (id, columnName, value) => {
        // Find the row in the table data and update the cell value
        const updatedData = data.map(row => {
          if (row.id === id) {
            return { ...row, [columnName]: value };
          }
          return row;
        });
        setData(updatedData);
      };
    const handleChange=(e,id)=>{
        setFlag(false)
        const target = e.target;
        const name = target.name;
        const value = target.value;
        let progammeList = [...activeProgammeList]
        if(name=="Date")
        {
            const updatedItems = progammeList.map((item,index) => {
                if((item.course_id == localStorage.getItem('sla_course_id') && item.program_id == localStorage.getItem('sla_program_id')) && item.module_id == id ){
                    setSlaIndex((current) => [...current, index]);
                    return { ...item, Date: value };
                 
            }
            return item;
              });
              
              // Update the state with the new array
              setActiveProgammeList(updatedItems);
        }
        if(name=="Time")
        {
            const updatedItems = progammeList.map((item,index) => {
                if((item.course_id == localStorage.getItem('sla_course_id') && item.program_id == localStorage.getItem('sla_program_id')) && item.module_id == id ){
                    setSlaIndex((current) => [...current, index]);
                    return { ...item, Time: value };
            }
            return item;
              });
              // Update the state with the new array
              setActiveProgammeList(updatedItems);
        }
        
    }
    const handleRoleAssign=(value)=>{
        
        history.push('/programmanagementdashboard/pslaroleassign',{sla_Data:value})
    }
    const handleSlaNotification=(value,id)=>{
        localStorage.setItem('sla_id',id)
        history.push('/programmanagementdashboard/pslanotifications',{sla_Data:value})
    }
    const saveSlaDateTime=()=>{
        setFlag(true)
        let uniqueslaIndex = [...new Set(slaIndex)];
        let progammeList = [...activeProgammeList]
        let slaArray = [];
        uniqueslaIndex.map(item=>{
            // progammeList.map(val=>{
            //     if(val.module_id == item){
                    let date = progammeList[item]['Date'] ? progammeList[item]['Date'] : '';
                    let time = progammeList[item]['Time'] ? progammeList[item]['Time'] : '';
                      let sla_data = {
                          "id": progammeList[item]['id'],
                          "confirmation_code": progammeList[item]['confirmation_code'],
                          "start_date": moment(date + ' ' + time, 'YYYY-MM-DD HH:mm').valueOf(),
                          "lesson_id": progammeList[item]['lesson_id'],
                          "module_id": progammeList[item]['module_id'],
                          "program_id": progammeList[item]['program_id'],
                          "course_id": progammeList[item]['course_id'] 
                      }
                      slaArray.push(sla_data)
            //     }
            // })
          
        })
        axios.post(base_url+"program_manager/update_sla_data",{
            "sla_data": slaArray
            }).then(res=>{
                localStorage.setItem('slaDateTimeSave',true)
                setSlaIndex([])
                if(localStorage.getItem('sla_program_id') && localStorage.getItem('sla_course_id')){
                    data_flag = true
                   handleProgrammeChange(localStorage.getItem('sla_program_id'))
               }
                // window.location.reload();
                toast.success("Successfully Updated!..",{toastId:random_number});
            }).catch(err=>{
                setSlaIndex([])
                setData([])
                setProgrammeId('')
                setCourseId('')
            if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText == 'Unauthorized'){
                localStorage.clear();
                history.push('/adminlogin')
                window.location.reload();
            }else if(err.message == 'Request aborted'){
              window.location.reload()
            }else{
                toast.warn(api_error_message,{toastId:random_number});
            }
        })
    }
    const handleProgrammeChange = (id) => {
        setProgrammeId(id)
         if(data_flag){
            setCourseId(localStorage.getItem('sla_course_id'))
            let courses_list =[]
            programs_list.map(item=>{
                if(item._id == id){
                    courses_list =item.courses
                    // setCourses(item.courses)
                }
             })
             programs_list.map(item=>{
                item.courses.map(course=>{ course['is_bold'] = false})
            })
            active_program_list.map((item) => {
                for (var course of courses_list) {
                   if (course.course_id == item.course_id && item.program_id == id) {
                     course['is_bold'] = true
                     break;
                   }
                 }
               })
               setCourses(courses_list)
             getData(localStorage.getItem('sla_course_id'), localStorage.getItem('sla_program_id'))
         }else{
            localStorage.setItem('sla_program_id',id)
            setModulesList([])
           
            setCourseId('')
            let programs =[...programmes]
            // programs.map(item=>{
            //     if(item._id == id){
            //         setCourses(item.courses)
            //     }
            //  })
             let progammeList = [...activeProgammeList]
            programs.map(item=>{
                item.courses.map(course=>{ course['is_bold'] = false})
            })
            let courses_list = []
            programs.map(item=>{
                    if(item._id == id){
                        courses_list =item.courses
                    }
             })
             progammeList.map((item) => {
             for (var course of courses_list) {
                if (course.course_id == item.course_id && item.program_id == id) {
                  course['is_bold'] = true
                  break;
                }
              }
            })
            setCourses(courses_list)
         }
    }
    const dropdownStyles = {
      control: (provided) => ({
        ...provided,
        whiteSpace: 'nowrap', // Prevent text from wrapping to the next line
        overflow: 'hidden', // Hide any overflow
      }),
      menu: (provided) => ({
        ...provided,
        minWidth: '500px', // Set the desired width for the menu
      }),
      };
      
    const handleCourseChange = (id) => {
        localStorage.setItem('sla_course_id',id)
        setData([])
        setModulesList([])
        setCourseId(id)
        getData(id,programme_id)
      
    }
    const getData=(course_id,program_id)=>{
        let program_list
        if(data_flag){
            program_list =[...active_program_list] 
        }else{
            program_list = [...activeProgammeList]
        }
        
        program_list.map((item,index)=>{
            if(item.course_id == course_id && item.program_id == program_id){
                let table_data = {
                    id: index+1,
                    module: item.module_name,
                    module_id: item.module_id,
                    is_group_assigned: item?.is_group_assigned??false,
                    roles: item.roles_count,
                    code: item.confirmation_code,
                    date: item.Date,
                    time:  item.Time ,
                    assign_roles: <img className="cursor-pointer" data-testid="Assign_Roles" src="/images/assign_roles.svg" onClick={()=>handleRoleAssign(item)}></img>,
                    send_emails:<div>
                    <img className="cursor-pointer" data-testid="Send_Emails" src="/images/Mail.svg" onClick={()=>handleSlaNotification(item, item.id)}></img></div> 
                     
                }
                setModulesList(current=>[...current,item])
                // let data=[]
                // data.push(table_data)
                setData(current=>[...current,table_data])
            }
         })
    }
    return(
        <>
        <div className="bg-body container ">
        {loading_flag?<>
                <div className="mt-5">
                   <img className="atrium_loader_custom" src="/images/atrium_loader.gif" alt="Loader" />
                </div>
            </>:<>
           {message? <h3 data-testid="no_records">No Records Found</h3>:
           <>
            <div data-testid="Main_Heading">
               <span className="SLA_Main_heading" >Active Programs & SLA's</span>
            </div>
           <>
           <div className="row mt-2">
           <div className="col-lg-4">
            
            <label className="program_sla_lable" data-testid="Programme_Label">Select Programme</label>
   
                  <Select
                 
                  id="programms"
                  aria-label=".form-select example"
                  name="programme_name"
                  options={optionsProgramm}
                  placeholder="Select the Programme"
                  onChange={(selectedOption) => {
                    handleProgrammeChange(selectedOption.value);
                  }}
                  backspaceRemovesValue
                  filterOption={customFilterOption}
                  value={optionsProgramm.find(option => option.value === programme_id)} // Set the selected option
                  menuPortalTarget={document.body} // Prevent scrollable behavior
                  styles={dropdownStyles}
                  classNamePrefix="react-select-Programme" // Use a more specific class prefix
                />
                 <select
                   id="programms"
                   aria-label=".form-select example"
                   name="programme_name"
                  data-testid="Programme_Dropdown"
                  className="form-select d-none"
                  filterOption={customFilterOption}
                  role="presentation" autoComplete="off"
                //   value={pr}
                  // required
                  onChange={(selectedOption) => {
                    handleProgrammeChange(selectedOption.target.value);
                  }}>
                  <option value=''>Select the Programme</option>
                  {optionsProgramm.map((item,index) => {
                     return <option  key={index} value={item.value} >{item.label}</option>;
                  })}
                </select>
                </div>
                <div className="col-lg-4">
                <label className="program_sla_lable">Select Course</label>
                  <Select
                  id="course"
                  aria-label=".form-select example"
                  name="course_name"
                  options={optionsCourse}
                  formatOptionLabel={formatOptionLabel}
                  placeholder="Select the Course"
                  value={optionsCourse.find(option => option.value === course_id)} 
                  onChange={(selectedOption) => {
                    handleCourseChange(selectedOption.value);
                  }}
                  classNamePrefix="react-select-Programme" 
                />
                 <select
                    id="course"
                    aria-label=".form-select example"
                    name="course_name"
                  data-testid="Course_Dropdown"
                  className="form-select d-none"
                  role="presentation" autoComplete="off"
                  // value={selectedProgram}
                  // required
                  onChange={(selectedOption) => {
                    handleCourseChange(selectedOption.target.value);
                  }}>
                  <option value=''>Select the Course</option>
                  {optionsCourse.map((item,index) => {
                     return <option  key={index} value={item.value} >{item.label}</option>;
                  })}
                </select>
                </div>
           </div>
             <div className="mt-2">
                    {/* <Table className="createSLA-table email-status" bordered={false}>
                            <thead className="fs-20">
                                <tr  style={{background:'#057092'}}>
                               
                                    <th className="text-left">Module</th>
                                    <th className="text-left">Roles</th>
                                    <th className="text-left">Code</th>
                                    <th className="text-left">Date</th>
                                    <th className="text-left">Time</th>
                                    <th className="text-left">Assign Roles</th>
                                    <th className="text-left">Send Emails</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                            {modules_list.map((item, index) => {
                                return (
                                <tr key={index} className="ActProg_SLA_row">
                                   
                                    <td className="text-start">
                                           {item.module_name} 
                                    </td>
                                    <td className="fs-18">
                                        {item.roles_count}
                                    </td>
                                    <td className="fs-18">
                                        {item.confirmation_code}
                                    </td>
                                    <td>
                                      <input type="date"  name="Date"  value={item?.Date} onChange={(e)=>handleChange(e,index)} id="MyDate" className="form-control sla-date "/>  
                                    </td>
                                    <td>
                                    <input type="time"  name="Time" value={item?.Time} onChange={(e)=>handleChange(e,index)}  className="form-control sla-date"/>  
                                    </td>
                                    <td>
                                        <div className="Actsla_table_icons">
                                            <span>
                                               <i class="fa-regular fa-pen-to-square cursor-pointer" onClick={()=>handleRoleAssign(item)}></i>
                                            </span>
                                          
                                        </div>
                                    </td>
                                    <td>
                                        <div className="Actsla_table_icons">
                                            <span>
                                            <img className="cursor-pointer" src="/images/Mail.svg" onClick={()=>handleSlaNotification(item, item.id)}></img>
                                            </span>
                                            
                                        </div>
                                    </td>
                                   
                                </tr>
                                );
                            })}
                            </tbody>
                </Table> */}
                <div data-testid="Active_Slas_Table" >
                <DataTable className="active_program-table" columns={columns} data={data} />
                <div className="d-none" data-testid="Table_Rows_Count">{data.length}</div>
                </div>
             </div>
             <div className="d-block text-right mr-5">
                        <span>
                            <button data-testid="Save_Button" type="submit" disabled={flag} className="btn my_btn_cls pl-4 pr-4" onClick={saveSlaDateTime}>Save</button>
                        </span>
                    </div>
            </>
            </>}
            </>}
        </div>
        </>
    )
}
