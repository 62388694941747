import axios from "../../axios-interceptor";
import { useState, useEffect, Fragment, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  base_url,
  date_formats,
  random_number,
  api_error_message,
  formatSentence,
} from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import DataTable from "react-data-table-component";
import { useTable } from "react-table";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
export const ActivityLogsPage = () => {
  const tableRef = useRef(null);
  let [programmes, SetAllProgrammes] = useState([]);
  let [course, setCourses] = useState([]);
  let [programme_id, setProgrammeId] = useState("");
  let [course_id, setCourseId] = useState("");
  let [program_options, setprogramOptions] = useState([]);
  let [course_options, setCourseOptions] = useState([]);
  let [data, setData] = useState([]);
  let [columns, setColumns] = useState([]);
  let [loading_flag, setLoadingFlag] = useState(false);
  let [noRecordsMessage, setNoRecordsMessage] = useState(false);
  let programs_list = [];
  const history = useHistory();
  const location = useLocation();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });
  useEffect(() => {
    const table = document.getElementById("activity_logs_table");
    console.log(table);
    axios
      .post(base_url + "admin/get_program_applications_for_review", {})
      .then((response) => {
        SetAllProgrammes(response.data.result);
        response.data.result.map((item) => {
          programs_list.push(item);
        });
        setprogramOptions(
          programs_list.map(({ _id, program_display_name }) => ({
            value: _id,
            label: program_display_name,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  }, []);
  const handleProgrammeChange = (val) => {
    setProgrammeId(val);
    setCourseId([])
    setData([]);
    setColumns([]);
    let programs = [...programmes];
    programs.map((item) => {
      if (item._id == val.value) {
        setCourses(item.courses);
        setCourseOptions(
          item.courses.map(
            ({ course_id, course_title, cohort_schedule_id }) => ({
              value: course_id,
              label: course_title,
              cohort_schedule_id: cohort_schedule_id,
            })
          )
        );
      }
    });
  };
  const containsArray = (obj) => {
    for (let key in obj) {
      if (Array.isArray(obj[key])) {
        let result = {
          course_name: key,
          course_data: obj[key],
        };
        return result;
      } else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        if (containsArray(obj[key])) {
          let result = {
            course_name: key,
            course_data: obj[key],
          };
          return result;
        }
      }
    }
    return false;
  };
  const getFinalData = (final_data, index_value, ind) => {
    let result_array = [];
    final_data.map((item, index) => {
      for (let key in item) {
        let module_result = {
          Header: formatSentence(key) + "@" + index_value + ind + index,
          cell: <span>{item[key]}</span>,
        };
        result_array.push(module_result);
      }
    });
    return result_array;
  };
  const getColumnsData = (data, index_value) => {
    let result_array = [];
    data.map((item, index) => {
      for (let key in item) {
        if (Array.isArray(item[key])) {
          let module_result = {
            Header: formatSentence(key) + "@" + index,
            columns: getFinalData(item[key], index_value, index),
          };
          result_array.push(module_result);
        }
      }
    });
    return result_array;
  };
  const getModuleData = (course_data) => {
    let result_array = [];
    course_data.map((item, index_value) => {
      for (let key in item) {
        if (Array.isArray(item[key])) {
          let module_result = {
            Header: formatSentence(key),
            columns: getColumnsData(item[key], index_value),
          };
          result_array.push(module_result);
        }
      }
    });
    return result_array;
  };
  const handleCourseChange = (val, page = 1) => {
    setData([]);
    setColumns([]);
    setCourseId(val);
    setLoadingFlag(true);
    axios
      .post(base_url + "program_manager/get_users_course_progress", {
        course_id: val.value,
        program_id: programme_id.value,
        cohort_schedule_id: val.cohort_schedule_id,
      })
      .then((res) => {
        setLoadingFlag(false);
        const startIndex = (page - 1) * 100;
        const endIndex = startIndex + 100;
        if (res.data?.data && res.data?.data?.length > 0) {
          setNoRecordsMessage(false);
          if (res.data?.data && res.data?.data?.length > startIndex) {
            const currentPageData = res.data?.data?.slice(startIndex, endIndex);
            const table_data = currentPageData.map((item,index) => {
              return {
                sno: index+1,
                name: item.name,
                email: item.email,
                certificate_issued: item.certificate_issued,
                earned_score: item.earned_score,
                total_score: item.total_score,
                score_percentage: item.score_percentage,
                total_badges: item.total_badges,
              };
            });
            setData([...data, ...table_data]);
          }
          else {
            res.data?.data?.map((item,index) => {
              let table_data = {
                sno: index+1,
                name: item.name,
                email: item.email,
                certificate_issued: item.certificate_issued,
                earned_score: item.earned_score,
                total_score: item.total_score,
                score_percentage: item.score_percentage,
                total_badges: item.total_badges,
              };
              setData((prev) => [...prev, table_data]);
            });
          }
          if (endIndex < res.data?.data?.length) {
            handleCourseChange(val, page + 1);
          }
          let table_columns;
          res.data?.data?.map((item) => {
            let sample_data = item;
            const getCourseData = containsArray(sample_data);
            // getCourseData.course_data.shift();
            // getCourseData.course_data.pop();
            table_columns = [
              {
                Header: "S.No",
                accessor: "sno",
              },
              {
                Header: "Name",
                accessor: "name",
              },
              {
                Header: "Email",
                accessor: "email",
              },
              {
                Header: "Earned Score",
                accessor: "earned_score",
              },
              {
                Header: "Score Percentage",
                accessor: "score_percentage",
              },
              {
                Header: "Total Score",
                accessor: "total_score",
              },
              {
                Header: "Total Badges",
                accessor: "total_badges",
              },
              {
                Header: "Certificate Issued",
                accessor: "certificate_issued",
              },
              {
                Header: formatSentence(getCourseData.course_name),
                columns: getModuleData(getCourseData.course_data),
              },
            ];
          });
          setColumns(table_columns);
        } else {
          setNoRecordsMessage(true);
        }
      })
      .catch((err) => {
        setLoadingFlag(false);
        console.log(err);
        if (err.response?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  };
  const flattenNestedColumns = (table) => {
    const flattenedRows = [];
    for (let rowIndex = 0; rowIndex < table.rows.length; rowIndex++) {
      const row = table.rows[rowIndex];
      const flattenedRow = [];
      let colIndex = 0;
      for (let cellIndex = 0; cellIndex < row.cells.length; cellIndex++) {
        const cell = row.cells[cellIndex];
        const colspan = parseInt(cell.getAttribute("colspan") || 1, 10);
        const cellText = `${cell.textContent.trim()}`;
        for (let i = 0; i < colspan; i++) {
          flattenedRow[colIndex] = cellText;
          colIndex++;
        }
      }
      flattenedRows.push(flattenedRow.join(", "));
    }
    return flattenedRows.join("\n");
  };
  const handleExportCSV = () => {
    const table = tableRef.current;
    const csvContent = flattenNestedColumns(table);
    const csvData = "\ufeff" + csvContent;
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "table_data.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  const exportToCSV = () => {
    const table = document.getElementById("activity_logs_table");
    const tableData = [];
    Array.from(table.rows).forEach((row) => {
      const rowData = [];
      let columnIndex = 0; // Track the current column index
      Array.from(row.cells).forEach((cell) => {
        const cellText = cell.textContent.trim();
        const colspan = parseInt(cell.getAttribute("colspan"), 10) || 1;
        for (let i = 0; i < colspan; i++) {
          rowData[columnIndex] = cellText; // Assign cell text to current column index
          columnIndex++; // Move to the next column index
        }
      });
      tableData.push(rowData);
    });
    const csvContent = tableData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "test.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-9">
          <span className="SLA_Main_heading">Activity Logs List</span>
        </div>
        <div className="col-lg-3">
          <ReactHTMLTableToExcel
            id="activity_logs"
            className="download-table-xls-button align-self-end"
            table="activity_logs_table"
            filename={programme_id.label+'_'+course_id.label}
            sheet="activity_logs"
            buttonText="Download as XLS"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-lg-6">
          <label className="program_sla_lable">Select Programme</label>
          <Controller
            as={Select}
            name="programme_name"
            placeholder="Programe Name"
            options={program_options}
            className="form-select form-select mb-2"
            control={control}
            rules={{ required: true }}
            render={({ onChange, value, name }) => (
              <Select
                onChange={handleProgrammeChange}
                value={programme_id}
                name={name}
                options={program_options}
                isSearchable={true}
                placeholder="Select Programme"
                noOptionsMessage={() => "No options available"}
              />
            )}
          />
        </div>
        <div className="col-lg-6">
          <label className="program_sla_lable">Select Course</label>
          <Controller
            as={Select}
            name="course_name"
            options={course_options}
            className="form-select form-select mb-2"
            control={control}
            rules={{ required: true }}
            render={({ onChange, value, name }) => (
              <Select
                onChange={handleCourseChange}
                value={course_id}
                name={name}
                options={course_options}
                isSearchable={true}
                placeholder="Select Course"
                noOptionsMessage={() => "No options available"}
              />
            )}
          />
        </div>
      </div>
      {loading_flag ? (
        <>
          <div className="mt-5">
            <img
              className="atrium_loader_custom"
              src="/images/atrium_loader.gif"
              alt="Loader"
            />
          </div>
        </>
      ) : data.length != 0 ? (
        <div className="custom_overFlw pt-3">
          <table
            ref={tableRef}
            id="activity_logs_table"
            {...getTableProps()}
            className="activity_logs_table"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <th key={column.id + index} {...column.getHeaderProps()}>
                      {" "}
                      {typeof column.Header === "function"
                        ? column.Header().props.children.split("@")[0]
                        : column.Header.toString().split("@")[0]}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td key={random_number} {...cell.getCellProps()}>
                          {cell.value !== undefined
                            ? cell.value
                            : cell.column.cell?.props?.children}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : noRecordsMessage ? (
        <h3>No Records Found</h3>
      ) : (
        ""
      )}
      {/* <DataTable className="active_program-table" columns={columns} data={data} /> */}
    </>
  );
};
