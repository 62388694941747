import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../axios-interceptor";
import { base_url,formatSentence,storefront_url,role_obj,random_number,default_country_code,default_dial_code,api_error_message, user_maintenance_type, land_nave_storefront_url, wrong_link_error_message} from "../../utilities";
import { Modal } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { color, fontSize } from "@mui/system";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { freelessonloginValidations } from "../../formValidationsRules";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
 const FreeLessonLogin = () =>{
    
 const { register, handleSubmit,setValue, formState: { errors } } = useForm({"mode":"all"});
 let [checkBox,setCheckBox]=useState(false)
 let [disableSubmit,setDisableSubmit]=useState(false)
 let [loading_flag,SetLoading_flag]=useState(false);
 let [showModal, setShowModal] = useState(false);
 let [loadPage, setloadPage] = useState(false);
 let [programValue, setProgrammValue] = useState('');
 const [phone, setPhone] = useState("");
 const [countryCode, setCountryCode] = useState(default_country_code);
 const [dialCode, setDialCode] = useState(default_dial_code);
 const [mobileErr, setMobileErr] = useState(false);
 const [countryObj, setCountryObj] = useState({});
 let [ProgrammDetails,setProgrammDetails] = useState([{id:1,value:role_obj.my_self},
    {id:2,value:role_obj.my_team},
    {id:3,value:role_obj.my_organisation}])

    let history =useHistory();
    const [invalidaPage, setInvalidaPage] = useState(false)
  useEffect(()=>{
    if(window.location.pathname=='/freelessonlogin')
    {
    document.getElementById("top-nav-bar").style.display = "flex";
    const cookies = document.cookie.split(';')
    .map(cookie => cookie.trim())
    .reduce((acc, cookie) => {
      const [key, value] = cookie.split('=');
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});

    checkCookies(cookies);
    hideModal();
  }
  else{
    setloadPage(true)
  }
  },[])


  const checkCookies=(cookies)=>{
    const {leadform_name,leadform_email,leadform_mobile,leadform_ProgramId,leadform_role,leadform_country_code}=cookies
    
    if(leadform_name&&leadform_email&&leadform_mobile&&leadform_ProgramId&&leadform_role&&leadform_country_code)
    { 
      setloadPage(false)
      onSubmit({name:leadform_name,email:leadform_email,mobile:leadform_mobile,role:leadform_role,checkBox:true,countryCode:leadform_country_code,cookies:cookies})
    }else{
      setloadPage(true)
    }
  }

  const handleOnChange = (value, country) => {
    setPhone(value);
    setCountryObj(country)
    setDialCode(country.dialCode);
  }


  const goToStoreFront=()=>{
    setProgrammValue('');
    setCheckBox(false);
    let learningtrack  = localStorage.getItem('learningtrack');
    if(learningtrack){
      window.location.href = (`${storefront_url}${learningtrack}`);
    }else{
      window.location.href = `${storefront_url}`
    }
  }

  const hideModal = () => setShowModal(false);

  const handleChangeDropdown = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    if (name == 'role') {
        if (value !== "") {
          ProgrammDetails.map(obj => {
                if (value == obj.value) {
                  setProgrammValue(obj.value)
                }
            })
        }
    }
  }




  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    // const value = target.value;
    if(name=='mobile')
    {
      setValue(e.target.name,e.target.value.split(" ").join("") , { shouldValidate: true })
    }

  };
  const onSubmit = (data) =>{
    let mobile ;
    if(!data.cookies){
      if(phone == dialCode){
        setMobileErr(true);
        return false;
      }
      if(phone){
        mobile = phone.substring(dialCode.length);
        if(mobile.toString().length < 4 || mobile.toString().length > 13){
          setMobileErr(true);
          return false;
        }else if(dialCode ==="91" && mobile.toString().length <10){
          setMobileErr(true);
          return false;
        }else if((dialCode ==="61" || dialCode ==="65") && ((mobile.toString().length <8) || (mobile.toString().length >9) )){
          setMobileErr(true);
          return false;
        }else if((dialCode ==="971") && ((mobile.toString().length <8) || (mobile.toString().length >9) )){
          setMobileErr(true);
          return false;
        }
        else{
          setMobileErr(false);
        }
      }else{
        setMobileErr(true);
        return false;
      }
    }
    handleDisbleLoaderandSaveBtn(true);
    if(localStorage.getItem('free_lesson_url')){
      loginUser(data,mobile)
    }else{
      if(data.role == role_obj.my_self){
        loginUser(data,mobile)
       }else if(data.role == role_obj.my_team || data.role == role_obj.my_organisation){
        let {  email, name } = data
        let SaveObj = {
            "name": formatSentence(name.trim().replace(/\s+/g, ' ')),
            "first_name":formatSentence(name.trim().replace(/\s+/g, ' ')),
            "last_name":formatSentence(name.trim().replace(/\s+/g, ' ')),
            "email": email,
            "mobile": data.cookies ? data.cookies['leadform_mobile'] : mobile ,
            "learning_track_id":localStorage.getItem('learningTrackId'),
            "course_id": localStorage.getItem('free_course_id'),
            "program_id": localStorage.getItem('programId'),
            "tags": localStorage.getItem('utmtags')? localStorage.getItem('utmtags') :"",
            "url": localStorage.getItem('url')? localStorage.getItem('url'):"",
            "is_free_lesson": localStorage.getItem('free_lesson_url')? true: false,
            "is_terms_accepted":true,
            "role":data.role,
            "country_code":dialCode
        }
        axios.post(base_url+'free_lesson/user_create',SaveObj).then(res=>{
            if(res.data.status == 200){
              const now = new Date();
                const expirationDate = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000); // one year from now
                document.cookie = `leadform_id=${res.data.user_id}; expires=${expirationDate.toUTCString()}; path=/;`;
        handleDisbleLoaderandSaveBtn(false);
        setShowModal(true);
            }
          }).catch(err=>{
            handleDisbleLoaderandSaveBtn(false);
            setloadPage(true)
            setShowModal(true);
            console.log(err);
            if(err.response?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/')
              window.location.reload();
            }else if(err.message == 'Request aborted'){
              window.location.reload()
            }else if(err.response?.statusText== "Bad Request"){
              history.push("/invalidfreelessonlink", { errorMessage: err.response.data.message });;
            }
            else{
               toast.warn(api_error_message,{toastId:random_number});
            }
          })
      }
      else{
        let url = localStorage.getItem('prev_url')
        if(!url){
          window.location.href= storefront_url
        }else{
          history.push(url.split(window.location.host)[1])
        }
      }
    }
  }

  const loginUser = (data,mobile) =>{
    let {  email, name } = data
    let SaveObj = {
        "name": formatSentence(name.trim().replace(/\s+/g, ' ')),
        "first_name":formatSentence(name.trim().replace(/\s+/g, ' ')),
        "last_name":formatSentence(name.trim().replace(/\s+/g, ' ')),
        "email": email,
        "mobile": data.cookies ? data.cookies['leadform_mobile'] : mobile ,
        "learning_track_id":localStorage.getItem('learningTrackId'),
        "course_id": localStorage.getItem('free_course_id'),
        "program_id": localStorage.getItem('programId'),
        "tags": localStorage.getItem('utmtags')? localStorage.getItem('utmtags') :"",
        "url": localStorage.getItem('url')? localStorage.getItem('url'):"",
        "is_free_lesson": localStorage.getItem('free_lesson_url')? true: false,
        "is_terms_accepted":true,
        "role":data.role,
        "country_code":dialCode
    }
    axios.post(base_url+'free_lesson/user_create',SaveObj).then(res=>{
        if(res.data.status == 200){
          localStorage.setItem('free_lesson_user_id', res.data.user_id)

          const now = new Date();
            const expirationDate = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000); // one year from now
            document.cookie = `leadform_name=${name.trim()}; expires=${expirationDate.toUTCString()}; path=/;`;
            document.cookie = `leadform_email=${email?.trim()}; expires=${expirationDate.toUTCString()}; path=/;`;
            document.cookie = `leadform_mobile=${data.cookies ? data.cookies['leadform_mobile'] : mobile}; expires=${expirationDate.toUTCString()}; path=/;`;
            document.cookie = `leadform_country_code=${data.cookies ? data.cookies['leadform_country_code'] : dialCode}; expires=${expirationDate.toUTCString()}; path=/;`;
            document.cookie = `leadform_ProgramId=${SaveObj.program_id}; expires=${expirationDate.toUTCString()}; path=/;`;
            document.cookie = `leadform_role=${data.role}; expires=${expirationDate.toUTCString()}; path=/;`;
            document.cookie = `leadform_id=${res.data.user_id}; expires=${expirationDate.toUTCString()}; path=/;`;
            localStorage.setItem('info_flag',1)
            let url = localStorage.getItem('prev_url')
            if(!localStorage.getItem('apply')){
              if(!url){
                window.location.href= storefront_url
              }else{
                history.push(url.split(window.location.host)[1])
              }
            }else{
              history.push('/onlineapplication')
              // window.location.reload();
              localStorage.removeItem('apply') 
            }
        }
      
    }).catch(err=>{
        console.log(err)
        handleDisbleLoaderandSaveBtn(false);
        setloadPage(true)
        if(err.response?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        }else if(err.response?.statusText== "Bad Request"){
          history.push("/invalidfreelessonlink", { errorMessage: err.response.data.message });
         }
          else{
           toast.warn(api_error_message,{toastId:random_number});
          }
    })
  }
  useEffect(() => {
    if(!localStorage.programId){
      const CustomToast = ({ closeToast }) => (
        <div className="float-end">
            <p className="mb-0">{wrong_link_error_message}</p>
            <button className="mt-0 btn btn-primary btn-sm float-end" onClick={() => handleButtonClick(closeToast)}>Start Over</button>
        </div>
    );
      setInvalidaPage(true)
      toast.warn(<CustomToast />,{toastId:random_number,
        onClose: () => window.location.href = storefront_url
      });
    }
    const handleButtonClick=()=>{
      window.location.href = storefront_url
    }
  }, [localStorage.programId])

const handleDisbleLoaderandSaveBtn=(value)=>{
  setDisableSubmit(value)
  SetLoading_flag(value)
}
  
  const getChecked=(e)=>{
    setCheckBox(e.target.checked);
    setValue(e.target.name,e.target.checked,{ shouldValidate: true })
  }

  return (
    <>
      {loading_flag &&<>
        <div>
            <img className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
        </div>
        </>
      }
      {!invalidaPage ? <>{showModal && (
        <Modal show={showModal} centered ClassName="role_modal" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
          <Modal.Body>
            <div className="text-center">
            <img src="images/tickmark_image.svg" />
            <h4 className="blue_text" style={{fontSize:"38px"}}>Request Received</h4>
            <p className="modal_text">Thank you for your interest in ISB Online. Someone from <br></br> our team will contact you to discuss your team/<br></br>organisation’s learning goals.</p>
            </div>
            <div className="text-center pb-3">
            <button className="btn my_btn_cls submit_button m-0 px-4" onClick={goToStoreFront}>Go to ISB Online Home</button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {loadPage && (
      <div>
        {
          <div style={{ width: "99%" }}>
            <div className="row justify-content-center mt-5">
              <div className="col-md-6 col-lg-4">
                <div className="isblogin-wrap p-4 p-md-5">
                  <div className="my_form_heading mb-2">
                    <span className="loginPageHeading">Get Started on ISB Online</span>
                  </div>
                 <form >
                 <div >
                <label htmlFor="exampleInput" className="leadForm_Label">Name<span className="requiredInput">*</span></label>
                <input {...register("name", freelessonloginValidations.name)}
                  type="text"
                  className="form-control leadForm_input"
                  id="name"
                  name="name"
                  data-testid="name"
                  placeholder=" "
                  autoFocus
                  required
                  role="presentation" autocomplete="off"
                  onChange={handleChange}
                />
                {errors.name?.message && <span role="alert" style={{ color: "red" }}>{errors.name?.message}</span>}
              </div>
            

              {/* <div className="row  form_field_cls"> */}
              <div >
                <label htmlFor="exampleInput" className="leadForm_Label">Email<span className="requiredInput">*</span></label>
                <input {...register("email", freelessonloginValidations.email)}
                  type="email"
                  id="email"
                  name="email"
                  data-testid="email"
                  onChange={handleChange}
                  className="form-control leadForm_input"
                  placeholder=""
                  role="presentation" autocomplete="off"
                />


                {errors.email?.message && (
                  <span role="alert" style={{ color: "red" }}>{errors.email?.message}</span>
                )}
                {/* {errors.email && <span style={{ color: "red" }}>This field is required</span>} */}

              </div>
              
              <div className="phone-input-container mb-2">
                <label htmlFor="exampleInput" className="leadForm_Label">Mobile<span className="requiredInput">*</span></label>
                <PhoneInput
                  country={countryCode}
                  value={phone}
                  onChange={handleOnChange}
                  separateDialCode={true} // Display flag and code in one block
                />
                <span className={`flag-icon flag-icon-${countryCode}`}></span>
                {mobileErr && <span role="alert" style={{ color: "red" }}>Please enter a valid mobile number</span>}
              </div>

       
              {/* <div >
                <label htmlFor="exampleInputMobile" className="leadForm_Label">Mobile<span className="requiredInput">*</span></label>
                <input {...register("mobile", {
                    required: "Mobile number is required", 
                    minLength: {
                    value: 10,
                    message: 'Mobile number must contain between 10 and 13 characters',
                  },
                  maxLength: {
                    value: 13,
                    message: 'Mobile number must contain between 10 and 13 characters',
                  },
                  pattern: {
                    value: /^[+-]?[0-9]+$/,
                    message: 'Please enter valid mobile number'
                  }
                })}
                  type="text"
                  id="mobile"
                  name="mobile"
                  onChange={handleChange}
                  className="form-control leadForm_input"
                  role="presentation" autocomplete="off"
                />
                {errors.mobile?.message && <span style={{ color: "red" }}>{errors.mobile?.message}</span>}

              </div> */}
              <div>
                <label htmlFor="exampleInput" className="leadForm_Label">I am interested in programmes for<span className="requiredInput">*</span></label>
                <select {...register("role", freelessonloginValidations.role)}
                    id="role"
                    name="role"
                    data-testid="role"
                    className="form-select leadForm_input rounded-1" 
                    value={programValue}
                    onChange={handleChangeDropdown}
                    role="presentation" autocomplete="off" >
                    <option value='' style={{display:'none',fontWeight:'800'}}>Select</option>
                    {ProgrammDetails.map((item, index) => {
                        return <option key={index} value={item._id} >{item.value}</option>;
                    })}
                </select>
                {errors.role?.message && (
                  <span role="alert" style={{ color: "red" }}>{errors.role?.message}</span>
                  )}
              </div> 
              <div className="pl-1 lead_form_submitMeg" id="LeadFormCheckBox">
                <div className="form-group_checkbox">
                          <input type="checkbox"  data-testid="checkBox" className="form-control" name="checkBox" id="checkBox" checked={checkBox}  {...register("checkBox",)} onChange={e => getChecked(e)} />
                          <label className="row" for="checkBox">
                            <div className="col leadform_Checkbox mt-2"> By submitting this form, you agree to receive communications via email/call/whatsapp from ISB about this programme and their relevant programmes.</div>
                          </label>
                          {errors.checkBox?.message && <span role="alert" style={{ color: "red" }}>{errors.checkBox?.message}</span>}
                          
                </div>
              </div>
              <div className="d-flex">
                <button  disabled={disableSubmit} data-testid="submit-button" className="btn btn-primary my_btn_cls my_submit_btn" onClick={handleSubmit(onSubmit)}>Continue</button>
              </div>
                 </form>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      )}</> : 
      ""}
    </>
  );

}

export default withMaintenanceCheck(FreeLessonLogin,user_maintenance_type) 