import React from 'react';
import useCollapse from 'react-collapsed';
import { useHistory, useLocation } from "react-router-dom";
import axios from "../axios-interceptor"
import { base_url,date_formats,api_error_message,random_number,enrollment_management_role, admin_maintenance_type } from "../utilities";
import { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Container } from 'react-bootstrap';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from '../HigherOrderComponents/maintenancePageChecker';

const AdminApprovalModules=(props)=> {

  const history = useHistory()
  let [learningTrackProgramsList,setLearningTrackProgramsList]=useState([])
  const [LearningTrack, setModules] = useState([]);
  var [coursedata, setCourseData] = useState([]);
  let [loading_flag,Setloading_flag]=useState(true)
 
  const handle = () =>{}
  if(!localStorage.getItem('Admin_Acesstoken'))
  {
    history.push('/adminlogin')
  }
// useEffect(()=>{
//      axios.get(`./json/AdminModules.json`)

//               .then((res) => {
//                // console.log([res.data.course[0].modules[0]],"in 22 line")
//                 setModules([res.data.course[0].modules[0]])
          
//         });
       
//   },[])
  useEffect(()=>{
    axios.post(base_url+'get_all_learning_tracks_programms')
    .then(res=>{
      let tempArr=[]
        res?.data?.result.map(obj=>{
          if(obj.programs.length>0)
          {
            tempArr.push(obj)
          }
        })
        setLearningTrackProgramsList(tempArr)
        Setloading_flag(false)
    })
    .catch(err=>{
      console.log(err);
      if(err.response?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/adminlogin')
        window.location.reload();
      }else if(err.message == 'Request aborted'){
        window.location.reload()
      }else{
        toast.warn(api_error_message,{toastId:random_number});
      }
    })


    // axios
    //   .post(base_url + "courses/get_all_courses",)
    //   .then((res) => {
    //     // console.log(res.data.courses)
    //     setCourseData(res.data.courses);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },[])

  const setTitleData = (learning_track_id,programId) => {
    if(localStorage.getItem('Admin_role') == enrollment_management_role){
      history.push('/enrollmentmanagementdashboard/adminapprovals',{learning_track_id:learning_track_id,programId:programId})
    }else{
      history.push('/admindashboard/adminapprovals',{learning_track_id:learning_track_id,programId:programId})
     }
  
  }

  return (
    <>
    <Container className='bg-transparent pt-0'>
         <img data-testid="Hero_Image" src="/images/admissionapproval.png" alt=""  />
         </Container>
      <div className="container bg-transparent">

      {loading_flag?
      <>
      <div>
        <img  className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
      </div>
      </>:
      <>
      {learningTrackProgramsList.map((item, index) =>{
    return(
        <div key={item.id} className='mt-3' >
        <div className="Module0  ps-4 h3 custm_shadow_cls"  >
          <div className="">
            <h3> <ul data-testid={`Learning_Track_${index}`} >{item.learning_track_name}</ul></h3>
            {/* <h4><ul>{item.no_of_stds_applied}</ul></h4> */}
            </div></div>
            {item.programs.map((itemtwo,ind) => {
                  return (
             
                      <div key={itemtwo.id} className="position-relative" >
                      
                        <div data-testid={`Programme_Click_${index}`} className="module_navs custm_shadow_cls " style={{cursor:"pointer"}} onClick={()=>setTitleData(itemtwo.learning_track_id,itemtwo._id)}>
                        <FullScreen className="" 
                        handle={handle}
                        >
                        
                          <h5 className='d-flex justify-content-between p-3'>
                            <div data-testid= {`Track_Name_Date_${index}`} className="im col-lg-7 col-sm-12 text-black text-wrap me-2 " >
                             
                             {itemtwo.learning_track_name} - {date_formats.human_date_format(itemtwo.start_date)}
                             {/* <div class="py-2"><i>Program Display Name</i> - {itemtwo.program_display_name}</div> */}
                            
                             {/* {itemtwo.product_id.length > 60 ? itemtwo.learning_track_name.slice(0, 60)+ '..' : itemtwo.title} */}
                            </div>
                            
                          
                            <div data-testid= {`Check_Applications_${index}`} className="im  lesson_status" >
                              <span>Check Applications</span>
                            </div>
                          </h5>
                          
</FullScreen>
                        </div>

                      </div>
                      
                      )
                })} 
            
      </div>  
    )
})
}
      </>}
      

                    
         
        </div>
    </>

  );
};
export default withMaintenanceCheck(AdminApprovalModules,admin_maintenance_type) 