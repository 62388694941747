import React, { useEffect, useState } from "react";
import { base_url, api_error_message, random_number } from "../../utilities";
import { useForm, Controller  } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "../../axios-interceptor";
import { useHistory,useLocation } from "react-router-dom";
import Select from 'react-select';

import { createAttributionsValidations } from "../../formValidationsRules";
const CreateAttributions = () => {
    const { register, handleSubmit, setValue, getValues,reset, control,formState: { errors } } = useForm({ "mode": "all", defaultValues: { isshow: true }  });
    const location = useLocation();
    const history = useHistory();
    const [courses, setCourses] = useState([]);
    const [modules, setModules] = useState([]);
    const [attributionData, setAttributionData] = useState("");
    const [loader, setLoader] = useState(true);
    const [attributionType, setAttributionType] = useState("Book");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [fullName, setFullName] = useState("");
    const [articleName, setArticleName] = useState("");
    const [publication, setPublication] = useState("");
    const [issue, setIssue] = useState("");
    const [edition, setEdition] = useState("");
    const [page, setPage] = useState("");
    const [dob, setDob] = useState("");
    const [url, setUrl] = useState("");
    const [uploaded, setUploaded] = useState("");
    const [accessedDate, setAccessedDate] = useState("");
    const [addtionalReadings, setAddtionalReadings] = useState(false);
    const [is_sla_attribution, setSlaAttribution] = useState(false);
    const [uploader, setUploader] = useState("");
    const [journalTitle, setJournalTitle] = useState("");
    const [volume, setVolume] = useState("");
    const [website, setWebsite] = useState("");
    const [newsPaperName, setNewsPaperName] = useState("");
    const [dictionaryName, setDictionaryName] = useState("");
    const [editors, setEditors] = useState("");
    const [courseId, setCourseId] = useState("");
    const [moduleId, setModuleId] = useState("");
    const [isShow, setIsShow] = useState(true);
    const [eidtMode, setEditMode] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    let [selectedCourse,setSelectedCourse]= useState([]);
    let [selectedModule,setSelectedModule]= useState([]);
    let edit_details;
    const attribution_links = ["Book", "Journel article", "Online video", "Webpage", "Newspaper article", "Dictionary entry", "ISB Attribution"];
    const attribution_id = location?.state?.id;
    useEffect(() => {
        axios.post(base_url + "user/get_courses_with_programme", {}).then((res) => {
            setCourses(res.data.courses);
            setLoader(false)
        }).catch(err => {
            console.log(err);
            if (err.response?.statusText == 'Unauthorized'|| err.response?.data?.statusText == 'Unauthorized') {
                localStorage.clear();
                history.push('/adminlogin')
                window.location.reload();
            } else if(err.message == 'Request aborted'){
                window.location.reload()
              }else {
                toast.warn(api_error_message, { toastId: random_number });
            }
        })
        if (attribution_id) {
            setEditMode(true)
            setLoader(true)
            axios.post(base_url + "attributions/get_attributions_data", {
                "attribution_id": attribution_id
            }).then((res) => {
                edit_details = res.data.data[0];
                getModules(edit_details.course_id._id)
            }).catch(err => {
                console.log(err);
                if (err.response?.statusText == 'Unauthorized' || err.response?.data?.statusText == 'Unauthorized') {
                    localStorage.clear();
                    history.push('/adminlogin')
                    window.location.reload();
                } else if(err.message == 'Request aborted'){
                    window.location.reload()
                  }else {
                    toast.warn(api_error_message, { toastId: random_number });
                }
            })
        }
    }, []);
    const getModules = (course_id) => {
        setModuleId("")
        if (course_id) {
            setCourseId(course_id)
            // reset({
            //     module: ''
            // })
            setValue('module','', { shouldDirty: true })
            axios.post(base_url + "get_course_modules", { "course_id": course_id }).then((res) => {
                setModules(res.data.data);
                if (attribution_id && edit_details) {
                    setValue('attribution_type', edit_details.attribution_type)
                    setAttributionType(edit_details.attribution_type)
                    setValue('first_name', edit_details.author_first_name)
                    setFirstName(edit_details.author_first_name)
                    setValue('last_name', edit_details.author_last_name)
                    setLastName(edit_details.author_last_name)
                    setValue('full_name', edit_details.other_authors)
                    setFullName(edit_details.other_authors)
                    setValue('article_name', edit_details.article_name)
                    setArticleName(edit_details.article_name)
                    setValue('publication', edit_details.publication_name)
                    setPublication(edit_details.publication_name)
                    setValue('edition', edit_details.edition)
                    setEdition(edit_details.edition)
                    setValue('page', edit_details.page)
                    setPage(edit_details.page)
                    setValue('additionalread', edit_details.is_additional_reading)
                    setValue('slaattribution', edit_details.is_sla_attribution)
                    setSlaAttribution(edit_details.is_sla_attribution)
                    setAddtionalReadings(edit_details.is_additional_reading)
                    setValue('isshow', edit_details.is_show)
                    setIsShow(edit_details.is_show)
                    setValue('url', edit_details.url)
                    setUrl(edit_details.url)
                    setValue('uploaded', edit_details.uploaded)
                    setUploaded(edit_details.uploaded)
                    setValue('accessed_date', edit_details.access_date)
                    setAccessedDate(edit_details.access_date)
                    setValue('dob', edit_details.year)
                    setDob(edit_details.year)
                    setSelectedCourse({value:edit_details.course_id._id,label:edit_details.course_id.course_title})
                    setValue('course', {value:edit_details.course_id._id,label:edit_details.course_id.course_title},{shouldDirty:true})
                    setCourseId(edit_details.course_id._id)
                    setSelectedModule({value:edit_details.module_id._id,label:edit_details.module_id.module_title},{shouldDirty:true})
                    setValue('module', {value:edit_details.module_id._id,label:edit_details.module_id.module_title},{shouldDirty:true})
                    setModuleId(edit_details.module_id._id)
                    setValue('website', edit_details.website)
                    setWebsite(edit_details.website)
                    setValue('journal', edit_details.journal_title)
                    setJournalTitle(edit_details.journal_title)
                    setValue('volume', edit_details.volume)
                    setVolume(edit_details.volume)
                    setValue('issue', edit_details.issue)
                    setIssue(edit_details.issue)
                    setValue('uploader', edit_details.uploader)
                    setUploader(edit_details.uploader)
                    setValue('news_paper_name', edit_details.news_paper_name)
                    setNewsPaperName(edit_details.news_paper_name)
                    setValue('dictionary_name', edit_details.dictionary_name)
                    setDictionaryName(edit_details.dictionary_name)
                    setValue('editors', edit_details.editors)
                    setEditors(edit_details.editors)
                }
            }).catch(err => {
                console.log(err);
                if (err.response?.statusText == 'Unauthorized' || err.response?.data?.statusText == 'Unauthorized') {
                    localStorage.clear();
                    history.push('/adminlogin')
                    window.location.reload();
                }else if(err.message == 'Request aborted'){
                    window.location.reload()
                  } else {
                    toast.warn(api_error_message, { toastId: random_number });
                }
            })
        } else {
            setCourseId("")
        }
    }
    const handleChangeInput = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        if (name == 'attribution_type') {
            setAttributionType(value)
        }
        if (name == 'first_name') {
            setFirstName(value)
        }
        if (name == 'last_name') {
            setLastName(value)
        }
        if (name == 'full_name') {
            setFullName(value)
        }
        if (name == 'article_name') {
            setArticleName(value)
        }
        if (name == 'publication') {
            setPublication(value)
        }
        if (name == 'issue') {
            setIssue(value)
        }
        if (name == 'edition') {
            setEdition(value)
        }
        if (name == 'page') {
            setPage(value)
        }
        if (name == 'dob') {
            setDob(value)
        }
        if (name == 'url') {
            setUrl(value)
        }
        if (name == 'uploaded') {
            setUploaded(value)
        }
        if (name == 'accessed_date') {
            setAccessedDate(value)
        }
        if (name == 'additionalread') {
            setAddtionalReadings(e.target.checked)
        }
        if (name == 'isshow') {
            setIsShow(e.target.checked)
        }
        if (name == 'slaattribution') {
            setSlaAttribution(e.target.checked)
        }
        if (name == 'uploader') {
            setUploader(value)
        }
        if (name == 'journal') {
            setJournalTitle(value)
        }
        if (name == 'volume') {
            setVolume(value)
        }
        if (name == 'website') {
            setWebsite(value)
        }
        if (name == 'news_paper_name') {
            setNewsPaperName(value)
        }
        if (name == 'dictionary_name') {
            setDictionaryName(value)
        }
        if (name == 'editors') {
            setEditors(value)
        }
        if (name == 'course') {
            setCourseId(value)
        }
        if (name == 'module') {
            setModuleId(value)
        }

    }
    const createAttribution = (event) => {
        let api_url = '';
        if (attribution_id) {
            api_url = base_url + 'attributions/update_attributions';
        } else {
            api_url = base_url + 'attributions/create_attributions';
        }
        let formData = new FormData();
        if (attribution_id) {
            formData.append('attribution_id', attribution_id)
        }
        formData.append('attribution_type', attributionType)
        formData.append('author_first_name', firstName)
        formData.append('author_last_name', lastName)
        formData.append('other_authors', fullName)
        formData.append('article_name', articleName)
        formData.append('publication_name', publication)
        formData.append('issue', issue)
        formData.append('edition', edition)
        formData.append('uploaded', uploaded)
        formData.append('access_date', accessedDate)
        formData.append('page', page)
        formData.append('year', dob)
        formData.append('url', url)
        formData.append('journal_title', journalTitle)
        formData.append('volume', volume)
        formData.append('website', website)
        formData.append('uploader', uploader)
        formData.append('news_paper_name', newsPaperName)
        formData.append('dictionary_name', dictionaryName)
        formData.append('editors', editors)
        formData.append('is_additional_reading', addtionalReadings)
        formData.append('is_sla_attribution', is_sla_attribution)
        formData.append('is_show', isShow)
        formData.append('course_id', courseId)
        formData.append('module_id', moduleId)
        axios.post(api_url, formData)
            .then((response) => {
                toast.success('Attribution Created Successfully!')
                history.push('/admindashboard/attributions')
            }).catch(err => {
                console.log(err);
                if (err.response?.statusText == 'Unauthorized') {
                    localStorage.clear();
                    history.push('/adminlogin')
                    window.location.reload();
                }else if(err.message == 'Request aborted'){
                    window.location.reload()
                  } else {
                    toast.warn(api_error_message, { toastId: random_number });
                }
            })

    }
    
    const handleSelectChange = (e) => {
        setValue("attribution_type", e);
        setAttributionType(e.value); // Update attributionType directly
    };
    const handleSelectCourse=(selectedOption) => {
        const selectedCourseId = selectedOption ? selectedOption.value : "";
        setValue('course', selectedOption, { shouldDirty: true });
        setSelectedCourse(selectedOption)
        setCourseId(selectedCourseId); // Update the courseId state
        getModules(selectedCourseId); // Call getModules with the selected course ID
      }
    const handleSelectModule=(e) => {
        setValue("module", e);
        setSelectedModule(e)
        setModuleId(e.value); // Update attributionType directly
    }
    const deleteAttribution = () => {
        let url = base_url + 'attributions/delete_attributions';
        let result = window.confirm('Are you want to remove this Attribution')
        if(result){
            axios.post(url, { "attribution_id": attribution_id })
                .then((response) => {
                    toast.success('Attribution Deleted Successfully!')
                    history.push('/admindashboard/attributions')
                }).catch(err => {
                    console.log(err);
                    if (err.response?.statusText == 'Unauthorized' || err.response?.data?.statusText=="Unauthorized") {
                        localStorage.clear();
                        history.push('/adminlogin')
                        window.location.reload();
                    }else if(err.message == 'Request aborted'){
                        window.location.reload()
                      } else {
                        toast.warn(api_error_message, { toastId: random_number });
                    }
                })
        }
    }
  
    
    return (
        <>
            {!loader ?
                <div>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn my_btn_cls submit_button m-0 px-4 fs-18" onClick={() => history.push("/admindashboard/attributions")}> <i class="fa fa-arrow-left me-2 fw-bolder payment_icon" aria-hidden="true" data-testid="Back_Button"></i>Back to Attributions</button>
                        </div>
                        {attribution_id ? <h5 data-testid="Remove_Button" className="text-decoration-underline" onClick={deleteAttribution} style={{ cursor: "pointer" }}>Remove Attribution</h5> : ""}
                    </div>
                    <form style={{ display: "flex" }}>
                        <div className="row mt-5">
                            <div className="col-7">
                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label data-testid="Type_Label" htmlFor="inputState" className="form-label fw-bold">
                                            Type
                                        </label>
                                    </div>
                                    <div className="col-9"  data-testid="Type_Select">  
                                    <Controller
                                       
                                        name="attribution_type"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                            {...field}
                                            options={attribution_links.map((app) => ({
                                                value: app,
                                                label: app,
                                            }))}
                                            placeholder="Select"
                                            isSearchable
                                            onChange={handleSelectChange}
                                            value={{ value: attributionType, label: attributionType }} 
                                            classNamePrefix="react-select-Programme"
                                            />
                                        )}
                                    />
                                        {errors.attribution_type?.message && <span data-testid="Type_Select_Error" className="form-label-color">{errors.attribution_type?.message}</span>}
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label data-testid="Last_Name_Label" htmlFor="inputLastName" className="form-label">
                                            Author Last Name
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            data-testid="Last_Name_Input"
                                            name="last_name"
                                            {...register("last_name", {
                                            })}
                                            onChange={handleChangeInput} type="text" className="form-control" id="inputLastName" />
                                        {errors.last_name?.message && <span className="form-label-color">{errors.last_name?.message}</span>}
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label htmlFor="inputFirstName" className="form-label" data-testid="First_Name_Label" >
                                            Author First Name
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            data-testid='First_Name_Input'
                                            name="first_name"
                                            {...register("first_name", {
                                            })}
                                            onChange={handleChangeInput} type="text" className="form-control" id="inputFirstName" />
                                        {errors.first_name?.message && <span className="form-label-color">{errors.first_name?.message}</span>}
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label htmlFor="otherauthors" className="form-label">
                                            Other Authors (Full Names)
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                        data-testid="Full_name"
                                            name="full_name"
                                            {...register("full_name", {
                                            })}
                                            onChange={handleChangeInput} type="text" className="form-control" id="otherauthors" />
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label htmlFor="articlename" className="form-label">
                                            Article Name
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <input name="article_name"
                                        data-testid="Article_Name"
                                            {...register("article_name", {
                                            })}
                                            onChange={handleChangeInput} type="text" className="form-control" id="articlename" />
                                        {errors.article_name?.message && <span className="form-label-color">{errors.article_name?.message}</span>}
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label htmlFor="publication" className="form-label">
                                            Book/Publication
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <input name="publication"
                                        data-testid="Publication"
                                            {...register("publication", {
                                            })}
                                            onChange={handleChangeInput} type="text" className="form-control" id="publication" />
                                        {errors.publication?.message && <span className="form-label-color">{errors.publication?.message}</span>}
                                    </div>
                                </div>


                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label htmlFor="Issue" className="form-label">
                                            Issue
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <input name="issue"
                                        data-testid="Issue"
                                            {...register("issue", {

                                            })}
                                            onChange={handleChangeInput} type="text" className="form-control" id="year_publication" />
                                    </div>
                                </div>



                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label htmlFor="edition" className="form-label">
                                            Edition
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <input name="edition"
                                        data-testid="Edition"
                                            {...register("edition", {
                                            })}
                                            onChange={handleChangeInput} type="text" className="form-control" id="edition" />
                                        {errors.edition?.message && <span className="form-label-color">{errors.edition?.message}</span>}
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label htmlFor="Page" className="form-label">
                                            Page
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <input name="page"
                                        data-testid="Page"
                                            {...register("page", {

                                            })}
                                            onChange={handleChangeInput} type="text" className="form-control" id="Page" />
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label htmlFor="date_month" className="form-label">
                                            Date/Month/Year
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <input name="dob"
                                        data-testid="DOB"
                                            {...register("dob", {

                                            })}
                                            onChange={handleChangeInput} type="text" className="form-control" id="date_month" />
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label htmlFor="url" className="form-label">
                                            URL
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <input name="url"
                                        data-testid="Url"
                                            {...register("url", {
                                            })}
                                            onChange={handleChangeInput} type="text" className="form-control" id="url" />
                                        {errors.url?.message && <span className="form-label-color">{errors.url?.message}</span>}
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label htmlFor="upload" className="form-label">
                                            Uploaded
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <input name="uploaded"
                                        data-testid="Uploaded"
                                            {...register("uploaded", {
                                            })}
                                            onChange={handleChangeInput} type="text" className="form-control" id="upload" />
                                        {errors.uploaded?.message && <span className="form-label-color">{errors.uploaded?.message}</span>}
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label htmlFor="accessdate" className="form-label">
                                            Accessed Date
                                        </label>
                                    </div>
                                    <div className="col-9">
                                        <input name="accessed_date"
                                        data-testid="Accessed_Date"
                                            {...register("accessed_date", {

                                            })}
                                            onChange={handleChangeInput} type="text" className="form-control" id="accessdate" />
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label htmlFor="additionalread" className="form-label">
                                            Additional Reading?
                                        </label>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-check">
                                            <input name="additionalread"
                                            data-testid="Additional-Read"
                                                {...register("additionalread", {

                                                })}
                                                onChange={handleChangeInput}
                                                type="checkbox"
                                                className="form-check-input additionalread"
                                                id="additionalread"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="isshow" className="form-label">
                                            Is Show
                                        </label>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-check">
                                            <input name="isshow"
                                            data-testid="Is-show"
                                                {...register("isshow")}
                                                onChange={handleChangeInput}
                                                type="checkbox"
                                                className="form-check-input additionalread"
                                                id="isshow"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-3">
                                        <label htmlFor="slaattribution" className="form-label">
                                            Is Sla Attribution ?
                                        </label>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-check">
                                            <input name="slaattribution"
                                            data-testid="SLA-attribution"
                                                {...register("slaattribution", {

                                                })}
                                                onChange={handleChangeInput}
                                                type="checkbox"
                                                className="form-check-input slaattribution"
                                                id="slaattribution"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5 d-flex flex-column justify-content-between">
                                <div>

                                    <div className="row mb-4">
                                        <div className="col-3">
                                            <label htmlFor="uploader" className="form-label ">
                                                Uploader
                                            </label>
                                        </div>
                                        <div className="col-9">
                                            <input name="uploader"
                                            data-testid="Uploader"
                                                {...register("uploader", {

                                                })}
                                                onChange={handleChangeInput} type="text" className="form-control" id="initial" />
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-3">
                                            <label htmlFor="journal" className="form-label ">
                                                Title of Journal
                                            </label>
                                        </div>
                                        <div className="col-9">
                                            <input name="journal"
                                            data-testid="Journal"
                                                {...register("journal", {

                                                })}
                                                onChange={handleChangeInput} type="text" className="form-control" id="journal" />
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-3">
                                            <label htmlFor="volume" className="form-label ">
                                                Volume
                                            </label>
                                        </div>
                                        <div className="col-9">
                                            <input name="volume"
                                            data-testid="Volume"
                                                {...register("volume", {

                                                })}
                                                onChange={handleChangeInput} type="text" className="form-control" id="volume" />
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-3">
                                            <label htmlFor="website" className="form-label ">
                                                Website
                                            </label>
                                        </div>
                                        <div className="col-9">
                                            <input name="website"
                                            data-testid="Website"
                                                {...register("website", {

                                                })}
                                                onChange={handleChangeInput} type="text" className="form-control" id="volume" />
                                        </div>
                                    </div>
                                    
                                    <div className="row mb-4">
                                        <div className="col-3">
                                            <label htmlFor="newspaper" className="form-label">
                                                News Paper Name
                                            </label>
                                        </div>
                                        <div className="col-9">
                                            <input name="news_paper_name"
                                            data-testid="News_paper_name"
                                                {...register("news_paper_name", {

                                                })}
                                                onChange={handleChangeInput} type="text" className="form-control" id="volume" />
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-3">
                                            <label htmlFor="dictionary_name" className="form-label">
                                                Dictionary Name
                                            </label>
                                        </div>
                                        <div className="col-9">
                                            <input name="dictionary_name"
                                            data-testid="Dictionary_name"
                                                {...register("dictionary_name", {

                                                })}
                                                onChange={handleChangeInput} type="text" className="form-control" id="volume" />
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-3">
                                            <label htmlFor="editors" className="form-label">
                                                Editors
                                            </label>
                                        </div>
                                        <div className="col-9">
                                            <input name="editors"
                                            data-testid="Editors"
                                                {...register("editors", {

                                                })}
                                                onChange={handleChangeInput} type="text" className="form-control" id="volume" />
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-3">
                                            <label htmlFor="preview" className="form-label fw-bold">
                                                Preview
                                            </label>
                                        </div>
                                        <div className="col-9 text-break">
                                            {(((lastName  + ',' + articleName + ',' + publication + ',' + dob + ',' + url).replace(/,+/g,',')).replace(/^,|,$/g, '')).replace(/,+/g,', ')}
                                        </div>
                                    </div>                                    

                                    <div className="row mb-4">
                                        <div className="col-3">
                                            <label htmlFor="link" className="form-label fw-bold">
                                                Link*
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-3">
                                            <label htmlFor="course" className="form-label" >
                                                Course <span className="requiredInput">*</span>
                                            </label>
                                        </div>
                                        <div className="col-9" >
                                            <Controller
                                            name="course"
                                            
                                            control={control}
                                            options={courses.map((app) => ({
                                                value: app._id,
                                                label: `${app.course_title}-${app.program_name}`,
                                            }))}
                                            rules={{ required: "Course is required" }}
                                            render={({ field }) => (
                                                <Select
                                                data-testid="Courses_ids"
                                                className="course_dropdown"
                                                {...field}
                                                options={courses.map((app) => ({
                                                    value: app._id,
                                                    label: `${app.course_title}-${app.program_name}`,
                                                }))}
                                                placeholder="Select Course"
                                                isSearchable
                                                name="course"
                                                onChange={handleSelectCourse}
                                                value={selectedCourse} // Set the value based on courseId
                                                classNamePrefix="react-select-Programme"
                                                />
                                            )}
                                            />

                                            {errors.course?.message && <span className="form-label-color">{errors.course?.message}</span>}
                                        </div>
                                    </div>

                                    <div className="row mb-4">
                                        <div className="col-3">
                                            <label htmlFor="module" className="form-label">
                                                Module <span className="requiredInput">*</span>
                                            </label>
                                        </div>
                                        <div className="col-9">
                                            <Controller
                                            name="module"
                                            control={control}
                                            rules={{ required: "Module is required" }}
                                            render={({ field }) => (
                                                <Select
                                                {...field}
                                                options={modules.map((app) => ({
                                                    value: app._id,
                                                    label: app.module_title,
                                                }))}
                                                placeholder="Select Module"
                                                isSearchable
                                                onChange={handleSelectModule}
                                                value={selectedModule} // Set the value based on courseId
                                                classNamePrefix="react-select-Programme"

                                                />
                                            )}
                                            />
                                            {errors.module?.message && <span className="form-label-color">{errors.module?.message}</span>}
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-3">&nbsp;</div>
                                        <div className="col-9">
                                            <div className="align-self-end">
                                                {
                                                    !eidtMode ?
                                                        <button type="button" data-testid="CreateAttri" onClick={handleSubmit(createAttribution)} className="btn my_btn_cls submit_button m-0 px-4 fs-18">Save</button>
                                                        :
                                                        <button type="button" data-testid="UpdateAttri" onClick={handleSubmit(createAttribution)} className="btn my_btn_cls submit_button m-0 px-4 fs-18">Update</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-1">&nbsp;&nbsp;</div>
                        </div>
                    </form>
                </div>
                :
                <div>
                    <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
                </div>
            }
        </>
    );
};

export default CreateAttributions;