import { useHistory, useLocation } from "react-router-dom";
import { base_url , random_number,api_error_message, admin_maintenance_type } from "../../utilities";
import {coursesListValidations} from "../../formValidationsRules"
import { useForm } from "react-hook-form";
import axios from "../../axios-interceptor";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { Fragment } from "react";
const CourseLearningTrackList = (props) => {
  const history = useHistory();
  const { register,handleSubmit, formState: { errors } } = useForm({ "mode": "all" });
  let [modules, setModules] = useState([]);
  let [mappedCourse, setCourse] = useState([]);
  let [course_id, setCourseId] = useState();
  let [loading_flag, setLoading_flag] = useState(false);
  let [table_loading_flag,setTable_loading_flag]=useState(true)
  const [show, setShow] = useState(false);
  let [update_flag, setUpdateFlag] = useState(false);
  let [cohorts, setCohorts] = useState([]);
  let [cohort_ids,setCohortIds] = useState([])
  let [file, setFile] = useState()
  let [is_public,setIsPublic] =useState()
  let [course_image, setCourseUrl] = useState(null)
  let [course_takeaway, setCourseTakeAway] = useState(null)
  let [course_description, setCourseDescription] = useState(null)
  let [course_name,setCourseName] = useState('')
  let [track_name,setTrackName] = useState('')
  if (!localStorage.getItem("Admin_Acesstoken")) {
    history.push("/adminlogin");
  }
  useEffect(() => {
    getMappedCourses();
  }, []);
  const getMappedCourses = () => {
    axios
      .post(base_url + "courses/get_all_courses_without_schedules", {})
      .then((res) => {
        let courses = res.data.result;
        courses.map((item, index) => {
          if (item.cohort_schedules.length) {
            courses[index]["course_id"] = item.cohort_schedules[0].course_id;
          }
        });
        let final_course = courses.filter((item, index) => {
          if (item.cohort_schedules.length > 0) {
            return item.cohort_schedules[0];
          }
        });
        setCourse(final_course);
        setTable_loading_flag(false)
      })
      .catch((err) => {
        if(err.response?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
        console.log(err);
      });
  };

  const handleClose = () => setShow(false);
  const deleteCourse = (id) => {
    setCourseId(id);
    setShow(true);
  };
  const deleteCurrentCourse = () => {
    setShow(false);
    axios
      .post(base_url + "courses/delete_course", { course_id: course_id })
      .then((res) => {
        toast.success("Successfully Deleted!..",{id:random_number});
        getMappedCourses();
      })
      .catch((err) => {
        if(err.response?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        }else{
          toast.warn(api_error_message,{id:random_number});
        }
      });
  };
  const updateTheCourse = (course_id,track_name) => {
    setCourseId(course_id)
    setTrackName(track_name)
    setUpdateFlag(true);
    axios
      .post(base_url + "cohort/get_started_cohort_schedules_by_course", {
        course_id: course_id,
      })
      .then((res) => {
        setCohorts(res.data.result);
      })
      .catch((err) => {
        if(err.response?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        }else{
          toast.warn(api_error_message,{id:random_number});
        }
      });
  };
  const backtoAdmin = () => {
    setUpdateFlag(false);
    setCohortIds([])
    history.push("/admindashboard/listcourses");
  };
  const getChecked = (e,status) => {
    setIsPublic(status)
    if (e.target.checked) {
      setCohortIds((current)=>[...current,e.target.id])
    } else {
      setCohortIds((current)=>current.filter(function (item) {
        return item !== e.target.id
      }))
    }
  
  }
  const handleChangeFileUpload = (event) => {
    setFile(event.target.files[0])
}
const handleChangeInput = (e) => {
  const target = e.target;
  const value = target.value;
  const name = target.name;

if(name=='image_url'){
  setCourseUrl(value)
}
if(name=='course_takeaway'){
  setCourseTakeAway(value)
}
if(name=='description'){
  setCourseDescription(value)
}
if(name == 'course_name'){
  setCourseName(value)
}


}
const updateCourse = () =>{
  let formData = new FormData();
  formData.append('file', file);
  formData.append('course_id', course_id );
  formData.append('is_public',is_public);
  formData.append('cohort_schedule_ids[]',cohort_ids)
  formData.append('courseimage',course_image);
  formData.append('course_takeaway',course_takeaway);
  formData.append('course_title', course_name);
  formData.append('description', course_description);
  setLoading_flag(true);
  axios.post(base_url+"files/update_course",formData).then(res=>{
    if(res.data.status == 200){
      setLoading_flag(false)
      // alert("Successfully updated...");
      // setUpdateFlag(false)
      localStorage.setItem("admin_course", res.data.result.courses[0])
      history.push('/admindashboard/courseview')
      // window.location.reload();
    }
  }).catch(err=>{
    setLoading_flag(false)
    setUpdateFlag(false)
    console.log(err);
    if(err.response?.statusText=='Unauthorized'){
      localStorage.clear();
      history.push('/adminlogin')
      window.location.reload();
    }else if(err.message == 'Request aborted'){
      window.location.reload()
    }else{
      toast.warn(api_error_message,{toastId:random_number});
    }
  })
}
const handleAddTrack=()=>{
history.push('/admindashboard/learningtrackadd')
}

  return !update_flag ? (
    <>
      <div className="bg-body ">
        <Container className="  pb-0 d-flex flex-row justify-content-between">
          <div className="mt-4 " style={{ marginLeft: "90px" }}>
            <h3 className="admin-tabs-heading" data-testid="Courses_Heading">Courses</h3>
          </div>
          <div>
            <button
              data-testid = "Add_Track_Button"
              type="button"
              className="btn btn-secondary admin_button  ms-2 float-end d-flex w-auto mt-4"
              value="Add Cohort"
              onClick={handleAddTrack}
            >
              <span className="text-white ">Add Track</span>
            </button>
          </div>
        </Container>
        <div className=" mt-3 m-auto col-lg-8  align-center course-table">
        {table_loading_flag?
        <>
          <div className="minheight50">
            <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
        </div>
        </>:
        <>
          <Table data-testid="Course_Table" className="table_custom">
            <thead className="programs-head">
              <tr>
                <th>S.No</th>
                <th>Course</th>
                <th>Learning Track</th>
                <th>Cohort</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
              {mappedCourse.map((item, index) => {
                return (
                  <Fragment key={index}>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.course_title}</td>
                    <td data-testid="Learning_track_Name">{item?.learning_tracks?.learning_track_name}</td>
                    <td>{item?.cohort_schedules?.length}</td>
                    <td
                      data-testid={`update_The_Course_${index}`}
                      className="cursor-pointer"
                      onClick={() =>
                        updateTheCourse(item._id,item?.learning_tracks?.learning_track_name)
                      }
                    >
                      Update
                    </td>
                  </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
          <div className="d-none" data-testid="Table_Length">{mappedCourse.length}</div>
        </>}
          
        </div>
      </div>
    </>
  ) : (
    loading_flag ? (
      <div>
      <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
    </div>
    ):
    <>
    <div className="bg-body ">
      <div className="d-flex justify-content-between">
        <div>
          <h3 data-testid="Course_Update_Heading" className="blue_text">Course Update</h3>
        </div>
        <div className="row">
          {" "}
          <div className="col-6">
          <button
            data-testid="Cancel_Button"
            className="btn my_outline_button_cls align-self-end program_btn_back"
            onClick={backtoAdmin}
          >
            Cancel
          </button>
          </div>
          <div className="col-6 pl-0">
          <button data-testid="Update_Button"  type="button" className="my_btn_cls program_btn update_course_btn" 
          onClick={handleSubmit(updateCourse)}>Update</button>

          </div>
        
        </div>
      </div>
      <div><h4 data-testid="Track_Heading">{track_name}</h4></div>
      <div id="multicolumn1">
        {cohorts.map((item,) => {
          return (
            <Fragment key={item._id}>
              <div data-testid="Check_Boxes" className="checkbox form-control">
                <input type="checkbox" id={item._id}  onChange={e => getChecked(e,item?.program_id?.status)}  />
                <span>{item?.program_id?.product_id}</span>
              </div>
            </Fragment>
          );
        })}
        
      </div>
      <div className="col-lg-12 mt-5">
                                            <label data-testid="Course_Name" htmlFor="course_name">Course Name <span className="requiredInput">*</span></label>
                                        <input {...register("course_name",coursesListValidations.course_name)}
                                            type="text"
                                            data-testid="Coursename_Input"
                                            className="form-control"
                                            id="course_name"
                                            name="course_name"
                                            placeholder=" "
                                            required
                                            role="presentation" autoComplete="off"
                                            onChange={handleChangeInput}
                                        />
                                        {errors.course_name?.message && <span className="form-label-color">{errors.course_name?.message}</span>}
                                    </div>
      <div className="col-lg-12 mt-1">
                                            <label htmlFor="image_url" data-testid="Image_Url">Image Url <span className="requiredInput">*</span></label>
                                        <input {...register("image_url",coursesListValidations.image_url)}
                                            type="text"
                                            data-testid = "Imageurl_Input"
                                            className="form-control"
                                            id="image_url"
                                            name="image_url"
                                            placeholder=" "
                                            required
                                            role="presentation" autoComplete="off"
                                            onChange={handleChangeInput}
                                        />
                                        {errors.image_url?.message && <span className="form-label-color">{errors.image_url?.message}</span>}
                                    </div>
                                    <div className="col-lg-12 mt-1">
                                            <label data-testid="Course_Takeaway" htmlFor="course_takeaway">Course Takeaway</label>
                                        <input 
                                            type="text"
                                            data-testid="Coursetakeaway_Input"
                                            className="form-control"
                                            id="course_takeaway"
                                            name="course_takeaway"
                                            placeholder=" "
                                            required
                                            role="presentation" autoComplete="off"
                                            onChange={handleChangeInput}
                                        />
                                    </div>
         <div className=" col-lg-12 mt-1 text-area-font">
                                        <label data-testid="Description" htmlFor="description" for="course_title">Description</label>
                                        <textarea
                                            type="text"
                                            data-testid="Description_Input"
                                            id="description"
                                            name="description"
                                            placeholder=" "
                                            required    
                                            role="presentation" autoComplete="off"
                                            onChange={handleChangeInput}
                                           cols="30" rows="1"
                                        />
                                    </div>
      </div>
      <div className="mt-5  choose_file">
      <button className="my_btn_cls p-2 mt-0 program_btn">
      <input  {...register("file",coursesListValidations.file)}
                                            data-testid ="Upload_File"
                                            type="file"
                                            className="form-control"
                                            id="file"
                                            name="file"
                                            placeholder="Please select a file"
                                            required
                                            role="presentation" autoComplete="off"
                                            onChange={handleChangeFileUpload}
                                        /> Upload File
                                         
      </button>
      {errors.file?.message && <p className="form-label-color text-center mt-1">{errors.file?.message}</p>}
      </div>
    </>
  );
};
export default withMaintenanceCheck(CourseLearningTrackList,admin_maintenance_type) 
