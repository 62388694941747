import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import axios from "../../axios-interceptor";
import { random_number,base_url,api_error_message, user_maintenance_type } from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";

const CardMain = () => {
  const history = useHistory();
  var [coursedata, setCourseData] = useState([]);
  let [learning_tracks,setLearningTracks] =useState([])
  var [cohort_data,setCohortData] = useState([])
  var [timer_flag, setTimeFlag] = useState(false);
  useEffect(()=>{
    // if(!user && !localStorage.getItem("login")){
    //   loginWithRedirect()
    // }
    if(localStorage.getItem("isb_user") || localStorage.getItem("user_id")){
      history.push('/dashboard')
    }
    axios.post(base_url + "create/get_learning_track", {})
    .then((res)=>{
       
        setTimeFlag(true);
       setLearningTracks(res.data.result)
    }).catch((err)=>{
      if(err.response?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else if(err.message == 'Request aborted'){
        window.location.reload()
      }else{
        toast.warn(api_error_message,{toastId:random_number});
      }
    })
    // axios
    //   .post(base_url + "courses/get_all_courses",)
    //   .then((res) => {
    //     setTimeFlag(true);
    //     // console.log(res.data.courses)
    //     // res.data.courses.map(item=>{
    //     //   console.log('43', item.cohort_schedules)
    //     // })
    //     setCourseData(res.data.courses);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },[])
 
    return (
      
       <>
       <Container className='bg-transparent'>
         <img className='w-100' src="./images/topbannermerged.png" alt=""  />
         </Container>


      <div className="container-sm mt-3 mb-3">
      <h1 className='fw-bold p-3'>Join our Professional Courses</h1>
        {!timer_flag ? (
                <div>
                <img className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
            </div>
        ):
        <div className="row justify-content-evenly" style={{ padding: "0" }}>
        {learning_tracks.map((props) => {
         
          return (
          
              <div className="d-none">dfjkdh</div>
          );
        })}
      </div>
        }
       
      </div>
      {/* <CourseCardsPtwo />

      <LearningTrack /> */}
    </>
  );
};

export default withMaintenanceCheck(CardMain,user_maintenance_type)
