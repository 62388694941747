import React, { useEffect, useState } from "react";
import axios from "../../axios-interceptor";
import { Modal } from "react-bootstrap";
import { base_url, date_formats, api_error_message, random_number, quiz_name, identify_quiz, user_maintenance_type } from "../../utilities";
import moment from 'moment';
import { useHistory, useLocation, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { useForm } from "react-hook-form";

const ViewFullProfile = () => {
  const history = useHistory();
  let location =useLocation()
  if (!localStorage.getItem('Admin_Acesstoken')) {
    history.push('/adminlogin')
  }
  let [showModal, setShowModal] = useState(false);
  let [email_show_modal,setEmailShowModal] = useState(false);
  let [email_exist,setEmailExist] = useState(false);
  const [courses, setCourses] = useState([])
  const [listCourses, setListCourses] = useState([])
  const [userData, setUserData] = useState([])
  const [courseScore, setCourseScore] = useState([])
  let [activityList, setActivityList] = useState([])
  let [course_end_date,setCourseEndDate] = useState()
  let [course_name,setCourseName] = useState('')
  let [learner_email,setLearnerEmail] = useState('')
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  let [extend_show_modal,setExtendShowModal] = useState(false)
  let [cohort_id,setCohortId] = useState('')
  let [program_id,setProgramId]= useState('')
  var lessonScores = []
  let [end_date,setEndDate] = useState()
  const {
    register,
    handleSubmit,setValue,getValues,
    formState: { errors },
  } = useForm({ mode: "all" });
  var calDate = (location.state?.dates)
  useEffect(() => {
    setListCourses(location.state.courses);
    callApis(location.state.cohortId)
    
  }, []);
  const callApis = (cohortId) => {
    setCohortId(cohortId)
    var timestamp = Date.now()
    axios.post(base_url + "user/program_management/profile", {
      "user_id": location.state.id,
      "cohort_schedule_id": cohortId,
      "date": timestamp
    }).then(res => {
      if (res.data.result) {
        setUserData(res.data.result)
        setProgramId(res.data.result.program_id)
        setCourses(res.data.result.courses)
        setLearnerEmail(res.data.result?.email)
        setValue('Email', res.data.result?.email, { shouldDirty: true })
        res.data.result.courses.map(item=>{
          if(item.cohort_schedule_id == cohortId){
            setCourseName(item.course_title)
            setEndDate(item.cohort_expiry_date?item.cohort_expiry_date:(item.cohort_end_date))
            setCourseEndDate(item.cohort_expiry_date?item.cohort_expiry_date:(item.cohort_end_date))
          }
        })
        
      }
    })


    axios.post(base_url + "user/program_management/activity/scoring",

      {
        "user_id": location.state.id,
        "cohort_schedule_id": cohortId,
        "date": timestamp
      }).then(res => {
        if (res.data.data != 0) {
          let resultData = res.data.result ? res.data.result : []
          let activityList = []
          resultData.map(courses => {
            courses?.course?.modules?.map(module => {
              module?.lessons?.map(lesson => {
                lesson['courseName'] = courses?.course?.title;
                lesson['moduleName'] = module?.title;
                activityList.push(lesson)
              })
            })
          })
          activityList.sort((a, b) => parseInt(b.last_activity_date) - parseInt(a.last_activity_date))
          setActivityList(activityList)
          setCourseScore(res.data.result)
        }
        else {
          // alert('No data found for this user')
          moveToProgramManagement()
        }
      }).catch(err => {
        console.log(err)
        if (err.response?.data.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      })
  }
  const hideModal = () => setShowModal(false);
  useEffect(() => {
    calDate.map((cal, id) => {
      if ((moment(cal).format("MMM YYYY") == moment().format("MMM YYYY"))) {
        handleCohortDate(cal)
      }
    })
  }, [courses])



  const [flagone, setFlagone] = useState(true)


  const [currentMnth, setCurrentMnth] = useState("current")

  const [filteredCourses, setFilteredCourses] = useState([])
  const [selectedDate, setSelectedDate] = useState()


  const handleCohortDate = (value) => {
    let filtered_course = []
    // const selectedMonth = moment(parseInt(value)).format("MMM YYYY");
    courses.map(course => {
      // if (moment(parseInt(course.cohort_start_date)).format("MMM YYYY") === selectedMonth) {
        filtered_course.push(course)
      // }
    })
    filtered_course.sort((a, b) => {
      return (b.last_activity_date) - (a.last_activity_date)
    })
    setFilteredCourses(filtered_course)
    setSelectedDate(parseInt(value))
  }

  const handleCourse = (value)=>{
    setCourseScore([])
    callApis(value);
  }

  const moveToProgramManagement = e => {
    history.push('/programmanagementdashboard/programmemanager')

  }
  const getConvertedTime = (time) => {
    let seconds = parseInt(time)
    if (isNaN(seconds)) {
      return "--";
    }
    else if (seconds < 60) {
      return seconds + " secs";
    } else if (seconds >= 60 && seconds < 3600) {
      return Math.floor(seconds / 60) + " mins";
    } else {
      return Math.floor(seconds / 3600) + " hours";
    }
  }

const onChangeDate = (e)=>{
  if (date_formats.human_date_time_format(e) != date_formats.human_date_time_format(course_end_date)) {
    setCourseEndDate(date_formats.human_date_time_format(e))
    setShowModal(true)
  } else {
    setCourseEndDate(course_end_date)
    setShowModal(false);
  }
 
}
const onCancel = () =>{
  setCourseEndDate(end_date)
  setShowModal(false);
}

const clearApiErrorMessage = () =>{
  setValue('Email', learner_email, { shouldDirty: true })
  setApiErrorMessage("")
}

const saveLearnerEmail = (updated_email) =>{
  axios.post(base_url + "admin/change_user_email",
  {
    "new_email":updated_email,
    "existing_email": learner_email,
  }).then(res => {

    if(res.data.error_code != 200){
      setEmailExist(true)
      setApiErrorMessage(res.data.message)
     }else{
      setEmailExist(false)
      setLearnerEmail(updated_email)
      setEmailShowModal(false)
      toast.success("Email updated successfully", { toastId: random_number });
     } 
  }).catch(err => {
   console.log(err)
    if (err.response?.data.statusText == 'Unauthorized') {
      localStorage.clear();
      history.push('/adminlogin')
      window.location.reload();
    }else if(err.message == 'Request aborted'){
      window.location.reload()
    } else {
      toast.warn(api_error_message, { toastId: random_number });
    }
  })
}
const extendDate = () =>{
  axios.post(base_url + "program_manager/extend_course_date",
      {
        "user_id": location.state.id,
        "cohort_schedule_id": cohort_id,
        "program_id": program_id,
        "extended_date":  moment(course_end_date).format("x")
      }).then(res => {
        hideModal()
        setTimeout(()=>{
          setExtendShowModal(true)
        },1000)
      }).catch(err => {
        console.log(err);
        if (err.response?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      })
 
 
}
  return (<>
    {courseScore.length != 0 ?
      <div className="row m-0 mt-3 p-0">
        <div className="col-lg-3 bg-body custm_shadow_cls p-3 m-1">

          <Link to='/programmanagementdashboard/programmemanager'><button className="btn my_btn_cls pm_view_profile" data-testid="moveToProgramManagement" onClick={moveToProgramManagement}>
            <img src="./images/arrow.svg" alt="" />  Dashboard
          </button></Link>
          <div className="col d-flex align-items-center justify-content-between ps-0 pe-0">
            <div className="mt-4 mb-4">
              <img src="./images/UserCircle.svg" alt="" />
            </div>
            <div>
              <h3 className=' text-capitalize ps-2 title_txt_color'><b >{userData.first_name} {userData.last_name}</b></h3>
              <h5 className="blue_text">Last Active <b>{userData.last_active} Days Ago</b></h5>
            </div>
          </div>
          <p className="bg-isbo text-white rounded p-2">
            <div className="float-left">
              {userData.email && <>
                <span className="mr-2"><i className="fa-solid fa-envelope"></i></span>
              </>}
              {learner_email}
            </div>
            <div className="float-end">
              <img src="./images/edit_icon.svg" alt="" className="cursor-pointer" data-testid="edit_icon" onClick={()=>setEmailShowModal(true)}/>
            </div>
            <br />
          </p>
          <p className="p-2">
          {userData.mobile && <> <span className="mr-2"><i className="fa-solid fa-phone"></i></span></>}
            {" "}
            {userData?.mobile}
          </p>
          <p className="p-2">
            {userData?.city && <><span className="mr-2"><i className="fa-solid fa-location-dot"></i></span></>}
            {userData?.city && (userData?.city.charAt(0).toUpperCase() + userData?.city.substr(1).toLowerCase())}{(userData?.city && userData?.state) ? ',' : ''} {userData?.state && (userData?.state.charAt(0).toUpperCase() + userData?.state.substr(1).toLowerCase())} <br />
            <span className="ml-4">
              {userData?.country && (userData?.country.charAt(0).toUpperCase() + userData?.country.substr(1).toLowerCase())}{(userData?.country && userData?.postcode) ? ',' : ''} {userData?.postcode}

            </span>
          </p>
          <p className="p-2">
            {userData?.role && <>
              <span className="mr-2">
                <i className="fa-solid fa-building"></i>
              </span>
            </>
            }
            {userData?.role && (userData?.role.charAt(0).toUpperCase() + userData?.role.substr(1).toLowerCase())}{userData?.role ? ',' : ''} {userData?.company && (userData?.company.charAt(0).toUpperCase() + userData?.company.substr(1).toLowerCase())} ({userData?.years_company} Years) <br />
            {userData?.industry && <> <span className="mr-2">
              <i className="fa-solid fa-industry"></i>
            </span></>}
            {userData?.industry && (userData?.industry.charAt(0).toUpperCase() + userData?.industry.substr(1).toLowerCase())}<br />
            {userData?.total_years && <><span className="mr-2">
              <i className="fa-solid fa-calendar-days"></i>
            </span></>}
            Total Experience {userData?.total_years} Years</p>
          <div className="d-flex align-items-baseline p-2">
            <div>
              {userData?.high_education && <><span><i data-testid="userData_high_education" className="fa-solid fa-graduation-cap"></i></span></>}
            </div>
            <div>
              <p className="p-2">{userData?.high_education && (userData?.high_education.charAt(0).toUpperCase() + userData?.high_education.substr(1).toLowerCase())}{userData?.high_education ? ',' : ''} {userData?.high_institute && (userData?.high_institute.charAt(0).toUpperCase() + userData?.high_institute.substr(1).toLowerCase())}{userData?.high_institute ? ',' : ''} <br />
                {userData?.prev_education && (userData?.prev_education.charAt(0).toUpperCase() + userData?.prev_education.substr(1).toLowerCase())}{userData?.prev_education ? ',' : ''}
                {userData?.prev_institute && (userData?.prev_institute.charAt(0).toUpperCase() + userData?.prev_institute.substr(1).toLowerCase())}{userData?.prev_institute ? ',' : ''} {userData?.graduation_year}</p>
            </div>
          </div>

          <p className="p-2">{userData?.dob ? 'DOB' : ''} {userData?.dob && (moment(userData.dob).format("Do MMM YYYY"))}</p>
          {/* <div className="p-2">
            <span>
              {" "}
              <b>Internal Notes</b>
            </span>
            <textarea
              className="p-4"
              type="text"
              id="description"
              name="description"
              placeholder=" "
              required
              role="presentation"
              autoComplete="off"
              cols="1"
              rows="1"
            />
            <button className="btn my_btn_cls float-end">Save</button>
          </div> */}
        </div>
        <div className="col-lg-4">
          <div className="mb-3 mt-4 d-flex justify-content-evenly align-items-center">
            <b>{location.state?.program_name ? location.state?.program_name : ""}</b>
            <div className="dropdown  ">
              <form>
                <label htmlFor="datevalue"></label>
                <select data-testid="handleCourse" className=" p-2 rounded" onChange={(e) => handleCourse(e.target.value)}>
                  {listCourses.map((course, id) => {
                    return (
                      <option key={id} selected={filteredCourses[0]?.course_title == course.title ? true : false} value={course.cohorts[0].id}>{course.title}</option>
                    )
                  })}
                </select>
              </form>
            </div>
          </div>
          <div className="mb-3 mt-4 d-flex justify-content-evenly align-items-center">
            <span className="fw-semibold">Course accessible until</span>
            <KeyboardDateTimePicker
                           disablePast={true}
                            className="view_full_profile_datepicker"
                            format="MMM d, yyyy"
                            value={course_end_date}
                            data-testid="change_date"
                            onChange={(e)=>onChangeDate(e)}
                            inputProps={{
                              readOnly: true,
                              style: { textAlign: 'center' }
                            }}
                          />
          </div>
          <div className=" bg-body custm_shadow_cls p-4  pm_course_element">
            {
              filteredCourses.length == 0 ? <div>No data available for this cohort</div> : filteredCourses.map((coursedata, course_id) => {

                return (
                  <div key={course_id} className="">
                    <div className="mb-2 mt-4">{coursedata.course_title}</div>
                    {coursedata?.modules.map((moduledata) => {
                      return (
                        <div className="row ps-4 p-2 " key={moduledata._id}>
                          <div className="col"><span className="pm_module_title"> {moduledata.module_title}</span></div>
                          <div className="col">
                            {moduledata?.lessons.map((lessondata) => {

                              return (
                                <div className="tool_tip" key={lessondata.lesson_id}>
                                  {lessondata.is_lesson_completed && lessondata.is_started &&
                                    !lessondata.title.toLowerCase().includes(identify_quiz) ? (
                                    <img
                                      className=""
                                      src="./images/green_circle.svg"
                                      alt=""
                                    />
                                  ) : !lessondata.is_lesson_completed && !lessondata.is_started &&
                                    !lessondata.title.toLowerCase().includes(identify_quiz) ? (
                                    <img
                                      className=""
                                      src="./images/gray_circle.svg"
                                      alt=""
                                    />
                                  ) : !lessondata.is_lesson_completed && lessondata.is_started && !lessondata.title.toLowerCase().includes(identify_quiz) ? (
                                    <img src="./images/dark_gray_circle.svg" alt="" />
                                  ) : lessondata.is_lesson_completed && lessondata.is_started &&
                                    lessondata.title.toLowerCase().includes(identify_quiz) ? (
                                    <img
                                      src="./images/pm_green_polygon.svg"
                                      alt=""
                                    />
                                  ) : !lessondata.is_lesson_completed && !lessondata.is_started &&
                                    lessondata.title.toLowerCase().includes(identify_quiz) ? (
                                    <img
                                      src="./images/pm_gray_polygon.svg"
                                      alt=""
                                    />
                                  ) : !lessondata.is_lesson_completed && lessondata.is_started &&
                                    lessondata.title.toLowerCase().includes(identify_quiz) ? (<img
                                      src="./images/pm_dark_gray_polygon.svg"
                                      alt=""
                                    />) : lessondata.is_lesson_completed && !lessondata.is_started &&
                                      !lessondata.title.toLowerCase().includes(identify_quiz) ? <img
                                    className=""
                                    src="./images/gray_circle.svg"
                                    alt=""
                                  /> : ''
                                  }
                                  <span className="tooltip_text">
                                    {lessondata.title}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )
              })}

          </div>
        </div>
        <div className="col bg-body custm_shadow_cls p-4 m-1">
          <h4 className="fw-bold mb-5">Activity Log</h4>


          <div className="row pm_course_element">
            <div className="col-lg-4 fw-bold bg-isbBlue text-white pt-2 pb-2">Activity</div>
            <div className="col-lg-2 fw-bold bg-isbBlue text-white pt-2 pb-2">Points</div>
            <div className="col-lg-3 fw-bold bg-isbBlue text-white pt-2 pb-2">Time Spent</div>
            <div className="col-lg-3 fw-bold text-center bg-isbBlue text-white pt-2 pb-2">Last Activity</div>




            {activityList?.map(lesson => {
              lessonScores.push(lesson)
              return (
                <>
                  <div className="row pt-3">
                    <div className="col-lg-4">
                    <span className="fw-bold">{`${lesson.moduleName} > `}</span>
                      <br />
                      <span className="">{lesson.title !== '' ? lesson.title : 'No Activity Done'}</span>
                    </div>
                    <div className="col-lg-2 text-center"> {`${lesson.earned_score}/${lesson.total_score}`}</div>
                    <div data-testid='getConvertedTime' className="col-lg-3 text-center"> {getConvertedTime(lesson.total_time_spent)}</div>
                    <div className="col-lg-3 text-end">{date_formats.human_date_time_format(lesson.last_activity_date)}</div>
                  </div>
                </>)
            })}
          </div>
        </div>
      </div>
      : (<div>
        <img className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
      </div>)} 
      {showModal && (
        <Modal show={showModal} centered ClassName="role_modal" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
            {/* <Modal.Header closeButton>
        </Modal.Header> */}
          <Modal.Body >
            <div className="text-center">
          {/* <h4 className="blue_text text-center redo_quiz">Redo Quiz?</h4> */}
            <p className="modal_text fw-semibold">Are you sure you want to extend the date for
“ {course_name} “ course?</p>
            </div>
            <div className="text-center pb-3 redo_btn_container">
            <button data-testid="cancel_extendDate" className="btn review_quiz_button my_outline_button_cls submit_button fw-bold  my_redo_quiz_btn m-0 px-4" onClick={onCancel}><b>Cancel</b></button>&nbsp;&nbsp;
            <button data-testid="extendDate" className="btn my_btn_cls submit_button m-0 px-4 fw-bold" onClick={extendDate}>Extend Date</button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {extend_show_modal && (
        <Modal show={extend_show_modal} centered ClassName="role_modal" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
           
          <Modal.Body >
            <div className="text-center">
            <p className="modal_text fw-semibold">
“ {course_name} “ course date has extended successfully</p>
            </div>
            <div className="text-center pb-3 redo_btn_container">
    
            <button className="btn my_btn_cls submit_button m-0 px-4 fw-bold px-5" onClick={()=>setExtendShowModal(false)}>OK</button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {email_show_modal && (
        <Modal show={email_show_modal} centered ClassName="role_modal" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}} onShow={clearApiErrorMessage}  data-testid="email-modal">
           
          <Modal.Body >
            <div className="p-2">
              <p className="black fs-5">Email</p>
              <input
              {...register("Email", {
                  required: "Email is required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\s]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
                type="text"
                className="form-control rounded-left border-radius-none"
                placeholder="Email"
                id="Email"
                name="Email"
                data-testid="email_input"
                autoFocus
                role="presentation"
                autoComplete="off"
              />
              {errors.Email?.message && (
                <span  role="alert" style={{ color: "red" }}>
                  {errors.Email?.message}
                </span>
              )}
              {email_exist && !errors.Email?.message  && <p className="mb-0" style={{ color: "red" }}>{apiErrorMessage}</p> }
            </div>
            <div className="text-end redo_btn_container">
              <button className="btn review_quiz_button my_outline_button_cls submit_button fw-bold  my_redo_quiz_btn m-0 px-4" style={{padding:"11px"}} onClick={()=>setEmailShowModal(false)}><b>Cancel</b></button>&nbsp;&nbsp;
              <button data-testid='saveLearnerEmail' className="btn my_btn_cls submit_button m-0 px-4 fw-bold" disabled={errors.Email?.message} onClick={() => saveLearnerEmail(getValues("Email"))}>Save changes</button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      </>);
};

export default withMaintenanceCheck(ViewFullProfile,user_maintenance_type) 
