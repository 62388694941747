import axios from "../../axios-interceptor"
import moment from "moment"
import { useEffect } from "react"
import { useState,Fragment } from "react"
import { base_url,api_error_message,random_number, quiz_name, learn_by_doing, check_point, did_i_get_this} from "../../utilities"
import { BarChart } from "./barchart"
import { MessageComponent } from "./messageComponent"
import { useRef,useLayoutEffect} from "react";
import Tooltip from "react-simple-tooltip";
import { toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
export const ActivityElementCheckAllApply = (props) => {
  let text = '';
  const history = useHistory();
  const [suceess_msg, setSuccessMessage] = useState('')
  var [button_name, setButtonName] = useState('Submit')
  var [flag, setFlag] = useState(false)
  var [msg, setMsg] = useState('')
  let [ele_data, setEleData] = useState()
  let title = props.title;
  let purpose = props.purpose;
  let quiz_review_selected = props.quiz_review;
  // var ans_array = []
  // let user_ans = []
  let [ans_array,setAnsArray] =useState([])
  let [user_ans,setUseAns] =useState([])
   let [chart_lables, setChartLables] = useState([])
  let [chart_data, setChartData] = useState([])
  let [data, setData] = useState([])
  let [points_data,setPointsData] = useState({})
  let [peer_flag,setPeerFlag] = useState(false)
  let [button_flag,setButtonFlag] = useState(true)
  let [option_flag,setOptionFlag] =useState(false)
  let [submit_flag,setSubmitFlag] = useState(true)
  const [activityInitiatedTime, setActivityInitiatedTime] = useState(moment());
  const check_all_apply_Component=useRef(null)
  let [tooltipShow,setTooltipShow] = useState(false)
    useLayoutEffect(() => {
      quiz_review_selected  ?
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Add smooth scrolling behavior
        });
      }, 350) :
      props.i==0 ?
     setTimeout(() => {
         window.scrollTo({
           top: window.scrollY,
           left: 0,
           behavior: "smooth",
         });
       }, 350):
       setTimeout(() => {
           window.scrollTo({
            top:  check_all_apply_Component.current?.offsetTop-40,
            left: 0,
             behavior: "smooth",
           });
         }, 350)
    }, []);
    for (const value of props.data.content.stem.content) {
      if (value?.children) {
        if(value?.type=='ol')
        {
          recursive_ol_data(value.children);
        }
        if(value?.type=='ul')
        {
          recursive_ul_data(value.children);
        }
        if (value?.type == 'h2' || value?.type == 'h1') {
          recursive_h2_data(value)
        }
        if(value?.type == 'p')
        {
          recursive_p_data(value)
        }
        for (const value1 of value.children) {
          if (value1?.type === 'a') {
            text += `<a target= 'blank' href='${value1.href}'>${value1.children[0].text}</a>`
          }
          // if (value?.type == 'h2' || value?.type == 'h1') {
          //   text += '<h2 class="question-title">' + (value1?.text ? value1.text : '') + '</h2>';
          // }
          else if(value?.type!=='h2'&&value?.type!=='h1'&&value?.type!=="p") {
            if(value1?.text ){
              text += `<span class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
              if(value1.em){
                text += `<em class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
                text += value1?.text ? value1.text : ''
                text += '</em>';
              }else if (value1?.strong) {
                text += `<strong class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
                text += value1?.text ? value1.text : ''
                text += '</strong><br/>';
              }
              else{
                text += value1?.text ? value1.text : ''
                text += '</span>';
              }
            }
          
          }
        }
      }
    }

    function recursive_ol_data(data){
      let str_li = '<ol class="summary_uList_cls">';
      for(const value of data){         
          for(const value1 of value.children){
            if (value1?.strong) {
              str_li += '<li class="summary_list_cls"><strong class="summary_header_cls">';
              str_li += value1.text;
              str_li += '</strong>';
            }else{
              str_li += '<li class="summary_list_cls">'+value1.text
            }
          }
          str_li += '</li>' 
        }
       text+=str_li+ '</ol>';
    }
    function recursive_ul_data(data){
      for(const value of data){ 
        if(value?.type !== 'li'){
          recursive_ul_data(value.children)
        }else{          
          let str_li = '<ul class="summary_uList_cls"><li class="summary_list_cls">';
          for(const value1 of value.children){
            if (value1?.strong) {
              str_li += '<strong class="summary_header_cls">';
              str_li += value1.text;
              str_li += '</strong>';
            }else{
              str_li += value1.text
            }
          }
          str_li += '</li>' 
          text +=str_li+ '</ul>';
        }
      }
    }
    function recursive_h2_data(value)
    {
       text += '<h2 class="question-title">'
        for (const value1 of value.children) {
          if(value1?.sup)
          {
            text += '<sup>';
            text += (value1?.text ? value1.text : '')
            text += '</sup>';
          }
          else{
            text +=(value1?.text ? value1.text : '')
          }
        }
        text+='</h2>';
    }

    function recursive_p_data(value)
    {
      text += `<span class="summary_header_cls d-block pb-4">`;
      for (const value1 of value.children) {
        if(value1?.sup)
        {
          text += '<sup>';
          text += (value1?.text ? value1.text : '')
          text += '</sup>';
        }
        else if(value1.em){
          text += `<em>`;
          text += value1?.text ? value1.text : ''
          text += '</em>';
        }
        else if (value1?.strong) {
          text +=`<strong>`;
          text += value1?.text ? value1.text : ''
          text += '</strong>';
        }
        else{
          text +=(value1?.text ? value1.text : '')
        }
      }
      text+='</span>';
    }
    text = text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
    props.data.final_text = text;
  useEffect(() => {
    if(localStorage.getItem('re_attempt')){
      props.data.is_answered = false
    }
    purpose !==quiz_name ? setButtonFlag(true) :  setButtonFlag(false)
    // setButtonFlag(false)
    if (props.data.is_answered) {
      setButtonFlag(props.data.is_answered)
      props.parentCallback('false');
      purpose !==quiz_name ?  setFlag(props.data.is_answered) : setFlag(!props.data.is_answered)
      setOptionFlag(true)
      setSubmitFlag(true)
      const list = { ...props.data };
      
      if(purpose ==quiz_name && quiz_review_selected == true){
        const selected_item = list.user_answer.map(option => option.option_id);
        if (props.data.content.authoring.correct[0].length == selected_item.length) {
          if (props.data.content.authoring.correct[0].sort().toString() == selected_item.sort().toString()) {
            setMsg('yes')
            let feedback = props.data.content.authoring.parts[0].responses[0].feedback;
            if (feedback.content[0].children[0].text == "") {
              // Set the text to "That’s correct"
              feedback.content[0].children[0].text = "That’s correct";
            }
            setSuccessMessage(props.data.content.authoring.parts[0].responses[0].feedback.content) //[0].children[0].text
          } else {
            setMsg('no')
            let feedback = props.data.content.authoring.parts[0].responses[1].feedback;
            if (feedback.content[0].children[0].text == "") {
              // Set the text to "Not quite"
              feedback.content[0].children[0].text = "Not quite";
            }
            setSuccessMessage(props.data.content.authoring.parts[0].responses[1].feedback.content) 
          }
        }
        else {
          setMsg('no')
          let feedback = props.data.content.authoring.parts[0].responses[1].feedback;
            if (feedback.content[0].children[0].text == "") {
              // Set the text to "Not quite"
              feedback.content[0].children[0].text = "Not quite";
            }
            setSuccessMessage(props.data.content.authoring.parts[0].responses[1].feedback.content) 
        }
      }
      list.content.choices.map(item1 => {
        item1['is_checked'] = false
        item1['color'] = "none"
      })
      let arr = []
      list.user_answer.map(item => {
        arr.push(item.option_id)
      })
      list.content.choices.map(item1 => {
        arr.map(item => {
          if (+item1.id == +item) {
            item1['is_checked'] = true
          }
        })
      })
      setEleData(list)
      let lable_array = []
      if (props.data.peer_response) {
        setPeerFlag(true)
        props.data.peer_response[0].labels.map((item, index) => {
          lable_array.push(item.toString().length > 15 ? item.slice(0, 15)+ '...' : item )
        })
        const results = props.data?.peer_response[0]?.counts?.map(element => {
          return parseInt(element.replace('%', ''))
        });
        setData(results)
        setChartLables(lable_array)
        setChartData(props.data.peer_response[0].counts)
      }
    } else {
      setEleData(props.data)
    }
    const container = document.createElement('div');
    container.innerHTML = props.data.final_text;
    const h2Text = container.querySelector('h2.question-title')?.innerText?.trim();
    const text = container.querySelectorAll('.summary_header_cls');
    const liElements = document.querySelectorAll('#checkAllthatApply .summary_list_cls');
    const headerTexts = Array.from(text).map(element => element.textContent.trim())
    const liTexts = Array.from(liElements).map(li => li.textContent.trim());
    let choice_array = []
    props.data.content.choices.map(item=>{
      item.content.map(item1=>{
        choice_array.push(item1.children[0].text)
      })
    })
    const combinedText = [...headerTexts,...liTexts,'Select all that apply', ...choice_array].join('. ');
    localStorage.setItem('prev_title',h2Text)
    localStorage.setItem('prev_text',combinedText)
  }, [props.data])
  
  // const Tagname = data.type!='Activity'?(data.type!='video'?(data.type!='img'?(data.type=='p'?'span':`${data?.type}`):"div"):"div"):"div";
  const getChecked = (e) => {
    let answer = {
      "option_id": e.target.id,
      "answer_label": e.target.value
    }

    // user_ans.push(answer)
   
    if (e.target.checked) {
      // ans_array.push(e.target.id)
      setAnsArray((current)=>[...current,e.target.id])
      setUseAns((current)=>[...current,answer])
    } else {
      setAnsArray((current)=>current.filter(function (item) {
        return item !== e.target.id
      }))
      setUseAns((current)=>current.filter(function (item) {
        return item.option_id !== e.target.id
      }))
    }
  }

  useEffect(() => {
    if(ans_array.length > 0){
      setSubmitFlag(false)
    }else{
      setSubmitFlag(true)
    }
  },[ans_array])

  const verifyAnswer = () => {
    let is_correct = ''
    if (!button_flag) {
     
      if (ans_array.length) {
        setTimeout(() => {
          window.scrollTo({
            top: window.scrollY+window.innerHeight,
            left: 0,
            behavior: "smooth",
          });
        }, 300);
       
        // setFlag(true)
        setTooltipShow(false)
        setButtonFlag(true)
        setOptionFlag(true)
        setSubmitFlag(true)
        if (props.data.content.authoring.correct[0].length == ans_array.length) {
        
          if (props.data.content.authoring.correct[0].sort().toString() == ans_array.sort().toString()) {
            setMsg('yes')
            is_correct = true
            // localStorage.setItem('is_correct',true)
            setSuccessMessage(props.data.content.authoring.parts[0].responses[0].feedback.content) //[0].children[0].text
          } else {
            is_correct = false
            // localStorage.setItem('is_correct',false)
            setMsg('no')
            setSuccessMessage(props.data.content.authoring.parts[0].responses[1].feedback.content) //[0].children[0].text
          }
        }
        else {
          setMsg('no')
          is_correct = false
          localStorage.setItem('is_correct', false)
          setSuccessMessage(props.data.content.authoring.parts[0].responses[1].feedback.content) //[0].children[0].text
        }

      } else {
        // setFlag(false)
        setOptionFlag(false)
        setButtonFlag(false)
        setSubmitFlag(false)
        setSuccessMessage('')
        setMsg('')
      }
      const activityEndTime=moment()
      var body = {
        user_id: localStorage.getItem("user_id"),
        course_id: props.data.course_id,
        module_id: props.data.module_id,
        lesson_id: props.data.lesson_id,
        cohort_schedule_id: props.data.cohort_sch_id,
        activity_id: props.data.activity_id,
        activity_entity_type: props.data.acitivity_entity_type,
        is_question: true,
        user_answer: user_ans,
        is_correct: is_correct,
        question_type: props.data.subType,
        answer_label: "Test",
        is_quiz: purpose == quiz_name ?  true: false,
        time_spent_on_activity:activityEndTime.diff(activityInitiatedTime,'seconds'),
        activity_submitted_platform: "ISBo"
      };
      if(localStorage.getItem("user_id") && window.location.pathname.toLowerCase().includes('freelessonpage') == false&&window.location.pathname.toLowerCase().includes('freelessonbdlink') == false){
      axios
        .post(base_url + "user/progress_tracking", body)
        .then((res) => {
          localStorage.removeItem('re_attempt')
          highlightSelectedResponses()
          purpose !==quiz_name ?  setPeerFlag(true) :  setPeerFlag(false)
          let lable_array = []
          res.data.result[0]?.labels.map((item, index) => {
            lable_array.push(item?.toString().length > 15 ? item.slice(0, 15)+ '...' : item )
          })
          const results = res.data.result[0]?.counts.map(element => {
            return parseInt(element.replace('%', ''))
          });
          if(typeof(res.data.points_break_down) == 'object'){
            setPointsData(res.data.points_break_down)
          }
          setData(results ? results : [])
          setChartLables(lable_array)
          setChartData(res.data.result[0]?.counts)
          setActivityInitiatedTime(moment())
          props.parentCallback('false');
        })
        .catch((err) => {
          console.log(err);
          props.parentCallback('');
          setButtonFlag(false)
          setOptionFlag(false);
          setSubmitFlag(false)
          if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/')
            window.location.reload();
          }else if(err.message == 'Request aborted'){
            window.location.reload()
          }else{
            toast.warn(api_error_message,{toastId:random_number});
          }
        });
      }else{
        highlightSelectedResponses()
        setPointsData({"free_lesson":"1"})
        props.parentCallback('false');
      }
    }   
  } 

  const highlightSelectedResponses = () => {
    ele_data.content.choices.map((item1, idx) => {
      if (props.data.content.authoring.correct[0].includes(item1.id)) {
        item1['color'] = "#C9F8CF";
      }
    })
    if (props.data.content.authoring.correct[0].length == ans_array.length) {
      if (props.data.content.authoring.correct[0].sort().toString() == ans_array.sort().toString()) {
        ele_data.content.choices.map((item1, idx) => {
          ans_array.map(item => {
            if (+item1.id == +item) {
              item1['color'] = "#C9F8CF";
            }
          })
        })
      } else {
        ele_data.content.choices.map((item1, idx) => {
          ans_array.map(item => {
            if (+item1.id == +item) {
              if (props.data.content.authoring.correct[0].includes(item1.id)) {
                item1['color'] = "#C9F8CF";
              } else {
                item1['color'] = "#F0CCCA";
              }

            }
          })
        })
      }
    }
    else {
      ele_data.content.choices.map((item1, idx) => {
        ans_array.map(item => {
          if (+item1.id == +item) {
            if (props.data.content.authoring.correct[0].includes(item)) {
              item1['color'] = "#C9F8CF";
            } else {
              item1['color'] = "#F0CCCA";
            }
          }
        })
      })
    }
  }

  const handleGetRadioValue=(content)=> {
    let value = '';
    content.forEach((item) => {
      const children = item.children || [];
      children.forEach((child) => {
        if (child && child.text) {
          value += child.text;
        }
      });
    });
    return value;
  }

  const renderProcessedContent=(content)=> {
    return (
      <div className="d-grid mobile_view_grid">
        {content.map(item => (
          <span key={item.id}>
            {item.children.map(child => (
              child.strong ? <b key={child.id}>{child.text}</b> : child.text
            ))}
          </span>
        ))}
      </div>
    );
  }


  const reviewPeerResponse =() =>{
    setSubmitFlag(true);
    let body = {
      "activity_id": props.data.activity_id, 
      "question_type":props.data.subType
    }
    if (ans_array.length) {
    axios.post(base_url + "peer_response/review_your_response", body).then((res) => {
      setFlag(true)
      setTimeout(() => {
        setTooltipShow(true)
      }, 1000);
      setButtonFlag(false);
      setSubmitFlag(false);
      purpose !==quiz_name?  setPeerFlag(true) :  setPeerFlag(false)
      let lable_array = []
      res.data.result[0]?.labels.map((item, index) => {
        lable_array.push(item?.toString().length > 15 ? item.slice(0, 15)+ '...' : item )
      })
    
      const results = res.data.result[0]?.counts.map(element => {
        return parseInt(element.replace('%', ''))
      });
      setData(results ? results : [])
      setChartLables(lable_array)
      setChartData(res.data.result[0]?.counts)
    }).catch((err)=>{
      console.log(err);
      props.parentCallback('');
      setSubmitFlag(false);
      if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else if(err.message == 'Request aborted'){
        window.location.reload()
      }else{
        toast.warn(api_error_message,{toastId:random_number});
      }
    })
    }
  }
  return (
    <>
    <div data-testid="ActivityElementCheckAllApply_content">
      <div ref={check_all_apply_Component} className="data-component col-12 custm_shadow_cls bg-body">
        <div className="d-flex flex-row justify-content-between align-items-end" style={{}}>
          <strong data-testid="multicheck_type" className=" mb-0 align-items-end" style={{ letterSpacing: "1px", opacity: "50%", }}>
            {purpose == learn_by_doing ? <p className="mb-0">
              <img style={{ opacity: "50%" }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA7ElEQVRIieWVMQrCMBhGH3Vyap1FcBYcPIpCr1Do0mPoFTyFCOIJqoIO3sLiWMShqFCH/IVQaEkhmfpBaBOS99ImJNCnDIE7cHYl2AClFOuZAx9XAg+4aHDrgkSgDxeCCfAS6NKFYC/Ag9StCkKB5cC4JqiXJxB1gQdAJoNjrT1tkfwA31SwlUEn1C5qywwoUNs4MIGPpHMhg9viySRKmZRRfNTnNv2KVOsbS1tmOvsqEWrhmiSgFj6XetgF3hZdsJP3oy24LljJ8w1MXQiqYyOxCdcFJXADBq4EX2BhG64L1i7goK7LK+r67En+G2pg6MDEsykAAAAASUVORK5CYII=" />

              PRIMING ACTIVITY</p> : (purpose == check_point ? <p>CHECK POINT</p> : (purpose ==quiz_name ? <p>QUESTION</p> : (purpose == did_i_get_this ? <p>DID I GET THIS?</p> : '')))}
          </strong>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-end">
          {ele_data && <>
            <span data-testid="multicheckhead_content" className="summary_header_cls" id="checkAllthatApply" dangerouslySetInnerHTML={{ __html: props.data.final_text }} />
          </> }
        </div>
        <div className="row">
        <div className="summary_header_cls pb-0 ml-checkbox-4" data-testid="multicheck_selectAlltext"><i>Select all that apply</i></div>
          <div data-testid={`multipleCheck_head`} className= {`pt-2 ml-checkbox-4 ${peer_flag ? "col-lg-8 col-md-12 col-sm-12" : "col-12"}`}>
            {
          
              ele_data && ele_data.content.choices.map((item, idx) => {
                return (
                  <Fragment key={item.id}>
                  <div  style={{ backgroundColor: purpose !==quiz_name?item.color:(props.data.is_answered ? item.color : 'none') }}>
                    <div className="form-group_checkbox pl-3" data-testid={`multipleCheck_${idx}`}>
                      <input type="checkbox" checked={item.is_checked} disabled={option_flag} id={item.id} value={handleGetRadioValue(item.content)} onChange={e => getChecked(e)} />
                      <label className="row" htmlFor={item.id}>
                        <span className="col">{renderProcessedContent(item.content)}</span>
                      </label>
                    </div>

                  </div>
                  </Fragment>
                )
              })

            }
            <br />
            {purpose !==quiz_name&& !flag  && 
           <button className="btn review_button submit_button mt-3" data-testid="notQuiz_submit_btn" disabled={submit_flag} onClick={reviewPeerResponse}><b>SUBMIT</b></button>}

            {flag && purpose !==quiz_name && 
            <> {button_flag? <button data-testid="checkAnswer_btn_tooltip" data-tip data-for={"cata"} className="btn my_btn_cls submit_button check_answ_button mt-3" disabled={button_flag} onClick={verifyAnswer} >
            CHECK ANSWER 
            </button>:<> {tooltipShow && <Tooltip data-testid="check_ans_tooltip" id="tooltip" content="You can change your response and then Check Answer" fixed={tooltipShow} arrow={10} radius={13} zIndex={1}  background='#fff' color="#000" border="#057092" placement="right">
          </Tooltip>}
          <button data-testid="checkAnswer_btn" data-tip data-for={"cata"} className="btn my_btn_cls submit_button check_answ_button mt-3" disabled={button_flag} onClick={verifyAnswer} >
            CHECK ANSWER 
            </button></>}
            </> 
            }
           {!flag && purpose ===quiz_name &&  <button data-testid="quiz_submit_btn" className="btn review_button submit_button mt-3"   disabled={submit_flag}  onClick={verifyAnswer} >
           <b>SUBMIT</b>
            </button>}
          </div>
            {(purpose !==quiz_name && flag) && 
              data.length != 0 && (
              <div data-testid="peer_responses" className={`${peer_flag ? "col-lg-4 col-md-12 col-sm-12 pt-2 peer-response-top" : ""}`}>
               <BarChart labels={chart_lables} data={chart_data} dataLables={data}></BarChart>
              </div>
            )}
        </div>
      </div>
      { (purpose ==quiz_name && props.data.is_answered && quiz_review_selected == true ) &&  
        <MessageComponent msg={msg} points={[]} feedbackContent={suceess_msg} />
      }
     { ((purpose !==quiz_name &&msg!=='') && Object.keys(points_data).length != 0 )&& <MessageComponent msg={msg} points={points_data} feedbackContent={suceess_msg} />}
      <button data-testid="lesson_check_ans_btn" className="btn my_btn_cls submit_button check_answ_button mt-3 d-none" onClick={verifyAnswer} >
        CHECK ANSWER
      </button>
      <button data-testid="button_flag_sets" className="btn my_btn_cls submit_button check_answ_button mt-3 d-none" onClick={() => { setButtonFlag(false) }} >
        CHECK ANSWER
      </button>
     </div>
    </>
  )
            }