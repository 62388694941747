import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useForm } from "react-hook-form";
import axios from "../../axios-interceptor";
import { base_url, api_error_message, random_number,date_formats } from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Table } from "react-bootstrap";

const EmailManagement = () => {
    const history = useHistory();
    const [emails, setEmails] = useState([]);
    const [tabTitles, setTabTitles] = useState([{ "key": 0, "title": "Scheduled" }, { "key": 1, "title": "Sent" }]);
    const [schedule_sent_emails, setScheduleSentEmails] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [loading_flag, setLoading_flag] = useState(true)
    const [displayedEmails, setDisplayedEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadMore, setLoadMore] = useState(true);

    const createEmail = () => {
        history.push('/programmanagementdashboard/createtemail');
    };

    const fetchEmails = async (tabIndex) => {
        try {
            const response = await axios.post(base_url + "scheduled_emails/get_scheduled_emails_by_email_sent", {
                email_sent: tabIndex
            });
            const sortedRecords = response.data.data.sort((a, b) => {
                return b.created_date - a.created_date;
            });
            setScheduleSentEmails(sortedRecords);
        } catch (error) {
            if (error.response?.statusText === "Unauthorized") {
                localStorage.clear();
                history.push("/adminlogin");
                window.location.reload();
            }else if(error.message == 'Request aborted'){
                window.location.reload()
              } else {
                toast.warn(api_error_message, { id: random_number });
            }
        }
        setLoading_flag(false)
    };

    useEffect(() => {
        fetchEmails(selectedTab);
    }, []);

    const handleTabChange = (key) => {
        setSelectedTab(key);
        fetchEmails(key);
    };

    const Update_template= (list) =>{
        if(list.sla_notification_by_ids.length !=0){
            localStorage.setItem('sla_id',list.sla_id)
            history.push('/programmanagementdashboard/pslanotifications', { schedule_info : list })
        }else{
            history.push('/programmanagementdashboard/createtemail', { schedule_id : list._id })
        }
       
    }
  
    const loadMoreEmails = () => {
      setIsLoading(true);
      setTimeout(() => {
        const currentCount = displayedEmails.length;
        const nextEmails = schedule_sent_emails.slice(
          currentCount,
          currentCount + 100
        );
        setDisplayedEmails((prevEmails) => [...prevEmails, ...nextEmails]);
        setIsLoading(false);
  
        if (currentCount + 100 >= schedule_sent_emails.length) {
          setLoadMore(false);
        }
      }, 1000); 
    };
  
    useEffect(() => {
      setLoadMore(true);
  
      const loadInitialData = () => {
        setIsLoading(true);
        setTimeout(() => {
          const initialEmails = schedule_sent_emails.slice(0, 100);
          setDisplayedEmails(initialEmails);
          setIsLoading(false);
        }, 100); // Simulating a delay for loading data (adjust as needed)
      };
  
      loadInitialData();
    }, [schedule_sent_emails]);
  
    useEffect(() => {
      const handleScroll = () => {
        if (!isLoading && loadMore) {
          if (
            window.innerHeight + window.scrollY >= document.body.offsetHeight - 200
          ) {
            loadMoreEmails();
          }
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [isLoading, loadMore, displayedEmails, schedule_sent_emails]);
  
    return (
        <>
            {loading_flag ? (
                <div>
                    <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
                </div>
            ) : <>
                <div className="bg-body container email_management">
                    <div>
                        <span className="SLA_Main_heading">Emails</span>
                        <button
                            type="button"
                            className="btn my_btn_cls float-end px-4"
                            onClick={createEmail}
                        >
                            Create Email
                        </button>
                    </div>
                    <Tabs
                        id="fill-tab-example"
                        className="mt-4 my_toggle_tabs emails-tab"
                        justified="true"
                        activeKey={selectedTab}
                        onSelect={handleTabChange}
                    >
                        {tabTitles.map((tab, index) => (
                            <Tab key={tab.key} eventKey={tab.key} title={tab.title} className={selectedTab === index ? "active-tab" : ""}>
                                <Table className="border_blue email_management" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                    <thead className="fs-20">
                                        <tr className="even">
                                            <th className="pb-3 pt-3 ps-3" style={{ width: '20%' }}>Date & Time</th>
                                            <th className="pb-3 pt-3" style={{ width: '30%' }}>To</th>
                                            <th className="pb-3 pt-3" style={{ width: '50%' }}>Subject</th>
                                            <th className="pb-3 pt-3 pe-4" style={{ width: '10%' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {displayedEmails.map((email) => (
                                            <tr key={email._id} className="odd">
                                                <td className="pb-3 pt-3 ps-3" style={{ width: '20%' }}>{date_formats.human_date_time_format(email.schedule_time)}</td>
                                                <td className="pb-3 pt-3" style={{ width: '30%' }}>
                                                    {email.emails.slice(0, 2).map((obj, index) => (
                                                        <div key={index}>{obj.email}</div>
                                                    ))}
                                                    {email.emails.length > 2 && (
                                                        <div>+{email.emails.length - 2} more</div>
                                                    )}
                                                </td>
                                                <td className="pb-3 pt-3" style={{ width: '50%' }}>{email.subject}</td>
                                                <td className="fw-bold cursor-pointer pb-3 pt-3 pe-4"  onClick={() =>tab.key === 0 && Update_template(email)} style={{ width: '10%' }}>
                                                    {tab.key === 0 ? (
                                                        <>
                                                            EDIT
                                                            <i className="fa fa-arrow-right ml-2 fw-bolder" aria-hidden="true"></i>
                                                        </>
                                                    ) : (
                                                        'Success'
                                                    )}
                                                </td>
                                                
                                            </tr>
                                             
                                        ))}
                                        
                                    </tbody>
                                </Table>
                            </Tab>
                        ))}
                    </Tabs>
                </div>
            </>
            }
        </>)

};

export default EmailManagement;
