import axios from "../../axios-interceptor";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  base_url,
  date_formats,
  random_number,
  api_error_message,
} from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Table } from "react-bootstrap";
import moment from "moment";
import DataTable from "react-data-table-component";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";





export const SLAPreActivityCompletion = (props) => {
    
  const history = useHistory();
  const location = useLocation();
  let [loading_flag, setloading_flag] = useState(true);
  let [table_loader, settable_loader] = useState(false);
  let [activeProgammeList, setActiveProgammeList] = useState([]);
  const [programmes, setAllProgrammes] = useState([]);
  let [data, setData] = useState([]);
  let [message,setMessage] = useState(false);
  let [noRecordsMessage, setNoRecordsMessage] = useState(false);
  let [programs_list,setPrograms_list]=useState([])
  let [selectedProgrammeOption, setSelectedProgrammeOption] = useState([]);
  let [program_options, setprogramOptions] = useState([]);
  let [selectedOCourseOption, setSelectedOCourseOption] = useState([]);
  let [Course_options, setCourse_options] = useState([]);
  let [selectedModuleOption, setSelectedModuleOption] = useState([]);
  let [Module_options, setModule_options] = useState([]);
  let [programme_course_studentlst,setProgramme_course_studentlst]=useState([])
  let [aLLProgramme_studentlst,setALLProgramme_studentlst]=useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    reset,control,
    formState: { errors },
  } = useForm({ mode: "all"});
  const columns = [
    {
      name: "S.No",
      selector: (_, index) => index + 1,
      sortable: true,
      //grow:0,
      width:"4.4rem"
    },
    {
      name: 'Name',
      selector: row => row.first_name,
      sortable: true,
  },
  {
      name: 'Email',
      selector:'email',
      cell: row => <div className="hoverable-cell">{row.email}</div>,
      // allowOverflow:false,
    
      wrap:true,
      sortable: true,
    
  },
  {
      name: 'Group',
      selector: row => row.group_name.replace(/-/g, ' ').replace(/\s+/g, ' '),
      sortable: true,
  },
  
 
  {
    name: "Preactivity Completed",
    selector: (row) => row.pre_activity_completed,
    sortable: true,
    cell: (row) => {
      return row.pre_activity_completed === 1 ? "Completed" : "Not Completed";
    },
    conditionalCellStyles: [
      {
        when: (row) => row.pre_activity_completed === 0,
        style: {
          color: "#D00000",
          justifyContent: 'center',
        },
      },
      {
        when: (row) => row.pre_activity_completed === 1,
        style: {
          color: "#034A0E",
          justifyContent: 'center',
        },
      },
    ],
  },
  {
    name:'Access Token',
    selector: row => row.is_correct_access_token ? row.confirmation_code : row.access_token,
    sortable: true,
    conditionalCellStyles: [
      {
        when: (row) => row.is_correct_access_token
        === false,
        style: {
          color: "#D00000",
          justifyContent: 'center',
        },
      },
      {
        when: (row) => row.is_correct_access_token
        === true,
        style: {
          color: "#034A0E",
          justifyContent: 'center',
        },
      },
    ],
},
  {
    name: "Postactivity Completed",
    selector: (row) => row.post_activity_completed,
    sortable: true,
    cell: (row) => {
      return row.post_activity_completed === 1 ? "Completed" : "Not Completed";
    },
    conditionalCellStyles: [
      {
        when: (row) => row.post_activity_completed === 0,
        style: {
          color:  "#D00000",
          justifyContent: 'center',
        },
      },
      {
        when: (row) => row.post_activity_completed === 1,
        style: {
          color:"#034A0E",
          justifyContent: 'center',
        },
      },
    ],
  },
  {
      name: 'Total Score',
      selector: row => row.earned_score+"/"+row.total_score,
      sortable: true,
    
  },
  ];

  useEffect(() => {
    localStorage.removeItem("sla_id");

    axios
      .post(base_url + "admin/get_program_applications_for_review", {})
      .then((response) => {
        let programmesList=[]
        programmesList= response.data?.result;
       
        setPrograms_list(programmesList)
          setprogramOptions(
            programmesList.map(({ _id, program_display_name }) => ({ value: _id, label: program_display_name }))//program list
          );
            if(programmesList.length>0)
            {

                const lastProgram = programmesList[programmesList.length - 1];
                //const {_id,program_display_name}=programmesList[0]
               
                const optionObject={ value: lastProgram._id, label: lastProgram.program_display_name }
                handleAutoPrefills(optionObject,programmesList)
            }
            
        setloading_flag(false);
      })
      .catch((err) => {
        if (err.response?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  }, []);
  

  const handleAutoPrefills = (item,programmeList) => {
    let filteredOption=  programmeList.filter(obj=>item.value===obj._id)
    setSelectedProgrammeOption(item)
    //set courseOptions
    if(filteredOption.length>0)
    {
      setCourse_options(
        filteredOption[0]?.courses.map(({ course_id, course_title }) => ({ value: course_id, label: course_title }))
      );


    
      if(filteredOption[0].courses.length>0)
      {

        const {course_id,course_title}=filteredOption[0].courses[0];

        setSelectedOCourseOption({value:course_id,label:course_title})
        // setSelectedOCourseOption({value:"ALL",label:"ALL"})
      
        axios.post(base_url + "get_course_modules", { "course_id": course_id }).then((res) => {
       
        let ModulesList=[]
        ModulesList= res.data.data;
      
      
        setModule_options(
          ModulesList
          .filter(module => module.module_title !== "Conclusion" && module.module_title !== "Orientation")
          
          .map(

            ({ _id, module_title }) => ({ value: _id, label: module_title })
            
            )//program list
          );
      
         
          if(ModulesList.length>0)
          {
              const {_id,module_title}=ModulesList[1];
              setSelectedModuleOption({value:_id,label:module_title})
              handleGetStudentsList(item.value,course_id,_id)



          }
      
        })
     
      } 
       
    }
  };

  const handleGetStudentsList=(programmeId,courseId=undefined,moduleId=undefined)=>{
   
    settable_loader(true)
    let queryObj={}
    if(programmeId) queryObj['program_id']=programmeId
    if(courseId) queryObj['course_id']=courseId
    if(moduleId) queryObj['module_id']=moduleId
    
    //console.log(queryObj)
    axios.post(base_url+"sla/get_sla_activity_data",queryObj)
    .then(res=>{
      setProgramme_course_studentlst(res.data.result)
      setALLProgramme_studentlst(res.data.result)
      settable_loader(false)
    })
    .catch(err => {
      if (err.response?.statusText == 'Unauthorized') {
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else if(err.message == 'Request aborted'){
        window.location.reload()
      } else {
        toast.warn(api_error_message, { toastId: random_number });
      }
    }) 
  }
  const handleProgrammeChange = (item) => {
    setSearchQuery('');
    setCourse_options([]);
    setSelectedOCourseOption([])
    let programmeList=[...programs_list]
    let filteredOption=  programmeList.filter(obj=>item.value===obj._id)
    setSelectedProgrammeOption(item)
    //set courseOptions

  
    if(filteredOption.length>0)
    {
      const {course_id,course_title}=filteredOption[0].courses[0];
     
      setCourse_options(
        filteredOption[0]?.courses.map(({ course_id, course_title }) => ({ value: course_id, label: course_title }))
      );
   
         setSelectedOCourseOption({value:course_id,label:course_title})
         //setSelectedOCourseOption({value:"ALL",label:"ALL"})
      
    
        axios.post(base_url + "get_course_modules", { "course_id": course_id }).then((res) => {
        
          let ModulesList=[]
          ModulesList= res.data.data;
        
           //console.log(res.data.data);
          setModule_options(
            ModulesList
            .filter(module => module.module_title !== "Conclusion" && module.module_title !== "Orientation")
            
            .map(

              ({ _id, module_title }) => ({ value: _id, label: module_title })
              
              )//program list
            );
           
            if(ModulesList.length>0)
            {
              const {_id,module_title}=ModulesList[1];
                setSelectedModuleOption({value:_id,label:module_title})
                handleGetStudentsList(item.value,course_id,_id)

            }

            
      
        })

    }
   
       
  };
 
 
 const handleSelectCourseChange=(val)=>{
    setSearchQuery('');
    setSelectedOCourseOption(val)
    axios.post(base_url + "get_course_modules", { "course_id": val.value }).then((res) => {
     
      let ModulesList=[]
      ModulesList= res.data.data;
      
      //setModule_list(ModulesList)
   
      setModule_options(
        ModulesList
        .filter(module => module.module_title !== "Conclusion" && module.module_title !== "Orientation")
        
        .map(

          ({ _id, module_title }) => ({ value: _id, label: module_title })
          
          )//program list
        );
    

        if(ModulesList.length>0)
        {
            const {_id,module_title}=ModulesList[1];
            setSelectedModuleOption({value:_id,label:module_title})
            handleGetStudentsList(selectedProgrammeOption.value,val.value,_id)

        }
    
    })
       //get student list by programeid
    
  }

  const handleSelectModuleChange=(val)=>{
    setSearchQuery('');
    setSelectedModuleOption(val)
   
      //get student list by programeid
      handleGetStudentsList(selectedProgrammeOption.value,selectedOCourseOption.value,val.value)
  }
 

  
    return(
        <>
        <div className="bg-body container ">
        {loading_flag?<>
                <div className="mt-5">
                   <img className="atrium_loader_custom" src="/images/atrium_loader.gif" alt="Loader" />
                </div>
            </>:<>
           {message? <h3>No Records Found</h3>:<>
            <div>
               <span className="SLA_Main_heading">Active Programs & SLA's</span>
            </div>
           <>
           <div className="row mt-2 mb-3">
           <div className="col-lg-6">
            <label className="sla_activity">Select Programme</label>
            <div className="">
                        <Controller
                          as={Select}
                          name="Programmes"
                          placeholder="Programe Name"
                          options={program_options}
                         // className="Dashboard_progdrpdwn rounded"
                          control={control}
                          rules={{ required: true }}
                          render={({ onChange, value, name }) => (
                          <Select
                              onChange={handleProgrammeChange}
                              value={selectedProgrammeOption}
                              name={name}
                              options={program_options}
                              isSearchable={true}
                              classNamePrefix="custom-select-sla"
                              // Add a dummy option if program_options is empty
                              noOptionsMessage={() => "No options available"}
                            />
                          )}
                        />
                </div>
                </div>
                <div className="col-lg-6">
                <label className="sla_activity">Select Course</label>
                <div className="">
                        <Controller
                          as={Select}
                          name="Courses"
                          placeholder="Course Name"
                          options={Course_options}
                          className="Dashboard_progdrpdwn rounded"
                          control={control}
                          rules={{ required: true }}
                          render={({ onChange, value, name }) => (
                          <Select
                            onChange={handleSelectCourseChange}
                            value={selectedOCourseOption}
                            name={name}
                            options={Course_options}
                            isSearchable={true}
                            classNamePrefix="custom-select-sla"
                              // Add a dummy option if program_options is empty
                              noOptionsMessage={() => "No options available"}
                            />
                          )}
                        /> 
                </div>
                </div>
                </div>
                <div className="row  mt-2">
          
                <div className="col-lg-6">
                <label className="sla_activity">Select Module</label>
                <div className="">
                        <Controller
                          as={Select}
                          name=""
                          placeholder="Select Module"
                          options={Course_options}
                          className="Dashboard_progdrpdwn rounded"
                          control={control}
                          rules={{ required: true }}
                          render={({ onChange, value, name }) => (
                          <Select
                            onChange={handleSelectModuleChange}
                            value={selectedModuleOption}
                            name={name}
                            options={Module_options}
                            isSearchable={true}
                            classNamePrefix="custom-select-sla"
                              // Add a dummy option if program_options is empty
                              noOptionsMessage={() => "No options available"}
                            />
                          )}
                        /> 
                </div>
                </div>
            </div>
               
             <div className="mt-2">
                   
             {table_loader?
                           <>
                           <div className="mt-5">
                             <img
                               className="atrium_loader_custom"
                               src="/images/atrium_loader.gif"
                               alt="Loader"
                             />
                           </div>
                         </>
                         :
                         <>
                        <DataTable
                          responsive={true}
                          className="active_program-table-gradebook"
                          columns={columns}
                          data={programme_course_studentlst}
                          
                        />
                         </>  
                  }
             </div>
             </>
             </>}
            </>}
        </div>
        </>
    )
}