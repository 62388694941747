import { useEffect, useState, useRef, React, Fragment } from "react";
import { Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { random_number, base_url, api_error_message, admin_maintenance_type, NA_String } from "../../utilities";
import axios from "../../axios-interceptor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import { element } from "prop-types";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";

 const ScoreCard = () => {
  let [progress_details, setProgressDetails] = useState([]);
  let [lastmodule_details, setLastModuleDetails] = useState([]);
  let [loading_flag, Setloading_flag] = useState(true);
  const [exportBtn, setExportBtn]=useState(false)
  const pdfRef = useRef();
  const history = useHistory();
  useEffect(() => {
    axios
      .post(base_url + "program_manager/get_user_score_card", {
        user_id: localStorage.getItem("user_id"),
        course_id: localStorage.getItem("course_id"),
        cohort_schedule_id: localStorage.getItem("cohort_id"),
      })
      .then((res) => {
        let data=res.data.data[0]
          let courseData=data[data.course_name]
          courseData.map(singleModule=>{
                  Object.keys(singleModule).map(key => {
                      singleModule[key].map(singleItem=>{
                          Object.keys(singleItem).map(lastItemKey=>{
                              if(lastItemKey=='module score')
                              {
                                  const combinedObject = singleItem[lastItemKey].reduce((result, obj) => {
                                      const [key] = Object.keys(obj);
                                      result[key] = obj[key];
                                      return result;
                                    }, {});
                                    singleItem[lastItemKey]=combinedObject
                              }
                          })
                      })
                  })
          })
        const lastModules = res.data.data[0][
          res.data.data[0]["course_name"]
        ].map(
          (element) =>
            Object.values(element)[0][Object.values(element)[0].length - 1]
        );
        
        setLastModuleDetails(lastModules);
        res.data.data[0][res.data.data[0]["course_name"]].map((obj) => {
          const key = Object.keys(obj)[0];
          obj[key] = Object.values(obj)[0].slice(0, -1);
          return obj;
        });
        setProgressDetails(res.data.data);
        Setloading_flag(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.statusText == "Unauthorized"||err.response?.data?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        } else {
          history.push("/")
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  }, []);


  const exportPDF = async () => {
    setExportBtn(true)

      //commented for unit test cases
    const contentElement = document.getElementById('pdf_content');

    const contentWidth = contentElement.offsetWidth;
    const contentHeight = contentElement.offsetHeight;

    
   
    // Capture content as an image with higher scale for better resolution
    domtoimage.toPng(contentElement, { quality: 1, width: contentWidth * 2, height: contentHeight * 2 }).then(imgDataUrl => {
      // Create a canvas from the image
      const canvas = document.createElement('canvas');
      canvas.width = contentWidth;
      canvas.height = contentHeight;
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = imgDataUrl;
      img.onload = () => {
        
        ctx.fillStyle = '#F7F7F7'; // Replace with your desired background color
        ctx.fillRect(0, 0, contentWidth, contentHeight);

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Convert canvas to JPEG with adjustable quality
        const newImgDataUrl = canvas.toDataURL('image/jpeg', 0.9); // Higher quality

        // Create PDF
        const pdf = new jsPDF('p', 'px', [contentWidth, contentHeight]);
        pdf.addImage(newImgDataUrl, 'JPEG', 0, 0, contentWidth, contentHeight);

        // Save PDF
        pdf.save(`${progress_details[0]?.name}_${progress_details[0]?.course_name}_progress_card.pdf`);

        
        setExportBtn(false)

      };
    });
  };

  


 
  return (
    <>
      {loading_flag ? (
        <div>
          <img data-testid="atrium_loader"
            className="atrium_loader"
            src="/images/atrium_loader.gif"
            alt="Loader"
          />
        </div>
      ) : (
        <div className="scorecard" data-testid="score_card_main">
          <div  id="pdf_content" ref={pdfRef}>
            <div className="d-flex justify-content-between bg-white h-auto m-0 p-3 w-100 score_card_main">
              <div className="align-self-start">
                <img data-testid="logo_image"
                  className="w-25"
                  src="/images/isb_online_logo.svg"
                  alt="Logo"
                />
              </div>
              <div className="align-self-end text-end me-5">
                <p className="blue_text fw-bold fs-4 mb-0 progress_Card_heading" data-testid="progress_Card_heading">Progress Card</p>
                <p className="blue_text fs-4 mb-0" data-testid="program_name">
                  {progress_details[0]?.program_name}
                </p>
                <p className="blue_text fs-4 mb-0" data-testid="program_start_date">
                  {progress_details[0]?.program_start_date}
                </p>
              </div>
            </div>
            <div className="lessons_table_header py-4 text-center fs-5 fw-bold score_card_course_web" data-testid="course_name">
              Course Name: {progress_details[0]?.course_name}
            </div>
            <div className="lessons_table_header py-4 text-center fs-5 fw-bold d-flex flex-column align-items-center score_card_course_mobile" data-testid="score_card_course_mobile">
                <div>
                Course Name
                  </div>
                <div>
                {progress_details[0]?.course_name}
                </div>
            </div>
            <div className="my-5">
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
                  <div className="card border-0">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="d-flex align-items-center mb-3">
                            <div className="w-30">
                              <label className="blue_text fs-18 mb-0 mobile_score_head" data-testid="user_name_title">
                                Name:
                              </label>
                            </div>
                            <div className="flex-grow-1 ml-3">
                              <span className="fw-bold mobile_score_val" data-testid="user_name">
                                {progress_details[0]?.name}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="w-30">
                              <label className="blue_text fs-18 mb-0 mobile_score_head" data-testid="user_email_title">
                                Email:
                              </label>
                            </div>
                            <div className="flex-grow-1 ml-3">
                              <span className="fw-bold mobile_score_val" data-testid="user_email">
                                {progress_details[0]?.email}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="w-30">
                              <label className="blue_text fs-18 mb-0 mobile_score_head" data-testid="lastupdate_title">
                                Last Updated:
                              </label>
                            </div>
                            <div className="flex-grow-1 ml-3">
                              <span className="fw-bold mobile_score_val" data-testid="lastupdate_activity">
                                {progress_details[0]?.last_activity}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-flex align-items-center mb-3">
                            <div className="w-50">
                              <label className="blue_text fs-18 mb-0 mobile_score_head" data-testid="user_points_title">
                                Points:
                              </label>
                            </div>
                            <div className="flex-grow-1 ml-3">
                              <span className="fw-bold mobile_score_val" data-testid="user_points">
                                {progress_details[0]?.earned_score}/{progress_details[0]?.total_score}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="w-50">
                              <label className="blue_text fs-18 mb-0 mobile_score_head" data-testid="learning_time_title">
                                Learning Time:
                              </label>
                            </div>
                            <div className="flex-grow-1 ml-3">
                              <span className="fw-bold mobile_score_val" data-testid="learning_time_course">
                                {progress_details[0]?.learning_time}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="w-50">
                              <label className="blue_text fs-18 mb-0 mobile_score_head" data-testid="badges_earned_title">
                                Badges Earned:
                              </label>
                            </div>
                            <div className="flex-grow-1 ml-3">
                              <span className="fw-bold mobile_score_val" data-testid="badges_earned">
                                {progress_details[0]?.user_badges}/{progress_details[0]?.total_badges}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="w-50">
                              <label className="blue_text fs-18 mb-0 mobile_score_head" data-testid="certificate_issued_title">
                                Certificate Issued:
                              </label>
                            </div>
                            <div className="flex-grow-1 ml-3">
                              <span className="fw-bold mobile_score_val" data-testid="certificate_issued">
                                {progress_details[0]?.certificate_issued}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" data-testid="modules_main">
                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
                  <div className="mt-5">
                    {progress_details[0][progress_details[0].course_name].map(
                      (course_details, index) => {
                        const key = Object.keys(course_details)[0];
                        return (
                          <div className="row p-2" style={{background:"White"}} key={index} >
                            <div className="col-12 col-md-8">
                              <div>
                                <p className="gradebook_module_heading score_module_head" data-testid={`module_title_${index}`}>
                                  Module {index} : {key}
                                </p>
                                <table className="table table-bordered lessons_card" data-testid={`module_table_${index}`}>
                                  <thead className="lessons_table_header" data-testid={`module_tableTitles_${index}`}>
                                    <tr>
                                      <th className="w-50">Lesson Name</th>
                                      <th className="w-25">Points</th>
                                      <th className="w-25">Score Percentage</th>
                                    </tr>
                                  </thead>
                                  <tbody className="lessons_table_body mb-2">
                                    {Object.values(course_details)[0].map(
                                      (item, index) => {
                                        const lessonKey = Object.keys(item)[0];
                                        const earnedScore =
                                          Object.values(item)[0][0][
                                            "earned score"
                                          ];
                                        const totalScore =
                                          Object.values(item)[0][1][
                                            "total score"
                                          ];
                                        const scoredPercentage =
                                          Object.values(item)[0][2][
                                            "scored_percentage"
                                          ];

                                        let scorePercentageClass = "";
                                        if (
                                          scoredPercentage === 0 ||
                                          scoredPercentage === null
                                        ) {
                                          scorePercentageClass =
                                            "score-percentage-0";
                                        } else if (
                                          scoredPercentage > 0 &&
                                          scoredPercentage < 75
                                        ) {
                                          scorePercentageClass =
                                            "score-percentage-below-75";
                                        } else {
                                          scorePercentageClass =
                                            "score-percentage-above-75";
                                        }

                                        return (
                                          <tr key={index} data-testid={`module_tableData_${index}`}>
                                            <td className="w-50">
                                              {lessonKey}
                                            </td>
                                            <td className="w-25">
                                            {totalScore ? earnedScore + '/' :''}{totalScore ? totalScore : ''}
                                            </td>
                                            <td
                                              className={`w-25 ${scorePercentageClass}`} data-testid={`score_percentage_${index}`}
                                            >
                                              {scoredPercentage === 0 ||
                                              scoredPercentage === null ? (
                                                <>
                                                  <img style={{ width: "15%" }}
                                                    src="images/score_percentage_0.svg"
                                                    alt="No Score"
                                                  />
                                                </>
                                              ) : scoredPercentage > 0 &&
                                                scoredPercentage < 75 ? (
                                                <>
                                                  <img style={{ width: "15%" }}
                                                    src="images/score_percentage_below_75.svg"
                                                    alt="No Score"
                                                  />
                                                  &nbsp;&nbsp;&nbsp;
                                                  {scoredPercentage}%
                                                </>
                                              ) : (
                                                <>
                                                  <img style={{ width: "15%" }}
                                                    src="images/score_percentage_above_75.svg"
                                                    alt="No Score"
                                                  />
                                                  &nbsp;&nbsp;&nbsp;
                                                  {scoredPercentage}%
                                                </>
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-12 col-md-4">
                              <div>
                                <p className="invisible">Score cards</p>
                                <Table
                                  bordered={false}
                                  className="lessons_card" data-testid={`module_grades_table_${index}`}
                                >
                                  <thead className="lessons_table_header" data-testid="module_Grade">
                                    <tr>
                                      <th className="border-bottom-0">
                                        Module Grade
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="modulegrade_table_body" data-testid={`module_grades_body`}>
                                    <tr>
                                      <th>
                                        <div className="row" data-testid="max_score">
                                          <div className="col-8">
                                            <span>
                                              Max Score
                                            </span>
                                          </div>
                                          <div className="col-4">
                                            <span>
                                              {
                                                lastmodule_details[index]["module score"]["earned score"] === 0 && 
                                                lastmodule_details[index]["module score"]["total score"] === 0 
                                                ? NA_String : 
                                                `${lastmodule_details[index]["module score"]["earned score"]}/${lastmodule_details[index]["module score"]["total score"]}`
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <th>
                                        <div className="row" data-testid="scored_percentage">
                                          <div className="col-8">
                                            <span>
                                              Scored Percentage
                                            </span>
                                          </div>
                                          <div className="col-4">
                                          <span className="blue_text">

                                          {
  lastmodule_details[index]["module score"]["score percentage"] !== null ? (
    lastmodule_details[index]["module score"]["earned score"] === 0 && 
    lastmodule_details[index]["module score"]["total score"] === 0 &&
    lastmodule_details[index]["module score"]["score percentage"] === 0 ?
    NA_String : `${lastmodule_details[index]["module score"]["score percentage"]}%`
  ) : NA_String
}
 
</span>
 


                                          </div>
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <th>
                                        <div className="row" data-testid="module_completed">
                                          <div className="col-8">
                                            <span>
                                              Module Completed
                                            </span>
                                          </div>
                                          <div className="col-4">
                                            <span
                                              className={` ${
                                                lastmodule_details[index][
                                                  "module score"
                                                ]["module completed"] === "No"
                                                  ? "red"
                                                  : ""
                                              }`}
                                            >
                                              {
                                                lastmodule_details[index][
                                                  "module score"
                                                ]["module completed"]
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </th>
                                    </tr>
                                    {lastmodule_details[index]["module score"]["onTime completed"] && <tr>
                                      <th>
                                        <div className="row" data-testid="on_Time_completed">
                                          <div className="col-8">
                                            <span>
                                              On Time Completion
                                            </span>
                                          </div>
                                          <div className="col-4">
                                            <span
                                              className={` ${
                                                lastmodule_details[index][
                                                  "module score"
                                                ]["onTime completed"] === "No"
                                                  ? "red"
                                                  : ""
                                              }`}
                                            >
                                              {
                                                lastmodule_details[index][
                                                  "module score"
                                                ]["onTime completed"]
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </th>
                                    </tr>}
                                    <tr>
                                      <th>
                                        <div className="row" data-testid="learning_time">
                                          <div className="col-8">
                                            <span>
                                              Learning Time
                                            </span>
                                          </div>
                                          <div className="col-4">
                                            <span className=" blue_text">
                                              {
                                                lastmodule_details[index][
                                                  "module score"
                                                ]["time spent on module"]
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <th>
                                        <div className="row" data-testid="badge_awarded">
                                          <div className="col-8">
                                            <span>
                                              Badge Awarded
                                            </span>
                                          </div>
                                          <div className="col-4">
                                            <span
                                              className={` ${
                                                lastmodule_details[index][
                                                  "module score"
                                                ]["badge awarded"] === "No" &&
                                                index !== 0 &&
                                                index !==
                                                  lastmodule_details.length - 1
                                                  ? "red"
                                                  : ""
                                              }`}
                                            >
                                              {index === 0 ||
                                              index ===
                                                lastmodule_details.length - 1
                                                ? "NA"
                                                : lastmodule_details[index][
                                                    "module score"
                                                  ]["badge awarded"]}
                                            </span>
                                          </div>
                                        </div>
                                      </th>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row exclude-from-pdf">
            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
              <div className="my-5 d-flex justify-content-between">
                <div className="align-self-start score_Card_back_mobile">
                  <button
                    className="btn my_outline_button_cls submit_button mr-4 my_certificate_btn file_upload_buttons px-4"
                    onClick={() => history.push("/modulepage")} data-testid="back_btn"
                  >
                    Back
                  </button>
                </div>
                <div className="align-self-end score_Card_export_mobile">
                  <button
                    className="btn submit_button fw-bold my_certificate_btn m-0 my_btn_cls top-0 end-0 my_btn_cl2 lesson_page_outline px-4"
                    onClick={exportPDF} data-testid="export_pdf_btn"
                    disabled={exportBtn}
                  >
                    Export PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withMaintenanceCheck(ScoreCard,admin_maintenance_type)
