import axios from "../../axios-interceptor";
import moment from "moment";
import { useState,useEffect } from "react"
import { base_url,api_error_message,random_number, quiz_name, learn_by_doing, check_point, did_i_get_this } from "../../utilities";
import { BarChart } from "./barchart";
import { MessageComponent } from "./messageComponent";
import { useRef,useLayoutEffect} from "react";
import Tooltip from "react-simple-tooltip";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
export const ActivityElementMultipleChoice = (props) => {
  let text = '';
  const history = useHistory();
  let user_ans = []
  let [value,setValue] = useState()
  let [value_text,setValueText] = useState()
  let rule_value =props.data.content.authoring.parts[0].responses[0].rule.match(/{(.*)}/)[1]
  let [answer_key,setAnswer] =useState([])
  let [msg,setMsg] = useState('')
  let [submit_flag,setSubmitFlag] = useState(true)
  let [flag,setFlag] = useState(false)
  let [ele_data ,setEleData] =useState()
  let [chart_lables,setChartLables] = useState([])
  let [chart_data,setChartData] = useState([])
  let [data,setData] =useState([])
  const [activityInitiatedTime, setActivityInitiatedTime] = useState(moment());
  let [button_flag,setButtonFlag] = useState(true)
  let [option_flag,setOptionFlag] =useState(false)
  let [points_data,setPointsData] = useState({})
  let [peer_flag,setPeerFlag] = useState(false)
  let title = props.title;
  let quiz_review_selected = props.quiz_review;
  let purpose = JSON.parse(localStorage.getItem('sla_time'))? quiz_name : props.purpose;
  const activity_Element_Multiple_Component=useRef(null)
  let [tooltipShow,setTooltipShow] = useState(false)
  useLayoutEffect(() => {
    quiz_review_selected  ?
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Add smooth scrolling behavior
        });
      }, 350) :
      props.i==0 ?
    setTimeout(() => {
     window.scrollTo({
       top: window.scrollY,
       left: 0,
       behavior: "smooth",
     });
   }, 350):
   setTimeout(() => {
       window.scrollTo({
      top:  activity_Element_Multiple_Component.current?.offsetTop-40,
      left: 0,
         behavior: "smooth",
       });
     }, 350)
}, []);
   
  for (const value of props.data.content.stem.content) {
    if (value?.children) {
      if(value?.type=='ol')
      {
        recursive_ol_data(value.children);
      }
      if(value?.type=='ul')
      {
        recursive_ul_data(value.children);
      }
      if(value?.type=='p')
      {
        recursive_p_data(value)
      }
      for (const value1 of value.children) {
        if (value1?.type === 'a') {
          text += `<a target= 'blank' href='${value1.href}'>${value1.children[0].text}</a>`
        }

        if (value?.type == 'h2' || value?.type == 'h1') {
          text += '<h2 class="question-title">' + (value1?.text ? value1.text : '') + '</h2>';
        }
        else if(value?.type!=='p') {
          if(value1?.text ){
            text += `<span class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
            if(value1.em){
              text += `<em class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
              text += value1?.text ? value1.text : ''
              text += '</em>';
            }else if (value1?.strong) {
              text += `<strong class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
              text += value1?.text ? value1.text : ''
              text += '</strong><br/>';
            }
            else{
              text += value1?.text ? value1.text : ''
              text += '</span>';
            }
          }
        
        }
      }
    }
  }

  function recursive_ol_commmon_data(value,str_li){
    if (value?.strong) {
      str_li += '<li class="summary_list_cls"><strong class="summary_header_cls">';
      str_li += value.text;
      str_li += '</strong>';
    }else{
      str_li += '<li class="summary_list_cls">'+value.text
    }
    return str_li;
  }
  
  function recursive_ol_data(data){
    let str_li = '<ol class="summary_uList_cls">';
    for(const value of data){   
        for(const value1 of value.children){
          if(value1?.children){
            for(const value2 of value1?.children){
              str_li = recursive_ol_commmon_data(value2,str_li);
            }
          }else{
            str_li = recursive_ol_commmon_data(value1,str_li);
          }
        }
        str_li += '</li>' 
      }
     text+=str_li+ '</ol>';
  }

  function recursive_ul_commmon_data(value,str_li){
         if (value?.strong) {
            str_li += '<strong class="summary_header_cls">';
            str_li += value.text;
            str_li += '</strong>';
          }else{
            str_li += value.text
          }
    return str_li;
  }

  function recursive_ul_data(data){
    for(const value of data){ 
      if(value?.type !== 'li'){
        recursive_ul_data(value.children)
      }else{          
        let str_li = '<ul class="summary_uList_cls"><li class="summary_list_cls">';
        for(const value1 of value.children){
          if(value1?.children){
            for(const value2 of value1?.children){
              str_li = recursive_ul_commmon_data(value2,str_li);
            }
          }else{
            str_li = recursive_ul_commmon_data(value1,str_li);
          }
        }
        str_li += '</li>' 
        text +=str_li+ '</ul>';
      }
    }
  }
  function recursive_p_data(value)
  {
    text += `<span class="summary_header_cls d-block pb-4">`;
    for (const value1 of value.children) {
      if(value1?.sup)
      {
        text += '<sup>';
        text += (value1?.text ? value1.text : '')
        text += '</sup>';
      }
      else if(value1.em){
        text += `<em>`;
        text += value1?.text ? value1.text : ''
        text += '</em>';
      }
      else if (value1?.strong) {
        text +=`<strong>`;
        text += value1?.text ? value1.text : ''
        text += '</strong>';
      }
      else{
        text +=(value1?.text ? value1.text : '')
      }
    }
    text+='</span>';
  }
  text = text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
  props.data.final_text = text;
  useEffect(() => {
    if(localStorage.getItem('re_attempt')){
      props.data.is_answered = false
    }
    purpose !==quiz_name ? setButtonFlag(true) :  setButtonFlag(false)
    // setButtonFlag(false)
    if (props.data.is_answered) {
      setOptionFlag(true)
      setButtonFlag(props.data.is_answered)
      setSubmitFlag(true)
      purpose !==quiz_name ?  setFlag(props.data.is_answered) : setFlag(!props.data.is_answered)
      if(purpose ==quiz_name && quiz_review_selected == true){
        if (props.data.user_answer == rule_value) {
          setMsg('yes')
          let feedback = props.data.content.authoring.parts[0].responses[0].feedback;
            // Set the text to "That’s correct"
            feedback.content[0].children[0].text = "That’s correct";
          setAnswer(props.data.content.authoring.parts[0].responses[0].feedback.content) //[0].children[0].text
        } else {
          setMsg('no')
          let feedback = props.data.content.authoring.parts[0].responses[1].feedback;
            // Set the text to "Not quite"
            feedback.content[0].children[0].text = "Not quite";
          setAnswer(props.data.content.authoring.parts[0].responses[1].feedback.content) 
        }
      }
      props.parentCallback('false')
      const list = { ...props.data };
      list.content.choices.map(item1 => {
        item1['is_checked'] = false
        item1['color'] = "none"
      })
      let arr = list.user_answer.split(',')
      list.content.choices.map(item1 => {
        arr.map(item => {

          if (+item1.id == +item) {
            item1['is_checked'] = true

          }

        })

      })
      setEleData(list)
      if (props.data.peer_response) {
        setPeerFlag(true)
        let lable_array = []
        
        props?.data?.peer_response[0]?.labels?.map((item, index) => {
          lable_array.push(item.toString().length > 15 ? item.slice(0, 15)+ '...' : item )
        })
        const results = props?.data?.peer_response[0]?.counts?.map(element => {
          return parseInt(element.replace('%', ''))
        });
        setData(results ? results : [])
        setChartLables(lable_array)
        setChartData(props?.data?.peer_response[0]?.counts)
      }

    } else {
      setEleData(props.data)
    }
    
    const container = document.createElement('div');
    container.innerHTML = props.data.final_text;
    const h2Text = container.querySelector('h2.question-title')?.innerText?.trim();
    const text = container.querySelectorAll('.summary_header_cls');
    const liElements = document.querySelectorAll('#multiplechoice .summary_list_cls');
    const headerTexts = Array.from(text).map(element => element.textContent?.trim())
    const liTexts = Array.from(liElements).map(li => li.textContent?.trim());
    let choice_array = []
    props.data.content.choices.map(item=>{
      item.content.map(item1=>{
        choice_array.push(item1.children[0].text)
      })
    })
    const combinedText = [...headerTexts,...liTexts,'Select the correct option', ...choice_array].join('. ');
    localStorage.setItem('prev_title',h2Text)
    localStorage.setItem('prev_text',combinedText)

  }, [])
 
 
  const onChangeRadio = (e) => {
    setValue(e.target.id)
    setValueText(e.target.value);
    setSubmitFlag(false)
    // value = e.target.id
    // value_text = e.target.value
  }
  const onSubmitAnswer = () => {

    let is_correct = ''
  
    if (!button_flag) {
      
      if (value) {
        setTimeout(() => {
          window.scrollTo({
           top: window.scrollY +window.innerHeight,
           left: 0,
           block: 'end',
            behavior: "smooth",
          });
        }, 300);
        // setFlag(true)
        setTooltipShow(false)
        setSubmitFlag(true)
        setButtonFlag(true)
        setOptionFlag(true)
        if(props.data.content.authoring.targeted.length != 0){
          if (value == rule_value) {
            setMsg('yes')
            is_correct = true
            setAnswer(props.data.content.authoring.parts[0].responses[0].feedback.content) //[0].children[0].text
          }else{
            const selectedFeedback = props.data.content.authoring.parts[0].responses.find(response => {
              const rule = response.rule;
              const match = rule.match(/input like {(.*)}/);
              return match && match[1] === value;
            });
            if (selectedFeedback) {
              setMsg('no')
              is_correct = false
              setAnswer(selectedFeedback.feedback.content)
            }
          }
        }else{
          if (value == rule_value) {
            setMsg('yes')
            is_correct = true
            setAnswer(props.data.content.authoring.parts[0].responses[0].feedback.content) //[0].children[0].text
          } else {
            setMsg('no')
            is_correct = false
            setAnswer(props.data.content.authoring.parts[0].responses[1].feedback.content) //[0].children[0].text
          }
        }
      }
      else {
        is_correct = false
        setAnswer('')
        // setFlag(false)
        setSubmitFlag(true)
        setButtonFlag(false)
        setOptionFlag(false)
        setMsg('')
      }
      const activityEndTime=moment()
      var body = {
        user_id: localStorage.getItem("user_id"),
        course_id: props.data.course_id,
        module_id: props.data.module_id,
        lesson_id: props.data.lesson_id,
        cohort_schedule_id: props.data.cohort_sch_id,
        activity_id: props.data.activity_id,
        activity_entity_type: props.data.acitivity_entity_type,
        is_question: true,
        user_answer: value,
        is_correct: is_correct,
        question_type:props.data.subType,
        answer_label:value_text,
        is_quiz: purpose ==quiz_name?  true: false,
        time_spent_on_activity:activityEndTime.diff(activityInitiatedTime,'seconds'),
        activity_submitted_platform: "ISBo"
      };
      if(localStorage.getItem("user_id") && window.location.pathname.toLowerCase().includes('freelessonpage') == false&&window.location.pathname.toLowerCase().includes('freelessonbdlink') == false){
      axios
        .post(base_url + "user/progress_tracking", body)
        .then((res) => {
          highlightSelectedResponses();
          localStorage.removeItem('re_attempt')
           purpose !==quiz_name?  setPeerFlag(true) :  setPeerFlag(false)
        
          let lable_array = []
          res.data.result[0]?.labels.map((item, index) => {
            lable_array.push(item?.toString().length > 15 ? item.slice(0, 15)+ '...' : item )
          })
          const results = res.data.result[0]?.counts.map(element => {
            return parseInt(element.replace('%', ''))
          });
          if(typeof(res.data.points_break_down) == 'object'){
            setPointsData(res.data.points_break_down)
          }
          setData(results ? results : [])
          setChartLables(lable_array)
          setChartData(res.data.result[0]?.counts)
          setActivityInitiatedTime(moment())
          props.parentCallback('false');
        })
        .catch((err) => {
          console.log(err);
          props.parentCallback('');
          setButtonFlag(false)
          setOptionFlag(false);
          setSubmitFlag(false)
          if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/')
            window.location.reload();
          }else if(err.message == 'Request aborted'){
            window.location.reload()
          }else{
            toast.warn(api_error_message,{toastId:random_number});

          }
        });
      }else{
        setPointsData({"free_lesson":"1"})
        props.parentCallback('false');
        highlightSelectedResponses();
      }
    }
  }


  const highlightSelectedResponses = () => {
    ele_data.content.choices.map((item1, idx) => {
      if(+item1.id == +rule_value){
        item1['color'] = "#C9F8CF";
      }
    })
    if (value == rule_value) {
      ele_data.content.choices.map(item1 => {
        if (+item1.id == +value) {
          item1['color'] = "#C9F8CF"
        }
      })
    } else {
      ele_data.content.choices.map(item1 => {
        if (+item1.id == +value) {
          item1['color'] = "#F0CCCA"
        }
      })
    }
  }

  const reviewPeerResponse =() =>{
    setSubmitFlag(true);
    let body = {
      "activity_id": props.data.activity_id, 
      "question_type":props.data.subType
    }
    if (value) {
    axios.post(base_url + "peer_response/review_your_response", body).then((res) => {
      setFlag(true)
      setTimeout(() => {
        setTooltipShow(true)
      }, 1000);
      setButtonFlag(false);
      setSubmitFlag(false);
      purpose !==quiz_name ?  setPeerFlag(true) :  setPeerFlag(false)
      let lable_array = []
      res.data.result[0]?.labels.map((item, index) => {
        lable_array.push(item?.toString().length > 15 ? item?.slice(0, 15)+ '...' : item )
      })
      const results = res.data.result[0]?.counts?.map(element => {
        return parseInt(element.replace('%', ''))
      });
     
      setData(results ? results : [])
      setChartLables(lable_array)
      setChartData(res.data.result[0]?.counts)
    }).catch((err)=>{
      console.log(err);
      props.parentCallback("");
      setSubmitFlag(false);
      if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else if(err.message == 'Request aborted'){
        window.location.reload()
      }else{
        toast.warn(api_error_message,{toastId:random_number});
      }
    })
    }
  }
  const handleGetRadioValue=(content)=> {
    let value = '';
    content.forEach((item) => {
      const children = item.children || [];
      children.forEach((child) => {
        if (child && child.text) {
          value += child.text;
        }
      });
    });
    return value;
  }
  const renderProcessedContent=(content)=> {
    return (
      <div className="d-grid">
        {content.map(item => (
          <span key={item.id}>
            {item.children.map(child => (
              child.strong ? <b key={child.id}>{child.text}</b> : child.text
            ))}
          </span>
        ))}
      </div>
    );
  }
  const handleRadioInputChildrenLength=(content)=>{
    let childLength = 0;
    content.forEach((item) => {
        childLength=item.children.length
    });
    return childLength;
  }
  useEffect(() => {
    //To remove the gap between the paragraph and a link
    const elementsWithClass = document.getElementsByClassName('mutlipleChoice_Dynamic_content');
    // Loop through each element with the class "shortanswers"
    for (let i = 0; i < elementsWithClass.length; i++) {
      const element = elementsWithClass[i];
      // Find the anchor tag within the current element
      const anchorTag = element.querySelector('a');
      // If an anchor tag is found
      if (anchorTag) {
        // Get the previous sibling of the anchor tag
        let sibling = anchorTag.previousElementSibling;

        // Loop through all previous siblings until we reach the <span> elements
        while (sibling) {
          // Check if the current sibling is a <span> element
          if (sibling.tagName.toLowerCase() === 'span') {
            // Remove the class 'pb-4' and add 'pb-0' to the <span> element
            sibling.classList.remove('pb-4');
            sibling.classList.add('pb-0');
          }
          // Move to the previous sibling
          sibling = sibling.previousElementSibling;
        }
      }
    }

  }, [props.data.final_text,ele_data]); 
  
  return (

    <>
    <div data-testid="ActivityElementChoice_content">
      <div ref={activity_Element_Multiple_Component} className='data-component col-12 custm_shadow_cls bg-body'>
       {!JSON.parse(localStorage.getItem('sla_time')) && <div className="d-flex flex-row justify-content-between align-items-end" style={{}}>
          <strong data-testid="activty_purpose" className=" mb-0 align-items-end" style={{ letterSpacing: "1px", opacity: "50%", }}>
            {purpose == learn_by_doing ? <p className="mb-0">
              <img style={{ opacity: "50%" }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA7ElEQVRIieWVMQrCMBhGH3Vyap1FcBYcPIpCr1Do0mPoFTyFCOIJqoIO3sLiWMShqFCH/IVQaEkhmfpBaBOS99ImJNCnDIE7cHYl2AClFOuZAx9XAg+4aHDrgkSgDxeCCfAS6NKFYC/Ag9StCkKB5cC4JqiXJxB1gQdAJoNjrT1tkfwA31SwlUEn1C5qywwoUNs4MIGPpHMhg9viySRKmZRRfNTnNv2KVOsbS1tmOvsqEWrhmiSgFj6XetgF3hZdsJP3oy24LljJ8w1MXQiqYyOxCdcFJXADBq4EX2BhG64L1i7goK7LK+r67En+G2pg6MDEsykAAAAASUVORK5CYII=" />

             PRIMING ACTIVITY</p> : (purpose == check_point ? <p>CHECK POINT</p> : (purpose == quiz_name? <p>QUESTION</p> : (purpose == did_i_get_this ? <p>DID I GET THIS?</p> : '')))}
          </strong>
        </div>}
        <div className="d-flex flex-row justify-content-between align-items-end">
          {ele_data && <>
            <span data-testid="activity_textContent" className="summary_header_cls mutlipleChoice_Dynamic_content" id="multiplechoice" dangerouslySetInnerHTML={{ __html: props.data.final_text }} />
          </> }
        </div>
        
        <div className="row">
        <div data-testid="correct_option_text" className="summary_header_cls pb-0 ml-checkbox-4"> <i>Select the correct option</i></div>
          <div className= {`pt-2 ml-checkbox-4 ${peer_flag ? "col-lg-8 col-md-12 col-sm-12" : "col-12"}`}>
            {
              ele_data && ele_data.content.choices.map((item, idx) => {

                return (
                  <div data-testid={`multiple_choice_input_${idx}`} key={item.id} className="multiple_choice_div" style={{ backgroundColor: purpose !==quiz_name ?item.color: (props.data.is_answered ? item.color : 'none')}}>
                    <label className="label_style" >
                      <div className="option_choise pl-3">
                        <div className={`${handleRadioInputChildrenLength(item.content)==1?'option_label':'option_label align-items-center'}`}>
                          <input className="" type="radio" checked={item.is_checked} value={handleGetRadioValue(item.content)}
                           name={`${ele_data.id}_name`}
                           id={item.id} onChange={(e) => { onChangeRadio(e) }} disabled={option_flag} style={{ height: "40px", width: "30px", accentColor: "black" }}></input>&nbsp;
                        </div>
                        <div className="option_content ">
                          <div data-testid="input_label">{renderProcessedContent(item.content)}</div>
                        </div>

                      </div>

                    </label><br />
                  </div>)
              })
            }
           {purpose !== quiz_name && !flag && 
         
           <button data-testid="noquiz_submit" className="btn review_button submit_button mt-3"  disabled={submit_flag} onClick={reviewPeerResponse}><b>SUBMIT</b></button>}
            {flag && purpose !==quiz_name &&   <> 
          {button_flag? <button data-testid="noquiz_checkanswer" className="btn my_btn_cls submit_button check_answ_button mt-3" disabled={button_flag} onClick={onSubmitAnswer} >
          CHECK ANSWER 
          </button> :<> {tooltipShow &&  <Tooltip id="tooltip" content="You can change your response and then Check Answer" fixed={tooltipShow} arrow={10} radius={13} zIndex={1}  background='#fff' color="#000" border="#057092" placement="right">
          </Tooltip>}
          <button data-testid="tooltip_checkanswer" className="btn my_btn_cls submit_button check_answ_button  mt-3" disabled={button_flag} onClick={onSubmitAnswer} >
          CHECK ANSWER  
          </button> </>}
           </>
            
            }
             
           {!flag && purpose ===quiz_name &&  <button data-testid="quiz_submit" className="btn review_button submit_button mt-3" disabled={submit_flag} onClick={onSubmitAnswer}>
           <b>SUBMIT</b>
            </button>}
          </div>
          {( purpose !==quiz_name && flag) && 
            data?.length != 0 && (
              <div className={`${peer_flag ? "col-lg-4 col-md-12 col-sm-12 pt-2 peer-response-top" : ""}`}>
               <BarChart labels={chart_lables} data={chart_data} dataLables={data}></BarChart>
              </div>
          )}
        </div>
      </div>
      { (purpose ==quiz_name && props.data.is_answered && quiz_review_selected == true ) &&  
        <MessageComponent points={[]} msg={msg} feedbackContent={answer_key} />
      }
      { ((purpose !==quiz_name &&msg!=="") && Object.keys(points_data).length != 0 ) && <MessageComponent points={points_data} msg={msg} feedbackContent={answer_key} />}

      <button data-testid="tooltip_checkanswer" className="btn my_btn_cls submit_button check_answ_button  mt-3 d-none" onClick={onSubmitAnswer} >
          CHECK ANSWER  
      </button> 
      <button data-testid="button_flag_sets" className="btn my_btn_cls submit_button check_answ_button mt-3 d-none" onClick={() => { setButtonFlag(false) }} >
        CHECK ANSWER
      </button>
    </div>
    </>
  )
}