
import axios from "../../axios-interceptor";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { base_url,api_error_message,random_number,date_formats } from "../../utilities";
import { Table } from "react-bootstrap";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const CreateSLAs = (props)=>{
  const history = useHistory();
  let [SLAData,setSLAData]=useState([])
  let [loading_flag,SetLoading_flag]=useState(true)

    useEffect(() => {
    axios.post(base_url+'sla/get_sla_programs_list')
    .then(res=>{
        setSLAData(res.data?.data)
        SetLoading_flag(false)
    }) 
    .catch(err=>{
        console.log(err);
        if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/adminlogin')
            window.location.reload();
        }else if(err.message == 'Request aborted'){
            window.location.reload()
          }else{
            toast.warn(api_error_message,{toastId:random_number});
        }
    }) 
    }, [])

    const EditSLA=(value)=>{
        history.push('/admindashboard/editsla',{"Sla_Data":value})
    }

    return(
        <>
        <div className="bg-body container ">
            <div>
               <span className="SLA_Main_heading" data-testid="Main_Heading">Configure SLAs</span>
            </div>
            {loading_flag?
            <>
                <div className="minheight50">
                    <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
                </div>
            </>:
            <>
              <div className={SLAData.length === 0 ? 'my-5' : 'mt-5'}>
                {SLAData.length != 0 &&  <Table data-testid="Sla_Table" className="createSLA-table" bordered={false}>
                    <thead>
                    <tr>
                        <th className="fs-4">Programme Start Date</th>
                        <th className="fs-4">Track Name</th>
                        <th className="fs-4">Course Name</th>
                        <th className="fs-4">SLA</th>
                    </tr>
                    </thead>
                    <tbody>
                    {SLAData.map((item, index) => {
                        return (
                        <tr key={index} className="Create_SLA_row">
                            <td>{date_formats.human_date_format(item.start_date)}</td>
                            <td>{item.program_name}</td>
                            <td>{item.course_name}</td>
                            <td  style={{cursor:"pointer"}} >
                            <span  data-testid={`Sla_Edit_${index}`} className="text-underline" onClick={()=>EditSLA(item)}>EDIT SLA</span>
                            </td>
                        </tr>
                        );
                    })}
                    </tbody>
                </Table>
                
                }
                <div data-testid="Table_Length" className="d-none">{SLAData.length}</div>
                {SLAData.length == 0 &&  <div className=" text-center my-5" data-testid="No_Slas">
                    <img height="62px" width="62px" src="/images/create_sla_icon.svg"></img>
                    <p className="past_course_title text-center mt-1 fs-4">No SLAs</p>
                </div>} 
             </div>
            </>}
        </div>
        </>
    )
}