import axios from "../../axios-interceptor";
import { useState, useEffect } from "react";
import { CSVLink } from 'react-csv';
import { Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import {
  base_url,
  date_formats,
  random_number,
  api_error_message,
  validate_certificate_issuing_criteria_yes,
  validate_certificate_issuing_criteria_no,
  programme_certificate_authorization,
} from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Table } from "react-bootstrap";
import moment from "moment";
import DataTable from "react-data-table-component";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { convertLength } from "@mui/material/styles/cssUtils";
import { Table as BootstrapTable, OverlayTrigger, Tooltip } from "react-bootstrap";
export const GradeBookMain = (props) => {
  const history = useHistory();
  const location = useLocation();
  let [loading_flag, setloading_flag] = useState(true);
  let [table_loader, settable_loader] = useState(false);
  let [activeProgammeList, setActiveProgammeList] = useState([]);
  const [programmes, setAllProgrammes] = useState([]);
  let [data, setData] = useState([]);
  let [noRecordsMessage, setNoRecordsMessage] = useState(false);
  let [programs_list,setPrograms_list]=useState([])
  let [selectedProgrammeOption, setSelectedProgrammeOption] = useState([]);
  let [program_options, setprogramOptions] = useState([]);
  let [selectedOCourseOption, setSelectedOCourseOption] = useState([]);
  let [Course_options, setCourse_options] = useState([]);
  let [programme_course_studentlst,setProgramme_course_studentlst]=useState([])
  let [aLLProgramme_studentlst,setALLProgramme_studentlst]=useState([])
  const [searchQuery, setSearchQuery] = useState('');
  let [modal_bool,setModalBool]=useState(false);
  let [user_certificate_proceed,setUserCertificateProceed]=useState("")
  let [table_columns,setTableColumns] = useState([])
  const {
    register,
    handleSubmit,
    setValue,
    reset,control,
    formState: { errors },
  } = useForm({ mode: "all"});

  const [generate_certificate_ids, setGenerateCertificateIds] = useState({ program_id: "", cohort_schedule_id: "", user_id: "" })
const handleGenerateProgrammeCertificate =async(programId,email)=>{
  settable_loader(true)
  let body_obj = {
    "program_id": programId,
    "validate_certificate_issuing_criteria": true,
    "learner_emails_list": [email]
  }
  try {
    const response = await fetch(base_url + "admin/generate_program_certificates", {
      method: 'POST',
      headers: programme_certificate_authorization,
    body: JSON.stringify(body_obj),
    },
    );
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const jsonData = await response.json();
    if (jsonData.status === 200) {
      settable_loader(false)
      if (jsonData.message === "successfully generated certificates") {
        toast.success("Certificate successfully issued", { toastId: random_number });
        handleAutoPrefills(JSON.parse(sessionStorage.getItem('gradebook_programmeid'))??selectedProgrammeOption.value,programs_list)
      }
    }
  } catch (error) {
    settable_loader(false)
    if (error.response?.statusText == "Unauthorized" || error.response?.data?.statusText == 'Unauthorized') {
      localStorage.clear();
      history.push("/");
      window.location.reload();
    } else if(error.message == 'Request aborted'){
      window.location.reload()
    }else {
      toast.error(error.message, { toastId: random_number });
    }
  }

}
  const handleGenerateCertificate = (programId, cohortScheduleId, userId) => {
    settable_loader(true)
    let obj_folder = {
      program_id: programId,
      cohort_schedule_id: cohortScheduleId,
      user_ids: [userId],
      validate_certificate_issuing_criteria: validate_certificate_issuing_criteria_yes
    }
    axios.post(base_url + "program_manager/generate_course_certificates", obj_folder)
      .then(response => {
        settable_loader(false)
        if (response.data.status === 200) {
          if (response.data.message === "Successfully generated certificates") {
            toast.success("Certificate successfully issued", { toastId: random_number });
            handleAutoPrefills(JSON.parse(sessionStorage.getItem('gradebook_programmeid'))??selectedProgrammeOption.value,programs_list)
          }
        }
      })
      .catch(error => {
        settable_loader(false)
        if (error.response?.statusText == "Unauthorized" || error.response?.data?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        }else if(error.message == 'Request aborted'){
          window.location.reload()
        } else {
          toast.error(error.message, { toastId: random_number });
        }
      });
  };


 
  


  let columns = [
    {
      name: "S.No",
      selector: (_, index) => index + 1,
      sortable: true
      //grow:0,
    },
    {
      name: "Student Name",
      selector: (row) => row.student_name,
      sortable: true,
    },
    {
      name: "Student Email",
      selector: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${row.student_email}`}  className="dashboard_tooltip" >
              {row.student_email}
            </Tooltip>
          }
        >
          <div>
            <span>{row.student_email}</span>
          </div>
        </OverlayTrigger>
      ),
      sortable: true,
    }, 
    {
      name: "Earned Score",
      selector: (row) => row.earned_score,
      sortable: true
    },
    {
      name: "Total Score",
      selector: (row) => row.total_score,
      sortable: true
    },
    {
      name: "Total Badges",
      selector: (row) => {
        return (
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <div>{row.total_badges}</div>
            <>
              <div className="isb_badge_gradebook">
                <div className="isb_badge_text">
                  <p className="text-center mb-2 white_color">{"fbhfh"}</p>
                </div>
              </div>
            </>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: 'Scored Percentage',
      selector: (row) => {
        const color = parseInt(row.score_percentage) < 50 ? '#D00000' :
                      parseInt(row.score_percentage) > 75 ? '#034A0E' :
                      parseInt(row.score_percentage) > 50 ? '#D1A13D' : '';
  
        const gap = row.score_percentage.length === 4 ? '10px' :
                    row.score_percentage.length === 3 ? '15px' :
                    '23px';
  
        return (
          <div style={{ display: 'flex', gap: gap, color: color }}>
            <div><i className="fa-solid fa-diamond"></i></div>
            <div>{row.score_percentage}</div>
          </div>
        );
      },
      sortable: true,
    },
    {
      name:'Issue',
      selector: <></>,
      sortable: true
    },
    {
      name: 'Action',
      cell: (row) => <button className="btn my_btn_cls mt-0  gradebook_view_btn rounded" onClick={(e)=>handleViewStudentData(row)}  data-testid="view-button">view</button>,
      sortable: true// Set the button property to true if you want to enable sorting and filtering on the column
    },
  ];  

  useEffect(() => {
    localStorage.removeItem("sla_id");
    axios
      .post(base_url + "admin/get_program_applications_for_review", {})
      .then((response) => {
        let programmesList=[]
        programmesList= response.data?.result;
        programmesList.map(item=>{
          if(item.courses.length>0)
          {
            const option={course_id:"Programme_Level_View",course_title:"Programme Level View"}
            item.courses.unshift(option)
          }
        })
        setPrograms_list(programmesList)
          setprogramOptions(
            programmesList.map(({ _id, program_display_name }) => ({ value: _id, label: program_display_name }))
          );
            if(programmesList.length>0)
            {
                const {_id,program_display_name}=programmesList[0]
                const optionObject={ value: _id, label: program_display_name }
                handleAutoPrefills(optionObject,programmesList)
            }
            
        setloading_flag(false);
      })
      .catch((err) => {
        if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        } else if(err.message == 'Request aborted'){
          window.location.reload()
        }else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  }, []);

  const handleViewStudentData=(row)=>{
    history.push('/gradebookindividual',{"studentData":row})
  }

  const handleAutoPrefills = (item,programmeList) => {
    let programme_id =JSON.parse(sessionStorage.getItem('gradebook_programmeid'))
    let course_id = JSON.parse(sessionStorage.getItem('gradebook_courseid'))
    let course_object ={value:"Programme_Level_View",label:"Programme Level View"}
    if(programme_id && JSON.stringify(course_id) !== JSON.stringify(course_object)){
      let filteredOption=  programmeList.filter(obj=>programme_id.value===obj._id)
      setSelectedProgrammeOption(programme_id)
      setSelectedOCourseOption(course_id)
      //set courseOptions
      if(filteredOption.length>0)
      {
        setCourse_options(
          filteredOption[0]?.courses.map(({ course_id, course_title }) => ({ value: course_id, label: course_title }))
        );
        //get student list by programeid
        handleGetStudentsList(programme_id.value,course_id.value)
      }
    }else if(programme_id && JSON.stringify(course_id) === JSON.stringify(course_object)){
      let filteredOption=  programmeList.filter(obj=>programme_id.value===obj._id)
      setSelectedProgrammeOption(programme_id)
      //set courseOptions
      if(filteredOption.length>0)
      {
        setCourse_options(
          filteredOption[0]?.courses.map(({ course_id, course_title }) => ({ value: course_id, label: course_title }))
        );
        if(filteredOption[0].courses.length>0)
        {
          setSelectedOCourseOption({value:"Programme_Level_View",label:"Programme Level View"})
         
        }
  
      }
      handleGetStudentsList(programme_id.value)
    }
    else{
      let filteredOption=  programmeList.filter(obj=>item.value===obj._id)
      setSelectedProgrammeOption(item)
      if(filteredOption.length>0)
      {
        setCourse_options(
          filteredOption[0]?.courses.map(({ course_id, course_title }) => ({ value: course_id, label: course_title }))
        );
  
        if(filteredOption[0].courses.length>0)
        {
          setSelectedOCourseOption({value:"Programme_Level_View",label:"Programme Level View"})
         
        }
        sessionStorage.setItem('gradebook_programmeid',JSON.stringify(item))
        sessionStorage.setItem('gradebook_courseid',JSON.stringify({value:"Programme_Level_View",label:"Programme Level View"}))
        //get student list by programeid
        handleGetStudentsList(item.value)
      }
    }
    
  };
 useEffect(()=>{
  let course_certificate =  {
    name: "Course Certificates",
    selector: (row) =>  row.course_certificates ,
    sortable: true
  }
  let programme_certificate = {
    name: "Programme Certificate",
    selector: (row) => row.programme_certificate,
    sortable: true,
    conditionalCellStyles: [
      {
        when: row => row.programme_certificate=="Yes",
        style: {
          color: 'green',
          justifyContent: 'center'
          // Additional styles...
        }
      },
      {
        when: row => row.programme_certificate=="No",
        style: {
          color: 'red',
          justifyContent: 'center'
          // Additional styles...
        }
      }
    ]
  }
  if( selectedOCourseOption['value'] === "Programme_Level_View"){
    columns.splice(6,0,course_certificate)
    columns.splice(8,0,programme_certificate)
    columns.pop()
  }else{
    let columns = [...table_columns]
    columns.splice(6,1)
    columns.splice(8,1)
  }
  columns.map(item=>{
    if(item['name'] == 'Issue'){
      selectedOCourseOption['value'] === "Programme_Level_View"?item['name'] ="Issue Prog Certificate":item['name'] ="Issue Course Certificate"
      selectedOCourseOption['value'] === "Programme_Level_View"?item['selector'] =(row) => {
        if (selectedOCourseOption['value'] !== "") {
          return (
            row.programme_certificate === "Yes" ? <span>Issued</span> : calculateProgrammeCertificate(row.course_certificates)?<p className="pb-0 mb-0">Issue</p>:<span data-testid="issue_programme_certificate" onClick={(e)=>handleGenerateProgrammeCertificate(row.program_id,row.student_email)} className="text-decoration-underline cursor-pointer text-primary">Issue</span>
          )
        }
      }:
      item['selector'] =(row) => {
        if (selectedOCourseOption['value'] !== "") {
          return (
            row.course_certificate === "Yes" ? <span>Issued</span> :(row.score_percentage.includes('%')?parseInt(row.score_percentage.split('%')[0]):parseInt(row.score_percentage)) < parseInt(row.course_certificate_issuing_percentage)?<p className="pb-0 mb-0">Issue</p>:<span data-testid="issue_testid" onClick={(e)=>handleGenerateCertificate(row.program_id,row.cohort_schedule_id,row.user_id)} className="text-decoration-underline cursor-pointer text-primary">Issue</span>
)
        }
      }
    }
   })
   setTableColumns(columns)

 },[selectedOCourseOption])
 const calculateProgrammeCertificate =(course_certificates)=>{
  let [numeratorStr, denominatorStr] = course_certificates.split("/");
  let numerator = parseInt(numeratorStr);
  let denominator = parseInt(denominatorStr);
  if(numerator == denominator) {
    return false
  }else{
    return true
  }
 }
  const handleGetStudentsList=(programmeId,courseId=undefined)=>{
    settable_loader(true)
    let queryObj={}
    if(programmeId) queryObj['program_id']=programmeId
    if(courseId&&courseId!=="Programme Level View") queryObj['course_id']=courseId
    axios.post(base_url+"program_manager/get_users_program_grade_book_data",queryObj)
    .then(res=>{
      res.data.data.filter(item=>{
        delete item.course_name
      })
      setProgramme_course_studentlst(res.data.data)
      setALLProgramme_studentlst(res.data.data)
      settable_loader(false)
    })
    .catch(err => {
      if (err.response?.statusText == 'Unauthorized' ||  err.response?.data?.statusText == 'Unauthorized') {
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else if(err.message == 'Request aborted'){
        window.location.reload()
      } else {
        toast.warn(api_error_message, { toastId: random_number });
      }
    }) 
  }
  const handleProgrammeChange = (item) => {
    let val = {value:"Programme_Level_View",label:"Programme Level View"}
    let Obj_check =  Object.values(item).some(value => {
      if (typeof value === 'object') {
        return value instanceof HTMLElement 
      }
      return false;
    })
    if(!Obj_check){
      sessionStorage.setItem('gradebook_programmeid',JSON.stringify(item))
      sessionStorage.setItem('gradebook_courseid',JSON.stringify(val))
    }
    setSearchQuery('');
    setCourse_options([]);
    setSelectedOCourseOption([])
    let programmeList=[...programs_list]
    let filteredOption=  programmeList.filter(obj=>item.value===obj._id)
    setSelectedProgrammeOption(item)
    //set courseOptions
   
    if(filteredOption.length>0)
    {
      setCourse_options(
        filteredOption[0]?.courses.map(({ course_id, course_title }) => ({ value: course_id, label: course_title }))
      );

      if(filteredOption[0].courses.length>0)
         setSelectedOCourseOption({value:"Programme_Level_View",label:"Programme Level View"})
    }

     //get student list by programeid
     handleGetStudentsList(item.value)
  };

  let serialNo = 1; // Variable to keep track of serial number
  
  
  const csvData = aLLProgramme_studentlst.map((row) => {
    const rowData = {
      'S.No': serialNo++,
      'Student Name': row.student_name,
      'Student Email': row.student_email,
      'Earned Score': row.earned_score,
      'Total Score': row.total_score,
      'Scored Percentage': row.score_percentage,
      'Total Badges': row.total_badges,
    };
  
    if (selectedOCourseOption['value'] === "Programme_Level_View") {
      rowData['Programme Certificate'] = row.programme_certificate;
      rowData['Course Certificates'] = '\u00A0' + (row.course_certificates).toString();
    } else {
      rowData['Course Name'] = row.course_name;
      rowData['Course Certificates'] = row.course_certificate;
    }
    return rowData;
  });
  


  const handleSelectCourseChange=(val)=>{
   let Obj_check =  Object.values(val).some(value => {
      if (typeof value === 'object') {
        return value instanceof HTMLElement 
      }
      return false;
    })
    if(!Obj_check){
    sessionStorage.setItem('gradebook_programmeid',JSON.stringify(selectedProgrammeOption))
    sessionStorage.setItem('gradebook_courseid',JSON.stringify(val))
    }
    setSearchQuery('');
    setSelectedOCourseOption(val)
    if (val.value === "Programme_Level_View"){
      //get student list by programeid
       handleGetStudentsList(selectedProgrammeOption.value)
    }else{
       //get student list by programeid and course id
       handleGetStudentsList(selectedProgrammeOption.value,val.value)
    }
     
      
       
   }
 

  const handleTableSearch=(e)=>{
    let value=e.target.value
    setSearchQuery(value);
    const allList=[...aLLProgramme_studentlst]
    let filteredTablelist = allList.filter((rec) =>{
      return rec.student_name?.toLowerCase().indexOf(value.toLowerCase()) !== -1
    });
    setProgramme_course_studentlst(filteredTablelist)
}

  return (
    <>
      <div className="bg-body container ">
        {loading_flag ? (
          <>
            <div className="mt-5">
              <img
                className="atrium_loader_custom"
                src="/images/atrium_loader.gif"
                alt="Loader"
              />
            </div>
          </>
        ) : (
          <>
            {noRecordsMessage ? (
              <h3>No Records Found</h3>
            ) : (
              <>
                <>
                  <div className="row mt-2">
                    <div className="col-lg-6">
                      <div className="">
                        <Controller
                          as={Select}
                          name="Programmes"
                          placeholder="Programe Name"
                          options={program_options}
                          className="Dashboard_progdrpdwn rounded"
                          control={control}
                          rules={{ required: true }}
                          render={({ onChange, value, name }) => (
                          <Select
                              onChange={handleProgrammeChange}
                              value={selectedProgrammeOption}
                              name={name}
                              options={program_options}
                              isSearchable={true}
                              classNamePrefix="custom-select-gradebook"
                              // Add a dummy option if program_options is empty
                              noOptionsMessage={() => "No options available"}
                            />
                          )}
                        />
                        <select
                          id="Programmes"
                          aria-label=".form-select example"
                          name="Programmes"
                          data-testid="Programme_Dropdown"
                          className="form-select d-none"
                          role="presentation" autoComplete="off"
                          onChange={handleProgrammeChange}>
                          {program_options.map((item,index) => {
                            return <option  key={index} value={item.value} >{item.label}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="d-flex gap-3 justify-content-end">
                        <div className="">
                          <div className="input-container">
                            <input value={searchQuery}  onChange={handleTableSearch}
                            className="form-control custm_input_search rounded"
                            type="text"
                            placeholder="Enter Student Name"
                            aria-label="Search"
                            autoFocus
                            data-testid="search-input"
                          />
                          <i className="fa-solid fa-magnifying-glass fs-sm  mr-2 mt-2 ml-1"></i> 
                          </div>
                          </div>
                          <div >
                          <CSVLink data={csvData}  filename={`${selectedProgrammeOption.label}_gradebook.csv`}>
                            <button className="btn my_btn_cls mt-0  download_exportCSV rounded"  >Export CSV</button>
                          </CSVLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-6">
                      <div className="">
                        <Controller
                          as={Select}
                          name="Courses"
                          placeholder="Course Name"
                          options={Course_options}
                          className="Dashboard_progdrpdwn rounded"
                          control={control}
                          rules={{ required: true }}
                          render={({ onChange, value, name }) => (
                          <Select
                            onChange={handleSelectCourseChange}
                            value={selectedOCourseOption}
                            name={name}
                            options={Course_options}
                            isSearchable={true}
                            classNamePrefix="custom-select-gradebook-course"
                              // Add a dummy option if program_options is empty
                              noOptionsMessage={() => "No options available"}
                            />
                          )}
                        /> 
                        <select
                          id="Courses"
                          aria-label=".form-select example"
                          name="Courses"
                          data-testid="Course_Dropdown"
                          className="form-select  d-none"
                          role="presentation" autoComplete="off"
                          onChange={handleSelectCourseChange}>
                          {Course_options.map((item,index) => {
                            return <option  key={index} value={item.value} >{item.label}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5">
                    {table_loader?
                           <>
                           <div className="mt-5">
                             <img
                               className="atrium_loader_custom"
                               src="/images/atrium_loader.gif"
                               alt="Loader"
                             />
                           </div>
                         </>
                         :
                         <>
                         <div data-testid="Active_Gradebook_Table" >
                          <div className="d-none" data-testid="Table_Rows_Count">{programme_course_studentlst.length}</div>
                          <DataTable
                            responsive={true}
                            className="active_program-table-gradebook"
                            columns={table_columns}
                            data={programme_course_studentlst}
                          />
                        </div>
                         </>  
                  }
                  </div>
                </>
              </>
            )}
          </>
        )}       
        
      </div>
    </>
  );
};



