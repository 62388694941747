import { useEffect, useState } from "react";
import { useLayoutEffect, useRef } from "react";
import axios from "../../axios-interceptor";
import { base_url, random_number, api_error_message } from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
export const ActivityInput = (props) => {
  const history = useHistory();
  let [sla_code, setSlaCode] = useState("");
  let [button_flag, setButtonFlag] = useState(true);
  let [option_flag, setOptionFlag] = useState(false);
  var [flag, setFlag] = useState(false);
  const activity_input = useRef(null);
  useLayoutEffect(() => {
    props.i == 0
      ? setTimeout(() => {
          window.scrollTo({
            top: window.scrollY,
            left: 0,
            behavior: "smooth",
          });
        }, 350)
      : setTimeout(() => {
          window.scrollTo({
            top: activity_input.current.offsetTop - 40,
            left: 0,
            behavior: "smooth",
          });
        }, 350);
  }, []);
  useEffect(() => {
    if (props.data.is_answered) {
      setButtonFlag(props.data.is_answered);
      axios
        .post(base_url + "sla/verify_sla_confirmation_code", {
          sla_id: localStorage.getItem("sla_id"),
          user_id: localStorage.getItem("user_id"),
          confirmation_code: props.data.user_answer,
          activity_id: props.data.activity_id,
        })
        .then((res) => {
          if (res.data.status == 200) {
            localStorage.removeItem("confirmation_code");
            setOptionFlag(true);
            setFlag(true);
            window.scrollTo(0, document.body.scrollHeight);
          }
        })
        .catch((err) => {
          if (err.response?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/");
            window.location.reload();
          }else if(err.message == 'Request aborted'){
            window.location.reload()
          } else if (err.response.data.status == 400) {
            if (err.response.data.message) {
              localStorage.setItem("confirmation_code", true);
            } else {
              toast.warn(api_error_message, { toastId: random_number });
            }
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
          setOptionFlag(false);
          setFlag(false);
        });
      props.parentCallback("false");
      setSlaCode(props.data.user_answer);
    }
  }, [props.data]);
  let text = "",
    images = [];
  for (const value of props.data.content.stem.content) {
    if (value?.children) {
      for (const value1 of value.children) {
        if (value.type === "p") {
          text += `<span class="summary_header_cls ${
            value.text !== "" ? "d-inline-block pb-4" : ""
          }">`;
          text += value1?.text ? value1.text : "";
          text += "</span><br/>";
        }
      }
    }
  }
  props.data.final_text = text;
  const verifyCode = () => {
    var body = {
      user_id: localStorage.getItem("user_id"),
      course_id: props.data.course_id,
      module_id: props.data.module_id,
      lesson_id: props.data.lesson_id,
      cohort_schedule_id: props.data.cohort_sch_id,
      activity_id: props.data.activity_id,
      activity_entity_type: props.data.acitivity_entity_type,
      is_question: true,
      user_answer: sla_code,
      is_correct: sla_code ? true : false,
      is_quiz: false,
      question_type: props.data.subType,
      answer_label: "Test",
      time_spent_on_activity: 0,
      is_access_token : true,
      activity_submitted_platform: "ISBo"
    };
    if(window.location.pathname.toLowerCase().includes('freelessonbdlink') == false)
    {
      axios
      .post(base_url + "user/progress_tracking", body)
      .then((res) => {
        props.parentCallback("false");
        codeChecking(sla_code);
      })
      .catch((err) => {
        console.log(err);
        props.parentCallback("");
        if (err.response?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        } else {
          toast.warn(api_error_message,{toastId:random_number});
        }
      });
    }
  };
  const codeChecking = (code) => {
    axios
      .post(base_url + "sla/verify_sla_confirmation_code", {
        sla_id: localStorage.getItem("sla_id"),
        user_id: localStorage.getItem("user_id"),
        confirmation_code: code,
        activity_id: props.data.activity_id,
      })
      .then((res) => {
        if (res.data.status == 200) {
          localStorage.removeItem("confirmation_code");
          setOptionFlag(true);
          setFlag(true);
          toast.success(res.data.message, { toastId: random_number });
        }
      })
      .catch((err) => {
        if (err.response?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        } else if(err.message == 'Request aborted'){
          window.location.reload()
        }else if (err.response.data.status == 400) {
          if (err.response.data.message) {
            localStorage.setItem("confirmation_code", true);
            toast.error(err.response.data.message, { toastId: random_number });
          } else {
            props.parentCallback("");
            toast.warn(api_error_message,{toastId:random_number});
          }
        } else {
          props.parentCallback("");
          toast.warn(api_error_message,{toastId:random_number});
        }
      });
  };
  const onEnterPassword = (e) => {
    setSlaCode(e.target.value);
  };
  return (
    <>
      <div data-testid="ActivityInput_content" ref={activity_input} className="col-12 p-0 custm_shadow_cls bg-body">
        <div className="row data-component">
          <div className="d-flex flex-row justify-content-between align-items-end">
            <div>
           <h2>Activity Access Token</h2>
           <span className="summary_header_cls">Enter the Activity Access Token received from the Programme Manager during the group interaction on ISB Town.</span>
         &nbsp; <span className="summary_header_cls"><i>If you face any issues logging in, please contact the Programme Manager.</i></span>
          </div>
          </div>
          <div className="col-12 pt-2">
            <div className="d-flex flex-column ">
              <input
                type="password"
                disabled={option_flag}
                onChange={(e) => onEnterPassword(e)}
                value={sla_code}
                autoFocus={true}
                placeholder="Type your Activity Access Token "
                id="text-area"
                className="form-control"
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              ></input>
              <div className="row">
                <div className="col-md-6 col-lg-6 col-sm-12">
                  <button
                    className="btn review_button submit_button mt-4"
                    disabled={flag || sla_code == ''}
                    onClick={verifyCode}
                  >
                    <b>SUBMIT</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
