import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import axios from "../../axios-interceptor";
import { base_url,api_error_message,random_number, admin_maintenance_type } from '../../utilities';
import {cohortAddValidations} from '../../formValidationsRules'
import { Container } from "react-bootstrap";
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from '../../HigherOrderComponents/maintenancePageChecker';

function CohortsAdd(props) {

    const[courseList,setCourseList]=useState([])
    let [courseOption, setcourseOption] = useState(null)
    var [timer_flag, setTimeFlag] = useState(true);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({ "mode": "all" });
    const history=useHistory()

    if(!localStorage.getItem('Admin_Acesstoken'))
    {
      history.push('/adminlogin')
    }
    
    const addCohort=(data) =>{
        let svobj={
            "course_id":  data.Course, //"637500ec93da23ae39b68ee9",
            "start_date": moment(data.StartDate).valueOf(),  //1666594720455,
            "end_date":   moment(data.EndDate).valueOf()  ,    //"1666594720455",
            "available_date":moment(data.AvailableDate).valueOf(),      //1666594720455,
            "is_enabled": true
        }
        const url = base_url + 'cohort/create_cohort_schedule';
        axios.post(url, svobj)
        .then((response) => {
            alert(response?.data?.message)
            props.handleCohortSave()
            // history.push('/courselearningtrackList')
        })
        .catch(err=>{
            console.log(err);
            if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
                localStorage.clear();
                history.push('/adminlogin')
                window.location.reload();
            }else if(err.message == 'Request aborted'){
                window.location.reload()
              }else{
              toast.warn(api_error_message,{toastId:random_number});
            }
        })
    }

    useEffect(()=>{
        axios
          .post(base_url + "courses/get_all_courses",)
          .then((res) => {
            setCourseList(res.data.courses);
          })
          .catch((err) => {
            console.log(err);
            if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
                localStorage.clear();
                history.push('/adminlogin')
                window.location.reload();
            }else if(err.message == 'Request aborted'){
                window.location.reload()
              }else{
              toast.warn(api_error_message,{toastId:random_number});
            }
          });
      },[])
    

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        if (name == 'Course') {
            if (value !== "") {
                courseList.map(obj => {
                    if (value == obj._id) {
                        setcourseOption(obj)
                    }
                })
                // setValue('pronoun', value, { shouldDirty: true })
            }
        }
    }
    return ( <>
    
    <div className="row mt-3">
    <Container className='  pb-0 d-flex flex-row justify-content-between'>
            <div className="mt-4" style={{marginLeft:"190px"}} ><h3 className='admin-tabs-heading'>Cohorts</h3></div>
     </Container>                   
              <div class="tab-content col-lg-2">
                <span className='visible-hidden'>dsd</span>
              </div>
                <div class="tab-content col-lg-8">
                    <div class="tab-pane fade show active " id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="custm_shadow_cls form_field_container_cls">

                            <form >
                                <div className="row align-items-center flex-column">
                                    <div className="col-lg-6">
                                        <label htmlFor="exampleInput"> Select Course <span className='form-label-color'>*</span></label>
                                        <select {...register("Course", cohortAddValidations.courseName)}
                                            id="Course"
                                            name="Course"
                                            data-testid="cohorts_Dropdown"
                                            className="form-select"
                                            role="presentation" autocomplete="off"
                                            // value={LearningTracks}
                                            onChange={handleChange}>
                                            <option value=''>Select</option>
                                            {courseList.map((item, index) => {
                                                return <option key={index} value={item.id} >{item.title}</option>;
                                            })}
                                        </select>
                                        {errors.Course?.message && <span className='form-label-color'>{errors.Course?.message}</span>}
                                    </div>
                                
                                    <div className=" col-lg-6 mt-3">
                                        <label htmlFor="exampleInput">Start Date <span className='form-label-color'>*</span></label>
                                            <input {...register("StartDate", cohortAddValidations.start_date)}
                                            type="Date"
                                            name="StartDate"
                                            id="StartDate"
                                            data-testid="cohorts_start_date"
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="  "
                                            required
                                            role="presentation" autocomplete="off"
                                            />
                                            {errors.StartDate?.message && <span className='form-label-color'>{errors.StartDate?.message}</span>}
                                   </div>

                                   <div className=" col-lg-6 mt-3">
                                        <label htmlFor="exampleInput">End Date <span className='form-label-color'>*</span></label>
                                            <input {...register("EndDate", cohortAddValidations.end_date)}
                                            type="Date"
                                            name="EndDate"
                                            id="EndDate"
                                            data-testid="cohorts_end_date"
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="  "
                                            required
                                            role="presentation" autocomplete="off"
                                            />
                                            {errors.EndDate?.message && <span className='form-label-color'>{errors.EndDate?.message}</span>}
                                   </div>

                                   <div className=" col-lg-6 mt-3">
                                        <label htmlFor="exampleInput">Available Date <span className='form-label-color'>*</span></label>
                                            <input {...register("AvailableDate", cohortAddValidations.available_date)}
                                            type="Date"
                                            name="AvailableDate"
                                            id="AvailableDate"
                                            data-testid="cohorts_available_date"
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="  "
                                            required
                                            role="presentation" autocomplete="off"
                                            />
                                            {errors.AvailableDate?.message && <span className='form-label-color'>{errors.AvailableDate?.message}</span>}
                                   </div>
                                </div>
                            </form>

                            <div className="mt-3 d-flex justify-content-center">
                                {!timer_flag ? (<>
                                    <div>
                                        <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
                                    </div>

                                </>
                                ) :
                                    <button
                                        type="button"
                                        className="btn my_btn_cls ms-2"
                                        data-testid="cohort_submit"
                                        value="Add Cohort"
                                        onClick={handleSubmit(addCohort)}
                                    >

                                        Submit
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content col-lg-2">
                <span className='visible-hidden'>dsd</span>
              </div>
            </div>
    </> );
}

export default withMaintenanceCheck(CohortsAdd,admin_maintenance_type) 