import axios from 'axios';

const source = axios.CancelToken.source();


axios.interceptors.request.use(
(request) => {
  request.cancelToken = source.token;
      // Add authorization header
      const admin_token = localStorage.getItem('Admin_Acesstoken');
      const user_token = localStorage.getItem('User_Accesstoken');
      if (admin_token) {
          request.headers['Authorization'] = `Bearer ${admin_token}`;
          // Add content type header
      }
      else if(user_token){
        request.headers['Authorization'] = `Bearer ${user_token}`;
      }
      // Check if request body is FormData
      if (request.data instanceof FormData) {
        request.headers['Content-Type'] = 'multipart/form-data';
      } else {
        request.headers['Content-Type'] = 'application/json';
      }
      return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    // Return response if no error
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.warn('Request canceled', error.message); // Handle request cancellation
    } else if (error.code === 'ERR_CANCELED' || error.code === 'ECONNABORTED' ) {
      console.warn('Request was aborted. Reloading the page.');
      window.location.reload();
    } else if(error.message ==='Network Error'){
      window.location.reload();
    }else {
      console.error('Request failed:', error);
    }
    return Promise.reject(error);
  }
);

export default axios;