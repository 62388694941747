import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { base_url, api_error_message, random_number } from "../../utilities";
import { toast } from "react-toastify";
import axios from "../../axios-interceptor";
import { useHistory } from 'react-router-dom';
const Attributions = () => {
  const history = useHistory()
  const [list, setList] = useState([]);
  let [filteredTableData,setFilteredTableData]=useState([])
  const [loader, setLoader] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    axios.post(base_url + "attributions/get_attributions_data", {}).then((res) => {
      setList(res.data.data);
      setFilteredTableData(res.data.data)
      setLoader(false)
    }).catch(err => {
      console.log(err);
      if (err.response?.statusText == 'Unauthorized') {
        localStorage.clear();
        history.push('/adminlogin')
        window.location.reload();
      }else if(err.message == 'Request aborted'){
        window.location.reload()
      } else {
        toast.warn(api_error_message, { toastId: random_number });
      }
    })
  }, []);
  const onEdit = (id) => {
    history.push("/admindashboard/createattributions", {
      id: id,
    });
  }
  const handleTableSearch=(e)=>{
      let value=e.target.value
      setSearchQuery(value);
      const allAtributionsList=[...list]
      let filteredTablelist = allAtributionsList.filter((rec) =>{
        const urlValue=rec.url?"Yes":"No";
        const additional_read_value=rec.is_additional_reading ? "Yes" : "No";
        return rec.article_name?.toLowerCase().indexOf(value.toLowerCase()) !== -1||
          rec.author_first_name?.toLowerCase().indexOf(value.toLowerCase()) !== -1||
          rec.author_last_name?.toLowerCase().indexOf(value.toLowerCase()) !== -1||
          rec.publication_name?.toLowerCase().indexOf(value.toLowerCase()) !== -1||
          rec.attribution_type?.toLowerCase().indexOf(value.toLowerCase()) !== -1||
          rec.year.toLowerCase().indexOf(value.toLowerCase()) !== -1||
          urlValue.toLowerCase().indexOf(value.toLowerCase()) !== -1||
          additional_read_value.toLowerCase().indexOf(value.toLowerCase()) !== -1||
          rec.course_id?.course_title?.toLowerCase().indexOf(value.toLowerCase()) !== -1||
          rec.module_id?.module_title?.toLowerCase().indexOf(value.toLowerCase()) !== -1
      });
  setFilteredTableData(filteredTablelist)
  }
  return (
    <>
      {!loader ?
        <div>
          <div className="d-flex justify-content-between">
            <div className="">
              <h3 data-testid="Main_Heading">Attributions</h3>
            </div>
            <div className="col-3">
              <div className="input-group">
                <input value={searchQuery} 
                  data-testid="Search_Box"
                  onChange={handleTableSearch}
                  className="form-control custm_input_search"
                  type="text"
                  placeholder="Type to Search"
                  aria-label="Search"
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fa fa-search"></i>
                  </span>
                </div>
              </div>
            </div>


            <div className="">
              <button data-testid="Add_Attribution" className="btn my_btn_cls submit_button btn-block mt-0 mb-5 px-4 py-2" onClick={() => history.push("/admindashboard/createattributions")}>
                Add Attribution
              </button>
            </div>
          </div>
          <div className="table-responsive " style={{height:"70vh"}}>
            <Table data-testid="Attributions_Table" className="table_custom">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Author</th>
                  <th>Publisher</th>
                  <th>Type</th>
                  <th> Date</th>
                  <th>Link</th>
                  <th>Additional Reading?</th>
                  <th>Course</th>
                  <th>Module</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  filteredTableData.length > 0 ?
                  filteredTableData.map((list_item, index) => (
                      <tr key={index}>
                        <td>{list_item.article_name}</td>
                        <td>{list_item.author_first_name + " " + list_item.author_last_name}</td>
                        <td>{list_item.publication_name}</td>
                        <td>{list_item.attribution_type}</td>
                        <td>{list_item.year}</td>
                        <td>{list_item.url?"Yes":"No"}</td>
                        <td>{list_item.is_additional_reading ? "Yes" : "No"}</td>
                        <td>{list_item.course_id.course_title}</td>
                        <td>{list_item.module_id.module_title}</td>
                        <td className="text-end pr-4" style={{ cursor: "pointer" }} >
                          <span data-testid={`Attribution_Edit_${index}`}  onClick={() => onEdit(list_item._id)}>Edit</span>
                        </td>
                      </tr>
                    ))
                    :
                    <td colSpan={10} className="text-center font-bold">No Records Found</td>
                }
              </tbody>
            </Table>
            <div data-testid="Table_Length" className="d-none">{filteredTableData.length}</div>
          </div>
          </div>
        :
        <div>
          <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
        </div>
      }

    </>
  );
};

export default Attributions;
