import React, { useEffect, useRef, useState } from 'react'
import { useLocation,useHistory } from 'react-router-dom';
import { admin_maintenance_type, api_error_message, base_url, random_number } from '../../utilities';
import axios from '../../axios-interceptor';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import CustomDataTables from './CustomDataTables';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import withMaintenanceCheck from '../../HigherOrderComponents/maintenancePageChecker';
import domtoimage from 'dom-to-image';
import { NA_String } from './../../utilities';

const GradeBookIndividual = () => {
    let [studentData,setStudentData]=useState({})
    let [studentScoreCard,setStudentScoreCard]=useState({})
    let [loading_flag,setLoading_flag]=useState(true)
    const [exportBtn, setExportBtn]=useState(false)
    const history = useHistory();
    const location = useLocation();
    const pdfRef = useRef();
    useEffect(() => {
        if(location.state.studentData)
        {
            setStudentData(location.state.studentData)
            let {user_id,cohort_schedule_id,course_id}=location.state.studentData
            axios.post(base_url+'program_manager/get_user_score_card',{
            "course_id":course_id, 
            "cohort_schedule_id":cohort_schedule_id,
             "user_id":user_id
            })
            .then(res=>{
                if(res.data.data.length>0)
                {
                    let data=res.data.data[0]
                    let courseData=data[data.course_name]
                    courseData.map(singleModule=>{
                            Object.keys(singleModule).map(key => {
                                singleModule[key].map(singleItem=>{
                                    Object.keys(singleItem).map(lastItemKey=>{
                                        if(lastItemKey=='module score')
                                        {
                                            const combinedObject = singleItem[lastItemKey].reduce((result, obj) => {
                                                const [key] = Object.keys(obj);
                                                result[key] = obj[key];
                                                return result;
                                              }, {});
                                              singleItem[lastItemKey]=combinedObject
                                        }
                                    })
                                })
                            })
                    })
                }
                setStudentScoreCard(res.data.data[0])
                setLoading_flag(false)
            }).catch(err => {
                if (err.response?.statusText == 'Unauthorized' || err.response?.data?.statusText == 'Unauthorized') {
                  localStorage.clear();
                  history.push('/')
                  window.location.reload();
                } else if(err.message == 'Request aborted'){
                    window.location.reload()
                  }else {
                  toast.warn(api_error_message, { toastId: random_number });
                }
              }) 
        }
        else{
            history.push('/programmanagementdashboard/gradebook')
        }
    }, [])

    const exportPDF = async () => {
        setExportBtn(true)
        const contentElement = document.getElementById('pdf_content');
        
        const contentWidth = contentElement.offsetWidth;
        const contentHeight = contentElement.offsetHeight;
     
         
        

        
        // Capture content as an image with higher scale for better resolution
        domtoimage.toPng(contentElement, { quality: 1, width: contentWidth * 2, height: contentHeight * 2 }).then(imgDataUrl => {
            // Create a canvas from the image
           const canvas = document.createElement('canvas');
           canvas.width = contentWidth + 50; // Add left margin space of 50 units
           canvas.height = contentHeight;
           const ctx = canvas.getContext('2d');
           const img = new Image();
           img.src = imgDataUrl;
           img.onload = () => {
               ctx.fillStyle = '#F7F7F7'; // Replace with your desired background color
               
               ctx.fillRect(0, 0, contentWidth + 50, contentHeight); // Adjust width for the left margin
               
               // Draw the image onto the canvas with left margin
               ctx.drawImage(img, 50, 0); // Draw image at (50, 0) to create a left margin
               
               // Convert canvas to JPEG with adjustable quality
               const newImgDataUrl = canvas.toDataURL('image/jpeg', 1); // Higher quality
               
               // Create PDF
               const pdf = new jsPDF('p', 'px', [contentWidth + 50, contentHeight]);
               pdf.addImage(newImgDataUrl, 'JPEG', 0, 0, contentWidth + 50, contentHeight); // Adjust the size
               
               // Save PDF
               pdf.save(`${studentScoreCard?.name}_${studentScoreCard?.course_name}_gradebook.pdf`);
               
                setExportBtn(false)

           };
        });
    }
 
  return (
    <div className='form_field_container_cls pt-2' style={{height: loading_flag?'100vh':''}}>

     {loading_flag?<>
         <>
            <div className="mt-5">
              <img data-testid="atrium_loader"
                className="atrium_loader_custom"
                src="/images/atrium_loader.gif"
                alt="Loader"
              />
            </div>
          </>
        </>:
       <>
            <div id="pdf_content" ref={pdfRef}>
               <div className='d-flex justify-content-between pt-4'>
                       <div className='mt-4'>
                            <div className='gradeindividual_std_info'>
                                <span className='left_heading' data-testid="user_name_label">Name:</span>{" "}
                                <span className='rigth_value' data-testid="user_name">{studentScoreCard?.name}</span>
                            </div>
                            <div className='gradeindividual_std_info'>
                                <span className='left_heading' data-testid="user_email_label">Mail:</span>{" "}
                                <span className='rigth_value' data-testid="user_email">{studentScoreCard?.email}</span>
                            </div>
                        </div>

                        <div>
                        <button className="btn my_btn_cls mt-0  download_exportCSV rounded exclude-from-pdf" disabled={exportBtn} onClick={exportPDF}  data-testid="export_pdf_btn">Export PDF</button>
                        </div>
                </div>
                <div className='mt-5 mb-5'>
                    <span className='garde_individual_programInfo' data-testid="main_heading">{studentScoreCard?.program_name} {studentScoreCard?.program_start_date} <span className='align-text-bottom'>{'>'}</span> {studentScoreCard?.course_name}</span>
                </div>
                {Object.keys(studentScoreCard).length>0&&studentScoreCard[studentScoreCard.course_name].map((item,index) =>{
                    return(<>
                        <div  data-testid={`modules_main_${index}`}>
                            {Object.entries(item).map(([key, value]) => (
                            <div key={key} className='mb-5'>
                                <div className='mb-2' data-testid={`module_title_${index}`}>
                                  <span className='gradebook_module_heading'>Module {index}: {key} </span>
                                </div>
                                <div className='d-flex gap-5' >
                                    <div style={{width:'65%'}} className='mb-5 pt-1' data-testid={`module_table_${index}`}>
                                        <CustomDataTables key={`Module-${index+1}`} data={value}  />
                                    </div>
                                    <div style={{width:'25%'}} data-testid={`module_grades_table_${index}`}>
                                        <div className='mb-2 pb-1'>
                                        <span className='gradebook_module_heading' data-testid="module_Grade">{key} {"Grade"} </span>
                                        </div>
                                        <div className='rightside_score_info'>
                                            <div>
                                                <div className='d-flex'>
                                                    <div className='student_grade_info_label'><span >Scored</span></div>
                                                    <div className='student_grade_info_value' data-testid="module_Score"><span >{value?.[value.length-1]?.['module score']?.['earned score'] === 0 && value?.[value.length-1]?.['module score']?.['total score'] === 0 ? 
  NA_String : 
  `${value?.[value.length-1]?.['module score']?.['earned score']}/${value?.[value.length-1]?.['module score']?.['total score']}`
}
 </span></div>
                                                </div>
                                                
                                                <div className='d-flex'>
                                                    <div className='student_grade_info_label'><span>Scored Percentage</span></div>
                                                    <div className='student_grade_info_value' data-testid="module_Score_percentage"><span className='blue_text'>{(value?.[value.length-1]?.['module score']?.['earned score'] === 0 && value?.[value.length-1]?.['module score']?.['total score'] === 0) ? 
  NA_String : 
  `${value?.[value.length-1]?.['module score']?.['score percentage']}${value?.[value.length-1]?.['module score']?.['score percentage'] !== NA_String ? '%' : ''}`
}
</span></div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className='student_grade_info_label'><span>Module Completed</span></div>
                                                    <div className='student_grade_info_value' data-testid="module_completed"><span style={{color:value?.[value.length-1]?.['module score']?.['module completed']=='Yes'?'#034A0E':'#D00000'}}>{value?.[value.length-1]?.['module score']?.['module completed']}</span></div>
                                                </div>
                                                {value?.[value.length-1]?.['module score']?.['onTime completed'] && <div className='d-flex' >
                                                        <div className='student_grade_info_label'><span>On time completed</span></div>
                                                        <div className='student_grade_info_value' data-testid="module_ontime_completed"><span style={{color:value?.[value.length-1]?.['module score']?.['onTime completed']=='Yes'?'#034A0E':'#D00000'}}>{value?.[value.length-1]?.['module score']?.['onTime completed']}</span></div>
                                                    </div>
                                                }
                                                <div className='d-flex'>
                                                    <div className='student_grade_info_label'><span>Learning Time</span></div>
                                                    <div className='student_grade_info_value ' data-testid="module_learning_time"><span className='blue_text'>{value?.[value.length-1]?.['module score']?.['time spent on module']}</span></div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className='student_grade_info_label'><span>Badge Awarded</span></div>
                                                    <div className='student_grade_info_value' data-testid="module_badge_awarded">
                                                    {(index==0||index==studentScoreCard[studentScoreCard.course_name].length-1)?
                                                    <>
                                                    <span style={{color:'#034A0E'}}>N/A</span>
                                                    </>:
                                                    <>
                                                    <span style={{color:(value?.[value.length-1]?.['module score']?.['badge awarded'])=='Yes'?'#034A0E':'#D00000'}}>{value?.[value.length-1]?.['module score']?.['badge awarded']}</span>
                                                    </>} 
                                                </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                    </>)
                })}

                <div className='d-flex justify-content-end mr-5 pr-5'>
                    <div>
                      <button className="btn my_outline_button_cls submit_button fw-bold gradebook_indvl_back_btn m-0 px-4 exclude-from-pdf" onClick={()=>{history.push('/programmanagementdashboard/gradebook')}} data-testid="back_btn">Back</button>
                    </div>
                    <div>
                        <span className='visible-hidden'>test</span>
                    </div>
                </div>
            </div>
        </>}
    </div>
  )
}

export default withMaintenanceCheck(GradeBookIndividual,admin_maintenance_type)