import axios from "../../axios-interceptor";
import moment from "moment";
import { useState,useEffect,Fragment } from "react"
import { base_url,api_error_message,random_number, quiz_name, learn_by_doing, check_point, did_i_get_this, date_formats } from "../../utilities";
import { MessageComponent } from "./messageComponent";
import { useRef,useLayoutEffect} from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
export const ActivityElementMultipleInput = (props) => {
    let [points_data,setPointsData] = useState({})
    let date_flag = false
    const history = useHistory();
    const fileInputRef = useRef(null);
    const activity_element_multi_check_Component=useRef(null)
    const [user_answer, setUserAnswer] = useState([]);
    const [flag, setFlag] = useState(false)
    const [submit_button_clicks, setSubmitButtonClicks] = useState(false)
    const searchString = "(today s date),";
useLayoutEffect(() => {
    props.i==0?
   setTimeout(() => {
       window.scrollTo({
         top: window.scrollY,
         left: 0,
         behavior: "smooth",
       });
     }, 350):
     setTimeout(() => {
         window.scrollTo({
        top:  activity_element_multi_check_Component.current?.offsetTop-40,
        left: 0,
           behavior: "smooth",
         });
       }, 350)
  }, []);
    let title = props.title;
    let purpose = props.purpose;
    let dropdown = '<select>', ele_content = [];
    let dropdown_length
    const [array_content, setArrayContent] = useState([]);

 
    for (const [key, value] of Object.entries(props.data.content.stem.content)) {
        ele_content[key] = [];
        if (value?.children) {
            for (const [index, value1] of Object.entries(value.children)) {
                ele_content[key][index] = {}
                
                if (value1.type === 'a') {
                    ele_content[key][index]['href'] = value1.href
                    ele_content[key][index]['text'] = value1.children[0].text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
                }
                let i = -1
                if (value1.type == 'input_ref') {
                  
                    for (const value2 of props.data.content.inputs) {
                        i = i+1
                        if (value1.id == value2.id && value2.inputType == 'dropdown') {
                            let options = []
                           
                            dropdown = '<select test class="custom-select">'
                            for (const value3 of value2.choiceIds) {
                                for (const [choiceid, choice] of Object.entries(props.data.content.choices)) {
                                    if (choice.id === value3) {
                                        options[choiceid] = {}
                                        options[choiceid]['key'] = value3;
                                        options[choiceid]['id'] = value1.id;
                                        options[choiceid]['value'] = choice?.content[0]?.children[0].text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ");
                                        dropdown += `<option value='${choice?.content[0]?.children[0].text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")}'>${ choice.content[0].children[0].text }</option>`
                                    }
                                }
                            }
                            ele_content[key][index]['dropdown'] = options;
                            dropdown_length =i+1
                        }
                        else if(value1.id == value2.id && value2.inputType == 'text'){
                          
                            ele_content[key][index]['input_text'] = true
                            ele_content[key][index]['key'] = i
                            dropdown_length = i + 1
                        }
                    }
                    dropdown += "</select>"
                }
                else if(value?.type == 'ol'||value?.type =='ul')
                {
                    ele_content[key][index]['listData'] = value?.children;
                    ele_content[key][index]['type'] = value?.type; 
                }                
                else {
                    if(value1?.text?.includes('agree on')){
                        date_flag = true
                    }
                    ele_content[key][index]['text'] = value1?.text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ");
                    ele_content[key][index]['type'] = value?.type;
                }
            }
            if(date_flag){
                dropdown_length = dropdown_length -1;
            }
        }
    }
    var value
    // var rule_value = props.data.content.authoring.parts[0].responses[0].rule.match(/{(.*)}/)[1]
    var [answer_key, setAnswer] = useState('')
    var [button_name, setButtonName] = useState('Submit')
    var [msg, setMsg] = useState('')
   
    let [ans_array,setAnsArray] = useState([])
    let [ele_data,setEleData] = useState([])
    let [peer_flag,setPeerFlag] = useState(false)
    let [element_type,setElementType] = useState('')
    const [activityInitiatedTime, setActivityInitiatedTime] = useState(moment());
    useEffect(()=>{
       
        if(localStorage.getItem('re_attempt')){
            props.data.is_answered = false
          }
        ele_content.map((item,index)=>{
            item.map(val=>{
                item.some(val => val.dropdown)? setElementType('dropdown'): setElementType('')
                item.some(val => val.input_text)?setElementType('input'):setElementType('')
                if(val?.listData)
                {
                    ele_content[index]=[val]
                }
            })
         })
         
        if(props.data.is_answered){
         setFlag(true)
         const list = [...ele_content];

         list?.map(item=>{
            item?.map(value=>{
                
                value?.dropdown?
                
                   value?.dropdown?.map(val=>{
                    val['is_selected'] = false
                   }):
                   value['value'] = ''
            })
         })
        //  let arr = props.data.user_answer.split(',')
        let arr = []
        props.data.user_answer.map(item=>{
         
            if(typeof item == "string")
            {
                arr.push(item)  
            }else{
                arr.push(item.option_id)
            }
           
        })
         list.map(item=>{
            item?.map(value=>{
                value?.dropdown?
                   value?.dropdown?.map(val=>{
                    arr?.map(val1=>{
                 
                        if(+val.key == +val1){
                            val['is_selected'] = true
                     
                        }
        
                   })
                   }): 
                   arr?.map((i,index)=>{ 
                      if(index ==  value['key'] ){
                        value['value'] = i
                      }
                   })
         })
        })
        
        setEleData(list)
        }else{
          
            fileInputRef?.current?.click();
            setEleData(ele_content)
        }
        
        const container = document.createElement('div');
        container.innerHTML = ele_content;
        const h2Text = container.querySelector('h2.question-title')?.innerText.trim();
        const text = container.querySelectorAll('.summary_header_cls');
        const liElements = document.querySelectorAll('#multi-input .summary_list_cls');
        const headerTexts = Array.from(text).map(element => element?.textContent?.trim())
        const liTexts = Array.from(liElements).map(li => li?.textContent?.trim());
        let text_conetent = []
        ele_content.map(item=>{
            item.map(i=>{
                text_conetent.push(i.text?i.text:(i.input_text?'type your response':'select any option'))
            })
        })
        const combinedText = [...headerTexts,...liTexts, text_conetent.toString()].join('. ');
        localStorage.setItem('prev_title',h2Text)
        localStorage.setItem('prev_text',combinedText)
       },[])
       let val = {}
       let values = ''
        let user_ans = []
    const onChangeOption = (e) => {
        if(e.target.value){
            var test = (e.target.value).split(',');
            val[test[1]] = test[0]
          
            let answer =  {
                "option_id":test[0],
                "answer_label":test[2]
            }
            
            user_ans.push(answer)
          }
    }

    const allInputValuesaluesFilled=()=>{
        if(props.data.is_answered){
            setFlag(true)
        }else{
            const inputElements = document.getElementsByClassName('custom_input_class');
            let isAnyValueEmpty = false;
    
            for (const element of inputElements) {
            const value = element.value.trim(); // Trim to remove leading/trailing whitespace
            if (value === null || value === '') {
                isAnyValueEmpty = true;
                break; // Exit the loop if any empty value is found
            }
            }
            if (isAnyValueEmpty || submit_button_clicks) {
                setFlag(true)
            } else {
                setFlag(false)
            }
        }
    }
   
    const getText = (e,key) =>{

        let value = e.target.value;

        const newUserAnswer = [...user_answer];
        newUserAnswer[key] = value;

        setUserAnswer(newUserAnswer);
    
        allInputValuesaluesFilled();
    }
    useEffect(() => {
        const inputElement = fileInputRef?.current;
        if (inputElement) {
            const inputTextWidth = getTextWidth(inputElement.value);
            inputElement.style.width = inputTextWidth + 'px';
        }
        if (props.data.is_answered) {
            setFlag(true);
        } else {
            allInputValuesaluesFilled();
        }
      }, [getText,props.data.is_answered]);
      const getTextWidth = (text) => {
        const span = document.createElement('span');
        span.style.visibility = 'hidden';
        span.style.position = 'absolute';
        span.style.whiteSpace = 'pre';
        span.style.fontSize = '20px'
        span.style.fontWeight = 500
        span.innerText = text;
        document.body.appendChild(span);
        const textWidth = span.getBoundingClientRect().width;
        document.body.removeChild(span);
        return textWidth;
      };
    const onSubmitAnswer = () => {
        let is_correct
       
        values = Object.values(val);
       
        let response = []
        props.data.content.authoring.parts.map(item=>{
            response.push(values.includes(item?.responses[0].rule.match(/{(.*)}/)[1]))
        })
        localStorage.setItem('ans_array', values.toString())
        if(!flag){
            if (values.length == dropdown_length || user_answer.length == dropdown_length ) {
                setPeerFlag(true)   
               
                setTimeout(() => {
                    window.scrollTo({
                      top: window.scrollY+window.innerHeight,
                      left: 0,
                      behavior: "smooth",
                    });
                  }, 300);
                 
                 if(values.length != 0){
                    if( response.includes(false)){
                        setMsg('no')
                        is_correct=false
                        setAnswer("Incorrect")
                    }else{
                        setMsg('yes')
                        is_correct=true
                        setAnswer("correct")
                    }
                 }else if(user_answer.length != 0){
                    is_correct=true
                    user_ans = user_answer
                    setMsg('')
                    setAnswer("")
                 }
                   
                
            
            const activityEndTime=moment()
            var body = {
                user_id: localStorage.getItem("user_id"),
                course_id: props.data.course_id,
                module_id: props.data.module_id,
                lesson_id: props.data.lesson_id,
                cohort_schedule_id: props.data.cohort_sch_id,
                activity_id: props.data.activity_id,
                activity_entity_type: props.data.acitivity_entity_type,
                is_question: true,
                user_answer:user_ans,
                is_correct: is_correct,
                question_type:props.data.subType,
                answer_label: "Test",
                is_quiz: purpose ==quiz_name?  true: false,
                time_spent_on_activity:activityEndTime.diff(activityInitiatedTime,'seconds'),
                activity_submitted_platform: "ISBo"
              };
              if(date_flag)
              body.user_answer.push(date_formats.human_date_format(new Date()))
            if(localStorage.getItem("user_id") && window.location.pathname.toLowerCase().includes('freelessonpage') == false&&window.location.pathname.toLowerCase().includes('freelessonbdlink') == false){
              axios
                .post(base_url + "user/progress_tracking", body)
                .then((res) => {
                    setFlag(true)
                    setSubmitButtonClicks(true)
                    if(typeof(res.data.points_break_down) == 'object'){
                        setPointsData(res.data.points_break_down)
                      }
                    setActivityInitiatedTime(moment())
                    props.parentCallback('false');
                })
                .catch((err) => {
                  console.log(err);
                  props.parentCallback('');
                  setFlag(false)
                  setSubmitButtonClicks(false)
                  if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
                    localStorage.clear();
                    history.push('/')
                    window.location.reload();
                  }else if(err.message == 'Request aborted'){
                    window.location.reload()
                  }else{
                    toast.warn(api_error_message,{toastId:random_number});
                    if(user_answer.includes(date_formats.human_date_format(new Date()))){
                        user_answer.pop();
                    }
                  }
                });
            }else{
                setPointsData({"free_lesson":"1"})
                props.parentCallback('false');
            }
        }else{
            setSubmitButtonClicks(false)
            setFlag(false)
            setMsg('')
            setAnswer("")
           
        }
       
        }
    }
   
    return (
        <>
        <div data-testid="ActivityElementMultipleInput_content">
            <div ref={activity_element_multi_check_Component} className='data-component col-12 custm_shadow_cls bg-body'>
            <div className="d-flex flex-row justify-content-between align-items-end" style={{}}>
              <strong data-testid="activity_purpose" className=" mb-0 align-items-end" style={{ letterSpacing: "1px", opacity: "50%", }}>
                {purpose == learn_by_doing ? <p className="mb-0">
                  <img style={{ opacity: "50%" }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA7ElEQVRIieWVMQrCMBhGH3Vyap1FcBYcPIpCr1Do0mPoFTyFCOIJqoIO3sLiWMShqFCH/IVQaEkhmfpBaBOS99ImJNCnDIE7cHYl2AClFOuZAx9XAg+4aHDrgkSgDxeCCfAS6NKFYC/Ag9StCkKB5cC4JqiXJxB1gQdAJoNjrT1tkfwA31SwlUEn1C5qywwoUNs4MIGPpHMhg9viySRKmZRRfNTnNv2KVOsbS1tmOvsqEWrhmiSgFj6XetgF3hZdsJP3oy24LljJ8w1MXQiqYyOxCdcFJXADBq4EX2BhG64L1i7goK7LK+r67En+G2pg6MDEsykAAAAASUVORK5CYII=" />

               PRIMING ACTIVITY</p>  : (purpose == check_point ? <p>CHECK POINT</p> : (purpose == quiz_name ? <p>QUESTION</p> : (purpose == did_i_get_this ? <p>DID I GET THIS?</p> : '')))}

               </strong>
             </div>
             <div className="row">
                <div data-testid="multi_input_content" className="col-lg-12" id="multi-input">
                    {
                        ele_data.map((value,ele_index) => (
                            <Fragment key={ele_index}>
                                {
                                    value.map((value1,val_index) => (
                                       
                                        <Fragment key={val_index}>
                                                {value1?.type == 'ol'&& <ol class="summary_uList_cls">
                                                   {value1?.listData.map((listItem,idx)=>{
                                                  return(
                                                    <Fragment key={idx}>

                                                    {listItem?.children[0]?.strong?
                                                         <li class="summary_list_cls">
                                                           <strong class="summary_header_cls">
                                                              {listItem?.children[0].text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")}
                                                           </strong>
                                                        </li>:<li class="summary_list_cls">
                                                              {listItem?.children[0].text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")}
                                                        </li>
                                                        }
                                                    </Fragment>
                                                    )
                                                    })}
                                                </ol>}
                                                {value1?.type == 'ul'&& <ul class="summary_uList_cls">
                                                    {value1?.listData.map((listItem,idx)=>{
                                                    return(
                                                        <Fragment key={idx}>
                                                        {listItem?.children[0]?.strong?
                                                            <li class="summary_list_cls">
                                                            <strong class="summary_header_cls">
                                                               {listItem?.children[0].text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")}
                                                            </strong>
                                                            </li>:<li class="summary_list_cls">
                                                                {listItem?.children[0].text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")}
                                                            </li>
                                                            }
                                                        </Fragment>
                                                        )
                                                    })}
                                                </ul>}
                                            
                                            {value1.type == "h2" ? <><h2 className="question-title">{value1?.text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")}</h2>{element_type == 'dropdown' ?<div className="summary_header_cls"><i>Select the right option</i></div>:(element_type == "input"?<div className="summary_header_cls"><i>Type in your answers</i></div>:'')}<br></br></>:
                                            <>
                                            {(value1.text!==" "&&value1.text!=="")? <><span className="summary_header_cls" style={{display:ele_data.length>2&& 'unset' }}>{value1?.text?.toLowerCase().replace(/[\u2019\x27\xA0\u200B\u202F\x20]/g, " ").includes(searchString)  ? value1?.text?.replace(/[\u2019\x27\xA0\u200B\u202F\x20]/g, " ").replace(searchString, ''):value1?.text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")}</span></> :""
                                            }
                                            {(ele_data.length>2 && value1.text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " "))&&<><br /><br/></>}
                                            </>
                                            }
                                            
                                            {
                                                 (value1.dropdown ?<><select className="custom_select"  onChange={(e)=>onChangeOption(e)}>
                                                    <option value="" style={{display:"none"}}>Select</option>
                                                    {value1.dropdown.map((value2,index) => (
                                                        <>
                                                            <option selected={value2.is_selected} value={`${value2.key},${value2.id},${value2.value}`} title={value2.value} key={value2.id} id={value2.key}>{value2.value.toString().length > 30 ? value2.value.slice(0, 30)+ '...':value2.value}</option>
                                                        </>
                                                    ))}
                                                </select></> :(value1.input_text && <>{date_flag && value1.key ==  2?<input data-testid="hidden_inputBox" className="custom_input_class border-0 multi_input_date" type="text" id={value1.key} value={value1.value?value1.value:date_formats.human_date_format(new Date())}  ref={fileInputRef} disabled></input> :<input data-testid="inputBoxs"className="custom_input_class" type="text" id={value1.key} value={value1.value}  onChange={(e)=>getText(e,value1.key)}></input> }</>))
                                                 
                                                
                                            } 
                                        </Fragment>
                                    ))
                                }
                            </Fragment>
                        ))
                    }
                    <div style={{marginTop:ele_data.length>2&& '30px'}}>
                        <button data-testid="submit_activity" className="btn review_button submit_button hover_button"  disabled={flag}  onClick={onSubmitAnswer}>
                           
                            <b>SUBMIT</b>
                        </button>
                    </div>

                </div>
                {/* removed peer response */}
                {/* <div className={`${peer_flag ? "col-4" : ""}`}>
                    {
                        !flag && (
                            props.data && props.data.content.stem.content.map(item => (
                                <>
                                    {item?.type == "img" && (
                                        <img className="mx-auto d-block imgs" src={item?.src} ></img>
                                    )}
                                </>
                            ))
                        )
                    }
                    
                    {flag &&  <img className="mx-auto d-block imgs" src="/images/peer-choice.svg" ></img>}
                </div> */}
                {/* test case coverage */}
                <div>
                <span
                    className="d-none"
                    data-testid="dropdown_option"
                    onClick={(e) =>
                        onChangeOption({
                        target: {
                            value: 'option_id_value,option_label_value,answer_label_value',
                        },
                        })
                    }
                ></span>

                </div>
             </div>
            </div>
            {(msg!==''  && Object.keys(points_data).length != 0 )&& <MessageComponent points={points_data} msg={msg} suceess_msg={answer_key} />}
            </div>
        </>
    )
}
