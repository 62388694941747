import axios from '../../axios-interceptor';
import React, { useEffect } from 'react';
import { useState } from 'react';
import {  Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { random_number,base_url,api_error_message, admin_maintenance_type } from '../../utilities';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import withMaintenanceCheck from '../../HigherOrderComponents/maintenancePageChecker';


const MyBatchmates = () => {
  const location=useLocation();
  let history=useHistory()
  let [currentdrpdownval,setcurrentdrpdownval]=useState('all')
  let [allApplicationList,setAllApplicationList] =useState([])
  let [applicationList,setApplicationList]=useState([])
  const [selectedDropDownItem,setSelectedDropDownItem]=useState(null)
  let [timer_flag, setTimeFlag] = useState(false);
  const handleBatchChange=value=>{
    setcurrentdrpdownval(value)
    if(value=='all')
    {
      setApplicationList(allApplicationList)
    }
    else{
     let appLists=[...allApplicationList]

     let foundApp= appLists.filter(obj=>{
      if(obj._id==value)
      {
        obj['bgcolor']="#057092" 
        obj['textClr']='#ffffff'
      }
      else{
        obj['bgcolor']="white" 
        obj['textClr']='#000000'
      }
      return obj._id==value
     })
     setSelectedDropDownItem(foundApp[0])
     setApplicationList([foundApp[0]])
    }
  }

  useEffect(() => {
    axios.post(base_url+'user/get_cohort_batchmates',{ 
      "cohort_schedule_id":location?.state?.cohort_data?.cohort_schedule_id})
      .then(res=>{
        setTimeFlag(true);
        res.data.result.map((obj,index)=>{
          if(index==0)
          {
            obj['bgcolor']="#057092" 
            obj['textClr']='#ffffff'
          }
          else{
            obj['bgcolor']="white" 
            obj['textClr']='#000000'
          }
          
          if(obj.aws_id==localStorage.getItem('user_id'))
          {
            obj['text_bold']="bolder"
          }
          else{
            obj['text_bold']="normal"
          }
          obj['fullName']=obj.first_name+" "+obj.last_name

        })

        setSelectedDropDownItem(res.data.result[0])
        setAllApplicationList(res.data.result)
        setApplicationList(res.data.result)
      })
      .catch(err=>{
        console.log(err);
        if(err.response?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
      })

  }, [])

  const search=item=>{
    if(currentdrpdownval=='all')
    {
      if(item=="")
      {
        allApplicationList.map((obj,index)=>{
          if(index==0)
          {
            obj['bgcolor']="#057092" 
            obj['textClr']='#ffffff'
          }
          else{
            obj['bgcolor']="white" 
            obj['textClr']='#000000'
          }
        })
        setApplicationList(allApplicationList)
        setSelectedDropDownItem(allApplicationList[0])
      }
      else{
        let filterresult = allApplicationList.filter((obj)=> {
          return obj.fullName.toLowerCase().indexOf(item.toLowerCase()) !== -1
        });
        setApplicationList(filterresult)
      }
    }
    }

 const itemChanged =(item,idx)=>{
  if(currentdrpdownval=='all')
  {
    let tmpval=[...applicationList]
    tmpval.map((obj,index)=>{
      if(index==idx)
      {
        obj['bgcolor']='#057092'
        obj['textClr']='#ffffff'
      }
      else{
        obj['bgcolor']="white"
        obj['textClr']='#000000'
      }
    })
    setApplicationList(tmpval)
    setSelectedDropDownItem(item)
  }
 }

return (
  <>
  {!timer_flag ? (
          <div>
          <img className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
        </div>
            ):
  <div >
  <Container className="bg-transparent mt-2">
      <img src="/images/batchmates-new.svg" alt="" />
    </Container>

    <Container className='batchmates_container'>
  
    {allApplicationList.length==0&&
     <div className='batchmates_notfound'>
     <h3>Batchmates not found...!</h3>
   </div>
      }
    <Link to="/dashboard">
    <div className='next_stdnt btn my_btn_cls'>Back to Dashboard</div>
    </Link>
    </Container>
    <Container className='batchmates_containerTwo'>
     {allApplicationList.length>0&& <div className="row">
        <div className="col-lg-3 mt-3 p-2">
          <div className="two p-3">
            <div className="form-group">
              <select
                id="inputState"
                className="form-select form-select mb-2"
                aria-label=".form-select example"
                onChange={(e)=>{handleBatchChange(e.target.value)}}
              >
                <option value="all">All Batchmates</option>
                {allApplicationList.map((app,index) => {
                  return (
                    <option key={index} value={app._id}>
                      {app.first_name} {app.last_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="inputWrapper">
              <input
                className="form-control custm_input_search"
                type="text"
                placeholder="Type to Search"
                aria-label="Search"
                onChange={(e)=>{search(e.target.value)}}
              />
              <i className="input_Mag_icon_custom fa-solid fa-magnifying-glass"></i>
            </div>
            <div>
            {applicationList.map((apptwo, index) => {
              return (
                <div
                  style={{ backgroundColor: apptwo.bgcolor ,fontWeight:apptwo.text_bold,color:apptwo.textClr}}
                  onClick={() => itemChanged(apptwo,index)}
                  className="batchmate_name mt-3"
                  key={apptwo.index}
                >
                  {<span>{apptwo.first_name} {apptwo.last_name}</span>}
                </div>
              );
            })}
            </div>
          </div>
        </div>
        <div className="col-lg-9 m-p-1 mt-3">
          <div className="col-lg-12 col-lg-12 col-sm-12 mb-3 p-1">
            {selectedDropDownItem !== null && (
              <div
                className="five five_contct_dtls batch_card_height" 
                key={selectedDropDownItem._id}
              >
                <span className='batchmatess_details'>Details</span>
                <div className="indiv_cntct_details">
                  <span>
                    <b>
                      {selectedDropDownItem.first_name +" "+
                        selectedDropDownItem.last_name}
                    </b>
                    <br />
                    </span>
                    <span className='mt-2 d-block batchmates_text'>
                    {selectedDropDownItem.city},&nbsp;{selectedDropDownItem.state},&nbsp;{selectedDropDownItem.country},&nbsp;{selectedDropDownItem.postcode}
                  </span>
                </div>
                <div className="adrs_n_exprns_qulfictn batchmates_details_width ">
                  <span className='batchmates_text visually-hidden'>
                    {selectedDropDownItem.role}, {selectedDropDownItem.company} (
                    {selectedDropDownItem.years_company} Years){" "} <br />
                    {selectedDropDownItem.industry}{" "} <br />
                    Total Experience {selectedDropDownItem.total_years} Years {" "}
                  </span>
                  <span className='batchmates_text visually-hidden'>
                    {selectedDropDownItem.high_education}, {" "}
                    {selectedDropDownItem.high_institute}, {" "}
                    {selectedDropDownItem.graduation_year} <br />

                    {selectedDropDownItem.prev_education}, {" "}
                    {selectedDropDownItem.prev_institute} {" "}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>}

    </Container>
  </div>
}
  </>
);

};

export default withMaintenanceCheck(MyBatchmates,admin_maintenance_type) 