import moment from 'moment';
export const base_url = window.location.hostname === "localhost" ? 'http://localhost:5643/' : window.location.protocol+'//'+window.location.hostname+'/backend/'
let online_url = '',nav_storefront_url='';
if(window.location.hostname === "hippolearn.isb.edu"){
    nav_storefront_url = 'https://watsonlock.isb.edu/';
    if(window.location.pathname.includes('/freeLessonpage') || window.location.pathname=== '/onlineapplication' || window.location.pathname==='/applicationrecvd' || window.location.pathname==='/freelessonlogin'){
        online_url =  'https://watsonlock.isb.edu/'   
    }else{
        online_url =  'https://hippolearn.isb.edu/'
    }
}else if(window.location.hostname === "learn.isb.edu" || window.location.hostname === "learn2.isb.edu" || window.location.hostname === "node1.isb.edu" ||  window.location.hostname === "node2.isb.edu" ||  window.location.hostname === "node3.isb.edu"){
    nav_storefront_url = 'https://online.isb.edu/';
    if(window.location.pathname.includes('/freeLessonpage') || window.location.pathname=== '/onlineapplication' || window.location.pathname==='/applicationrecvd' || window.location.pathname==='/freelessonlogin'){
        online_url =  'https://online.isb.edu/' 
    }else{
        online_url =  window.location.origin + '/'
    }
}else{
    nav_storefront_url = 'https://isbstorefront.quantana.top/';
    if(window.location.pathname.includes('/freeLessonpage') || window.location.pathname=== '/onlineapplication' || window.location.pathname==='/applicationrecvd' || window.location.pathname==='/freelessonlogin'){
        online_url =  'https://isbstorefront.quantana.top/' 
    }else{
        online_url =  window.location.origin + '/'
    }
}
export const storefront_url = online_url
export const land_nave_storefront_url = nav_storefront_url;
// we need to change for live this one ---- rzp_live_knu5XatV0o4V4s ----
export const razorpay_key = window.location.hostname === "learn.isb.edu" ? 'rzp_live_knu5XatV0o4V4s' : 'rzp_test_7DSLUWSkXyDYXL'
export const setUrl = ()=>{
    localStorage.setItem('prev_url',window.location.href);
}
// adminlogin roles
export const api_error_message="Something went wrong. Please refresh and retry.";
export const wrong_link_error_message="Your session has timed out for security reasons. Please start over to continue.";
export const NA_String="NA";
export const admin_role="1";
export const role_obj={
    "my_self":"Myself",
    "my_team":"My Team",
    "my_organisation":"My Organisation (L & D Responsibility)"
};
export const default_dial_code="91";
export const default_country_code="in";
export const program_management_role="2";
export const enrollment_management_role="3";
export const crypto_secret_key="secret key 123";
export const public_role = 1
export const private_role = 2
export const unlisted = 3
export const user_maintenance = false
export const admin_maintenance = false
export const user_maintenance_type = "USER"
export const admin_maintenance_type = "ADMIN"
export const social_learning_activity = 'social learning activity'
export const unassigned_role = 'Unassigned'
export const quiz_name = 'quiz'
export const summary_name = 'summary'
export const course_quiz = 'course quiz'
export const identify_quiz = 'quiz'
export const learn_by_doing = 'learnbydoing'
export const check_point = 'checkpoint'
export const did_i_get_this = 'didigetthis'
export const Program_type = 1
export const standalone = "Standalone"
export const cohort_based = "Cohort Based"
export const validEmailMessage = '"email" must be a valid email'
export const standalone_program_type = 2
export const minimum_percentage = 75
export const minimum_duration = 45
export const full_payment = 1
export const emi_payment = 2
export const offline_payment = 3
export const one_point = 1
export const random_number = Math.random()
export const payment_paid = 1
export const validate_certificate_issuing_criteria_yes = true;
export const validate_certificate_issuing_criteria_no = false;
export const emi_status = 1;

// export const progresstracking_api_error_message = "Issue recording response. Please refresh the page and try again"
// export const lessonrecap_api_error_message = "Please answer all the questions"
export const downloadPdf=(url)=>{
    const fileName = url.split('/').pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute('download', fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();  
}
export const date_formats= {
    human_week_date_format: function (date) {
        return moment(date).format("dddd MMM D, YYYY");
    },

    date_format_heatmap_start_date(){
        return moment().startOf("year").subtract(1, "day").format("YYYY-MM-DD")
    },

    date_format_heatmap_end_date(){
        return moment().endOf("year").format("YYYY-MM-DD")
    },

    date_format(date){
        return moment(date).format("YYYY-MM-DD")
    },

    human_date_format(date){
        return moment(date).format("MMM D, YYYY");
    },
    human_date_format2(date){
        return moment(date).format("D MMM, YYYY");
    },

    human_date_time_format(date){
        return moment(date).format("MMM D, YYYY h:mm A"); 
    },

    invoice_date_format(date){
        return moment(parseInt(date+'000')).format("MMM D, YYYY");
    },

    convert_current_date_utc_format(){
        return moment.utc().valueOf()
    },

    convert_current_date_without_time(){
        return moment().startOf('day').unix() * 1000
    }

}
export const formatSentence=(sentence) =>{
    sentence = sentence.replace(/-/g, ' ');
    sentence = sentence.replace(/(?:^|\s)\S/g, function(firstLetter) {
      return firstLetter.toUpperCase();
    });
    return sentence;
  }
export const twoDaysBeforeDate = (date)=>{
    const timestampDate = new Date(date);

    // Calculate two days before the timestamp date
    const twoDaysBeforeDate = new Date(timestampDate);
    twoDaysBeforeDate.setDate(timestampDate.getDate() - 2);
    
    // Format the resulting date as 'YYYY-MM-DD'
    const twoDaysBeforeDateString = twoDaysBeforeDate.toISOString().split('T')[0];
    return twoDaysBeforeDateString
}

export const currentDate = (date) => {
    
    const today = new Date();
  
      // If it's before today, return today's date
      return today.toISOString().split('T')[0];
    
}
export const moduleBadgeIssuing = (user_earned_module_score,module_total_score,module_score_percentage,badge_percentage,points_needed_for_badge,end_date)=>{
    return (
        <>
        <span>You currently have {user_earned_module_score}/{module_total_score} points ({module_score_percentage}%)</span>
                                  <br />
                                  <br />
                                  <span>
                                    You will need to achieve {Math.round(badge_percentage) ? Math.round(badge_percentage) : <img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" />}%
                                    ({points_needed_for_badge} points <br/>more) by {date_formats.human_date_time_format(end_date)} to<br/> be awarded a badge.
                                  </span>
        </>)
}

export const badgeMessage = "Congratulations you have earned the badge."
export const courseOutlineBadge =(badge_percentage,end_date)=>{
   
    return(
        <>
        <span>You will need to achieve {Math.round(badge_percentage)?Math.round(badge_percentage):<img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" ></img>}% by</span><br/> <span> {date_formats.human_date_time_format(end_date)} to be</span><br/> <span>awarded a badge.</span>
        </>
    )
   
}
export const programme_certificate_authorization ={
    'Authorization': 'Bearer j8is(rYXr1vAKLqk@yJrNOtrUL2?XyRmBjx',
    'Content-Type': 'application/json' // Adding Content-Type header
  }
 export const quiz_max_attempts =99
 export const unlimited_text = "unlimited"
 export const redo_text = "Redo"
 export const review_text ="Review"

