import { useEffect, useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useForm, Controller } from 'react-hook-form';
import axios from "../../axios-interceptor";
import { base_url, api_error_message, random_number, downloadPdf, date_formats } from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory,useLocation } from "react-router-dom";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import Select from "react-select";
import { createScheduleEmailsValidations } from "../../formValidationsRules";

const CreateScheduleEmail = () => {
  const [content, setContent] = useState('');
  let [loading_flag, SetLoading_flag] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [programms, SetAllProgramms] = useState([]);
  const [selected_programms, SetSelectedProgramms] = useState([]);
  const [templates, SetAllTemplates] = useState([]);
  const [selected_templates, SetSelectedTemplates] = useState([]);
  const [success_emails, SetSuccessEmails] = useState([]);
  let [subject_name, SetSubjectName] = useState('');
  let [handout_url, setHandoutUrl] = useState(null);
  let [url, setUrl] = useState(null);
  const [schedule_checked, setScheduleChecked] = useState(false);
  const [email_list, setEmailList] = useState(false);
  let [scheduled_email, setScheduledEmail] = useState();
  const [clearedDate, setClearedDate] = useState(null);
  let [programm_id, setProgrammId] = useState('');
  let [template_id, setTemplateId] = useState('');
  let [sentbutton_label, setSentButtonLabel] = useState(false);
  let [file, setFile] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  let [options, setOptions] = useState([]);
  const location = useLocation();
  const { register, handleSubmit, setValue, watch } = useForm();
  const selectedProgramme = watch("programme_name");
  let [genericEmailTemplateCheckbox,setGenericEmailTemplateCheckBox]=useState(false)
  let [isbxEmailTemplateCheckbox,setIsbxEmailTemplateCheckBox]=useState(false)
  const { control } = useForm();


  const schedule_id = location.state?.schedule_id;

  const history = useHistory();

  const fileInputRef = useRef(null);

  const handleFileSelect = () => {
    fileInputRef?.current?.click();
  };

  const handleEditorChange = (content) => {
    setContent(content);
    setValue("body", content);
  };

  const getChecked = (isChecked,type) => {
    if(type== "generic_type"){
      setGenericEmailTemplateCheckBox(isChecked);
    }else if(type == "isbx_type") {
      setIsbxEmailTemplateCheckBox(isChecked);
    }
  };
  
  const {
    // register,
    // handleSubmit,
    // setValue,
    formState: { errors, isSubmitting, isValid },
    reset,
  } = useForm({ mode: "all" });

  const customFilterOption = (option, searchText) => {
    const optionLabel = option.label?.toLowerCase();
    const searchLower = searchText?.toLowerCase();
    return optionLabel?.includes(searchLower);
  };

  const optionsP = programms.map((app) => ({
    value: app._id,
    label: app.program_display_name ? app.program_display_name : " ",
  }));

  useEffect(() => {
    if(schedule_id){
      axios.post(base_url + 'scheduled_emails/get_scheduled_emails_by_email_sent', {id:schedule_id
      }).then(res => {
        setProgrammId(res.data.data.program_id);
        setTemplateId(res.data.data.email_template_id)
        setValue("programme_name", res.data.data.program_id, { shouldDirty: true });
        setValue("template_name",res.data.data.email_template_id, { shouldDirty: true })
        setValue("subject",res.data.data.subject, { shouldDirty: true });
        SetSubjectName(res.data.data.subject);
        setContent(res.data.data.email_body);
        setScheduledEmail(res.data.data.email_body)
        setValue("body", res.data.data.email_body)
        SetSelectedProgramms(res.data.data.emails)
        setOptions(res.data.data.emails.map(({ name, email }) => ({ value: email, label: email,name:name })));
        setSelectedOption(res.data.data.emails.map(({ name, email }) => ({ value: email, label: email,name:name })))
        setScheduleChecked(res.data.data.schedule_send_flag == 0 ? false : true )
        setClearedDate(res.data.data.schedule_time ? date_formats.human_date_time_format(res.data.data.schedule_time):new Date());
        setHandoutUrl(res.data.data.sla_attachment_url? res.data.data.sla_attachment_url.split('/').pop():null);
        setUrl(res.data.data.sla_attachment_url? res.data.data.sla_attachment_url:null);
        setGenericEmailTemplateCheckBox(res.data.data.is_generic_email)
        setIsbxEmailTemplateCheckBox(res.data.data.is_isbx_email)

      }).catch(err => {
        console.log(err);
        if (err.response?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      })
    }
    axios.post(base_url + 'admin/get_program_applications_for_review', {
    }).then(res => {
      SetAllProgramms(res.data.result)
    }).catch(err => {
      console.log(err);
      if (err.response?.statusText == 'Unauthorized') {
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else if(err.message == 'Request aborted'){
        window.location.reload()
      } else {
        toast.warn(api_error_message, { toastId: random_number });
      }
    })
    axios.post(base_url + 'email_template/get_templates', {
    }).then(res => {
      const sortedRecords = res.data.data.sort((a, b) => {
        return b.created_date - a.created_date;
      });
      SetAllTemplates(sortedRecords)
    }).catch(err => {
      console.log(err);
      if (err.response?.statusText == 'Unauthorized') {
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else if(err.message == 'Request aborted'){
        window.location.reload()
      } else {
        toast.warn(api_error_message, { toastId: random_number });
      }
    })
    SetLoading_flag(false)
  }, []);

  const handleChange = (e) => {
    SetSubjectName(e.target.value);
    setValue(e.target.value)
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setFile(file)
    setHandoutUrl(file.name)
    setUrl(URL.createObjectURL(file))
  }
  const handleSelectChange = (newValue) => {
    setSelectedOption(newValue)
};

  const handleDetails = (id, name) => {
    if (name == "programme_name") {
      setProgrammId(id);
      setValue("programme_name",id)
      axios
        .post(base_url + "user/get_paid_students_by_program", {
          program_id: id,
        })
        .then((res) => {
          let uniqueArray = [];
          if (res.data.result.length !== 0) {
            uniqueArray = Array.from(new Set(res.data.result.map(JSON.stringify))).map(JSON.parse);
          }
          setOptions(uniqueArray.map(({ name, email }) => ({ value: email, label: email,name:name })));
          setSelectedOption(uniqueArray.map(({ name, email }) => ({ value: email, label: email,name:name  })))
          SetSelectedProgramms(uniqueArray);
        })
        .catch((err) => {
          SetLoading_flag(false);
          if (err.response?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          }else if(err.message == 'Request aborted'){
            window.location.reload()
          } else {
            toast.warn(api_error_message, { id: random_number });
          }
        });
    } else if (name == "template") {
      setTemplateId(id);
      setValue("template_name",id)
      axios
        .post(base_url + "email_template/get_templates", {
          id: id,
        })
        .then((res) => {
           setContent(res.data.data[0].body);
          SetSubjectName(res.data.data[0].subject);
          setValue("subject", res.data.data[0].subject)
          SetSelectedTemplates(res.data.data[0]);
        })
        .catch((err) => {
          SetLoading_flag(false);
          if (err.response?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          } else if(err.message == 'Request aborted'){
            window.location.reload()
          }else {
            toast.warn(api_error_message, { id: random_number });
          }
        });
    }

  }

  useEffect(() => {
    const isAnyValueEmpty = subject_name?.trim() === "" || content?.trim() === "" || programm_id?.trim() === "" || template_id?.trim() === "";
    setIsSubmitted(isAnyValueEmpty);
  }, [subject_name, content, programm_id, template_id])

  const openSchedule = (e) => {
    setIsSubmitted(false)
    if (e.target.checked) {
      setScheduleChecked(true)
    } else {
      setClearedDate(null)
      setScheduleChecked(false)
    }
  }


  const handleInputChange = (inputValue, { action }) => {
    if (action !== 'input-change') {
      // Handle other actions (e.g., menu open, select option)
      return;
    }
    const pastedEmails = inputValue.split(',').map((email) => email.trim());

    // Append pasted emails to the existing selection
    const newSelectedValues = [...selectedOption];

    options.forEach((option) => {
      if (pastedEmails.includes(option.value) && !newSelectedValues.some((selected) => selected.value === option.value)) {
        newSelectedValues.push(option);
      }
    });


    // Set selected values only for pasted emails
    setSelectedOption(newSelectedValues);
  };



  const handleClearedDateChange = (date) => {
    setClearedDate(date);
    if(date == "Invalid Date"){
      setIsSubmitted(true);
    }else{
      setIsSubmitted(false);
    }
  };

  const onSubmit = (data) => {
    SetLoading_flag(true);
    setIsSubmitted(true);
    let email_exist = true;
    if(selectedOption.length !=0 ){
      email_exist = selectedOption.some(program_details => !program_details.label);
    }else{
      email_exist = true
    }
    if (email_exist) {
      setEmailList(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      SetLoading_flag(false);
      return false;
    }else{
      setEmailList(false);
    }
    let email_array = selectedOption.filter(obj => obj.label && obj.label.trim() !== "").map(obj => obj.label);
    let name_array = selectedOption.filter(obj => obj.name && obj.label).map(obj => obj.name);
    var form_emails = "";
    if(email_array.length !=0 && name_array.length != 0){
      for(var i=0; i<email_array.length; i++){
        form_emails += `{"name":"${name_array[i]}","email":"${email_array[i]}"};`;
      }
    }
    let formData = new FormData();
    formData.append('program_id', programm_id);
    formData.append('email_template_id', template_id);
    formData.append('subject', subject_name);
    formData.append('email_body', content);
    formData.append('emails', form_emails? form_emails.replace(/;(?=[^;]*$)/, ""):{});
    formData.append('send_flag', schedule_checked ? 0 : 1)//send_flag = 0  scheduled
    if (file) {
      formData.append('files', file);
    }else{
      formData.append('sla_attachment_url','')
    }
    if (schedule_checked) {
      formData.append('schedule_time', moment(clearedDate).format("x") == "Invalid date" ? date_formats.convert_current_date_utc_format() : moment(clearedDate).format("x"))
    }
    if(schedule_id){
      formData.append('scheduled_email_id',schedule_id)
    }
    if(url && !url.includes('blob')){
      formData.append('sla_attachment_url', url.includes('blob')?'':url)
    }
    formData.append("is_generic_email",genericEmailTemplateCheckbox)

    formData.append("is_isbx_email",isbxEmailTemplateCheckbox)
    axios
        .post(base_url + "scheduled_emails/save_emails", formData)
        .then((res) => {
          setScheduledEmail(res.data.result.email_body)
          if(!schedule_checked){
            SetSuccessEmails(res.data.result.scheduled_email.emails);
            setSentButtonLabel(true)
          }else{
            setSentButtonLabel(false)
            SetSuccessEmails(res.data.result.emails);
          }
          SetLoading_flag(false)
          toast.success(res.data.message, { toastId: random_number });
        })
        .catch((err) => {
          SetLoading_flag(false);
          setIsSubmitted(false);
          if (err.response?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          }else if(err.message == 'Request aborted'){
            window.location.reload()
          } else {
            toast.warn(api_error_message, { id: random_number });
          }
        });
  };

  const optionsTemplates = templates.map((app) => ({
    value: app._id,
    label: app.name,
  }));

  return (
    <>
      {loading_flag ? (
        <div>
          <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
        </div>
      ) : <>

        <div className="d-block text-center ">
          <h3 className="mb-3" data-testid="Main_Heading">Create Email</h3>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)} id="myForm">
            <div className="container email_schedule_container">
              <div className="row">
                <div className="col-lg-2">
                  <label htmlFor="roletype" data-testid="Programme_Label" className="mt-2 email_labels">
                    Programme :
                  </label>
                </div>
                <div className="col-lg-7">
                 
                    <Select
                      {...register("programme_name", createScheduleEmailsValidations.programme_name)}
                      id="programme_name"
                      name="programme_name"
                      options={optionsP}
                      placeholder="Select the Programme Name"
                      onChange={(selectedOption) => {
                        handleDetails(selectedOption.value, 'programme_name');
                      }}
                      classNamePrefix="react-select-Student"
                      backspaceRemovesValue
                      filterOption={customFilterOption}
                      value={optionsP.find(option => option.value === selectedProgramme)} // Set the selected option
                    />
                  {errors.programme_name && (
                    <span style={{ color: "red" }}>{errors.programme_name.message}</span>
                  )}
                  <select
                   id="Programme_Dropdown"
                   aria-label=".form-select example"
                   name="programme_name"
                  data-testid="Programme_Dropdown"
                  className="form-select d-none"
                  role="presentation" autoComplete="off"
                  value={selectedProgramme}
                  onChange={(selectedOption) => {
                    handleDetails(selectedOption.value, 'programme_name');
                  }}>
                  {optionsP.map((item,index) => {
                     return <option  key={index} value={item.value} >{item.label}</option>;
                  })}
                </select>

                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-2">
                  <label className="d-none"></label>
                </div>
                <div className="col-lg-7">
                  <span className="email_labels" data-testid="toemail_Label"></span>
                  <br />
                 {programm_id && <Select
                 id="email-select"
                    className=" mt-3 cursor-pointer "
                    closeMenuOnSelect={false}
                    onChange={handleSelectChange}
                    value={selectedOption}
                    // name={name}
                    placeholder= ''
                    options={options}
                    onInputChange={handleInputChange}
                    isMulti
                    />}
                  <select
                    id="Programme_toemail"
                    aria-label=".form-select example"
                    name="programme_name"
                    data-testid="Programme_toemail"
                    className="form-select d-none"
                    role="presentation" autoComplete="off"
                    value={selectedOption}
                    onChange={handleSelectChange}  multiple // Enable multiple selections
                  >
                  {options.map((item,index) => {
                     return <option  key={index} value={item.value} >{item.label}</option>;
                  })}
                </select>
                  {/* <div className="emails_container mt-3 cursor-pointer"  tabIndex={0} onKeyDown={handleKeyDown}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleMouseDown}
                    onTouchEnd={handleMouseUp}
                    style={{ cursor: 'text' }}>
                     
                    {selected_programms.map((program_details, index) => (
                      program_details?.email && (
                        <div key={index} className="email-container" style={{ backgroundColor: '#D9D9D9', display: 'inline-block', marginRight: '0.5em', marginBottom: '0.5em' }}>
                          <span className="email-text">
                            {program_details.email}
                          </span>
                          <span className="cross cursor-pointer fs-5" onClick={() => handleCrossClick(index)}>
                            &times;
                          </span>
                        </div>
                      )
                    ))}
                  </div> */}
                  {email_list && (
                    <div style={{ color: 'red',marginTop:"10px" }}>
                      Email is required.
                    </div>
                  )}
                  <div  className="d-none" data-testid="Programme_emailmessage" style={{ color: 'red',marginTop:"10px" }}>
                    Email is required.
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-2">
                  <label htmlFor="roletype" className="mt-2 email_labels" data-testid="template_label">
                    Template:
                  </label>
                </div>

                <div className="col-lg-7">
                  <Controller
                    name="template_name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={optionsTemplates}
                        placeholder="Select the Template"
                        classNamePrefix="react-select-Programme"
                        value={optionsTemplates.find(option => option.value === template_id)}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          handleDetails(selectedOption?.value, 'template');
                        }}
                      />
                      
                    )}
                  />
                  <select
                    id="Programme_template"
                    aria-label=".form-select example"
                    name="template_name"
                    data-testid="Programme_template"
                    className="form-select d-none"
                    role="presentation" autoComplete="off"
                    value={template_id}
                    onChange={(selectedOption) => {
                      handleDetails(selectedOption?.value, 'template');
                    }}>
                      {templates.map((item,index) => {
                      return <option  key={index} value={item._id} >{item.name}</option>;
                    })}
                  </select>
                  {errors.template_name && (
                    <span style={{ color: "red" }}>{errors.template_name.message}</span>
                  )}
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-2">
                  <label htmlFor="subject" className="mt-2 email_labels" data-testid="subject_label">
                  </label>
                </div>
                <div className="col-lg-7">
                  <span className="email_labels">Subject :</span>
                  <br />
                  <input
                    id="subject"
                    data-testid="Programme_subject"
                    name="subject"
                    type="text"
                    className="form-control mt-3"
                    value={subject_name}
                    {...register("subject", createScheduleEmailsValidations.subject)}
                    onChange={handleChange}
                  />
                  {errors.subject?.message && (
                    <span style={{ color: "red" }}>
                      {errors.subject?.message}
                    </span>
                  )}
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-2">
                  <label htmlFor="subject" className="mt-2 email_labels">
                  </label>
                </div>
                <div className="col-lg-7">
                  <span className="email_labels" data-testid="emailbody_label">Body :</span>
                  <br />
                  <br />
                  <div className="d-none" data-testid="Programme_emailBody">{content}</div>
                  <Editor
                    apiKey="1jl4n1cka72zftyk5y5sq27ioibmxki6jukqbch7ysalixae"
                    cloudChannel="5-stable"
                    className="mt-3"
                    disabled={false}
                    inline={false}
                    initialValue={scheduled_email ? scheduled_email : "Text"}
                    onEditorChange={handleEditorChange}
                    plugins=""
                    tagName="div"
                    textareaName="body"
                    toolbar="undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat forecolor backcolor"
                    value={content}
                    outputFormat="html"
                    init={{
                      height: 500,
                      language: "en",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      convert_urls: false
                    }}
                    aria-required={true}
                  />
                  {content == "" && (
                    <span style={{ color: "red" }}>{"Email Body is required"}</span>
                  )}
                </div>
                <br />
              </div>
              <br />

              <div className="row w-100">
                <div className="d-flex align-items-center">
                  <div className={`form-check fs-4 ${clearedDate ? "mt-10" : ""}`}>
                    <input
                      type="checkbox"
                      name="generic_template_checkbox"
                      className="form-check-input"
                      id="generic_template_checkbox"
                      checked={genericEmailTemplateCheckbox}
                      onChange={(e) => getChecked(e.target.checked,"generic_type")} 
                      // onChange={(e) => handleCheckboxChange(e, 'checkbox1')}
                    />
                    <label htmlFor="generic_template_checkbox" className="form-check-label ms-2 fs-6 template_checkbox mt-0">
                      Generic Email Template
                    </label>
                  </div>

                  <div className={`form-check fs-4 ${clearedDate ? "mt-10" : ""} ms-4`}>
                    <input
                      type="checkbox"
                      name="isbx_template_checkbox"
                      className="form-check-input"
                      id="isbx_template_checkbox"
                     checked={isbxEmailTemplateCheckbox}
                     onChange={(e) => getChecked(e.target.checked,"isbx_type")} 
                    />
                    <label htmlFor="isbx_template_checkbox" className="form-check-label ms-2 fs-6 template_checkbox mt-0">
                      ISBx Email Template
                    </label>
                  </div>
                </div>
              </div>


              <br/>

              <div className="row w-25">
                <div className="d-flex align-items-center">
                  <div className= {`form-check fs-4 ${clearedDate ? "mt_10" : ""}`}>
                    <input
                      type="checkbox"
                      name="additionalread"
                      data-testid="Programme_scheduledatechecked"
                      className="form-check-input additionalread"
                      id="additionalread"
                      placeholder="Schedule Date & Time"
                      value={schedule_checked}
                      checked={schedule_checked}
                      onChange={(e) => openSchedule(e)}
                    />
                  </div>
                  <div className="ms-2 d-flex align-items-center" >
                    <KeyboardDateTimePicker
                      data-testid="Programme_scheduledatedatetime"
                      className={!schedule_checked ? "pe-none" : 'pe-auto'}
                      clearable
                      value={clearedDate}
                      onChange={handleClearedDateChange}
                      label={!clearedDate ? "Schedule date & time" : ''}
                      disablePast={true}
                      format="MMM d, yyyy hh:mm a"
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </div>
              </div>

              <br />
              <div className="row w-25" >
                {handout_url == null ? (
                  <>
                    <div className="handout_input_class p-2 cursor-pointer">
                    <div className="mb-0 p-1 pl-0 pr-0 cursor-pointer" onClick={handleFileSelect}>
                      Add Attachment
                    </div>
                      <input type="file" ref={fileInputRef} id="browse" name="browse" className="d-none" onChange={(e) => { handleFileInputChange(e); setIsSubmitted(false); }} />
                    </div>
                  </>
                ) : (
                  <div className="pl-0">
                    <div className=" handout_url_class p-2">
                      <img src="/images/sla_file_upload.svg"></img> &nbsp;
                      <span  className="cursor-pointer text-underline" onClick={() => { downloadPdf(url) }}>{handout_url}</span>
                      <img src="/images/close-icon.svg" style={{ width: "16px" }} className="cursor-pointer float-end mt-2 mr-2" data-testid="Programme_crossicon" onClick={() => { setFile(null);setHandoutUrl(null);setUrl(null);setIsSubmitted(false); }}></img>
                    </div>
                  </div>
                )}
                <div className="d-none" data-testid="Programme_scheduledattachment">{url}</div>
              </div>
              <br />
              <div className="row">
                <div>
                  <button type="submit"
                  data-testid="save-button"
                    className="btn my_btn_cls mt-3 col-lg-1 col-md-2 col-sm-3  px-2"
                    disabled={isSubmitting || content == "" || isSubmitted}
                  >
                    {schedule_checked ? 'Save':'Send'}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <br />
        {
          <div className="col bg-body custm_shadow_cls p-4 m-1 row">
            <h4 className="fw-bold mb-3">Mail Status</h4>
            <div className="">
              <div className="row pm_course_element bg-isbBlue text-white">
                <div className="col-lg-2 fw-bold  pt-2 pb-2">
                  Name
                </div>
                <div className="col-lg-4 fw-bold  pt-2 pb-2">
                  Email
                </div>
                {/* <div className="col-lg-4 fw-bold  pt-2 pb-2"> */}
                  {/* Date & Time
                </div> */}
                <div className="col-lg-2 fw-bold pt-2 pb-2">
                  Status
                </div>
              </div>
              <div className="d-none" data-testid="Programme_tablelist">{success_emails.length}</div>
              {success_emails?.map((item) => {
                return (
                  <>
                    <div className="row pt-3">
                      <div className="col-lg-2 fw-bold ">{item.name}</div>
                      <div className="col-lg-4"> {item.email}</div>
                      <div className="col-lg-2 fw-bold text-success">
                        {sentbutton_label ? 'Sent':'Scheduled'}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        }
       <br />
      </>
      }

    </>
  );
};

export default CreateScheduleEmail;
