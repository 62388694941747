import axios from '../axios-interceptor';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { base_url,api_error_message ,random_number, user_maintenance_type} from '../utilities';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from '../HigherOrderComponents/maintenancePageChecker';
import CryptoJS from "crypto-js";
import { crypto_secret_key } from '../utilities';

function IsbUserLogin(props) {
    var [errorFlag, setErrorFlag] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange = (evnt) => {
             setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const { register, handleSubmit, formState: { errors } } = useForm({ "mode": "all" });
    const history = useHistory()
    const onSubmitLogin = data => {
        let obj = {
            Email: data.Email.trim(),
            Password: data.Password.trim()
        }

        let hashedpassword = CryptoJS.AES.encrypt(
            obj.Password,
            crypto_secret_key
          ).toString();

        axios.post(base_url + "login/isb/user", {
            "email": obj.Email,
            "password": hashedpassword
        })
            .then((res) => {
                if (res.data.result.length != 0) {
                    setErrorFlag(false);
                    localStorage.setItem('login', true);
                    localStorage.setItem('isb_user', true);
                    localStorage.setItem('user_id', res.data.result._id);
                    axios.post(base_url+"login_tracking",{
                        "user_id": res.data.result._id, "is_login": 1
                      }).then(res=>{
                
                      }).catch(err=>{
                        console.log(err);
                        if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
                            localStorage.clear();
                            history.push('/')
                            window.location.reload();
                        }else if(err.message == 'Request aborted'){
                            window.location.reload()
                          }else{
                         toast.warn(api_error_message,{toastId:random_number});
                        }
                      })
                    history.push('/dashboard')
                    window.location.reload();
                }
                else {
                    setErrorFlag(true);
                }
            })
            .catch((err) => {
                console.log(err);
                if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
                    localStorage.clear();
                    history.push('/')
                    window.location.reload();
                }else if(err.message == 'Request aborted'){
                    window.location.reload()
                  }else{
                  toast.warn(api_error_message,{toastId:random_number});
                }
            })
    }


    useEffect(() => {
        if(localStorage.getItem('User_Accesstoken')||localStorage.getItem('Admin_Acesstoken'))
        {
          history.push('/')
        }
        }, [])

    return (<>
        {<div style={{ width: '99%' }}>
            <div className="row justify-content-center mt-5">
                <div className="col-md-6 col-lg-5">
                    <div className="login-wrap p-4 p-md-5">
                        {errorFlag && <div>
                            <span data-testid="Invalid_email_msg" className="text-danger ">Invalid Email or Password</span>
                        </div>
                        }
                        <form onSubmit={handleSubmit(onSubmitLogin)} autoComplete="new-password" className="login-form">
                            <div className="form-group mt-3">
                                <input {...register("Email", {
                                    required: "Email is required", pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Please enter a valid email',
                                    },
                                })}
                                    type="text"
                                    className="form-control rounded-left"
                                    placeholder="Email"
                                    id='Email'
                                    name='Email'
                                    data-testid="Email"
                                    autoFocus
                                    role="presentation" autoComplete="off"
                                />
                                {errors.Email?.message && <span style={{ color: "red" }}>{errors.Email?.message}</span>}
                            </div>
                            <div className="form-group mt-3">
                                <input {...register("Password", { required: "Password is required" })}
                                    type={passwordType}
                                    onChange={handlePasswordChange}
                                    value={passwordInput}
                                    id='Password'
                                    name='Password'
                                    className="form-control rounded-left"
                                    placeholder="Password"
                                    data-testid="Password"

                                />
                                {errors.Password?.message && <span style={{ color: "red" }}>{errors.Password?.message}</span>}
                                <div onClick={togglePassword}>
                                    {passwordType === "password" ? <span role="button" data-testid="eye_button_hide_toggle"> <i data-testid="eye_button_hide" className="fa-regular fa-eye mr-2 mt-2 ml-1" />Show Password </span> : <span role="button"><i className="fa-regular fa-eye-slash fs-sm  mr-2 mt-2 ml-1"></i> Hide Password </span>}
                                </div>
                            </div>
                            <div className="justify-content-center d-flex mt-5">
                                <button
                                    type="submit"
                                    data-testid="submit-button"
                                    className="btn btn-primary rounded submit p-3 px-5"
                                >
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>}
    </>);
}

export default withMaintenanceCheck(IsbUserLogin,user_maintenance_type) 